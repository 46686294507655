import React from 'react'
import {ReactComponent as CloseSvg} from './assets/close.svg';
import { useTranslation } from "react-i18next";

import './CartMutationPopup.scss'

const CartMutationPopup = ({showCartMutationMessage, clearCartMutatedMessage, completeOrder, saveOrderAction}) => {
    const { t } = useTranslation();
    if(!showCartMutationMessage) {
        return null
    }

    return <div className="cart-mutation-popup">
        <div className="outer-wrap">
            <div className="inner-container">
                <div className="close-popup" onClick={() => clearCartMutatedMessage()}>
                    <CloseSvg/>
                </div>
                <div className="message-text">
                    {/*<p>Your cart cannot contain Preorder and Afterorder products at the same time</p>*/}
                    <p>{t('To change season, your cart has to be empty.')}</p>
                    <p>{t('Please complete your order or save it for later.')}</p>
                </div>
                <div className="controls">
                    <button onClick={() => saveOrderAction()}>{t('Save Order')}</button>
                    <button onClick={() => completeOrder()}>{t('Complete Order')}</button>
                </div>
            </div>
        </div>
    </div>

}

export default CartMutationPopup