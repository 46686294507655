import Api from "../controllers/Api";

export const TYPES = {
    FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
    FETCH_PRODUCTS_FAIL: 'FETCH_PRODUCTS_FAIL',
    FETCHED_LIVE_PRICES: 'FETCHED_LIVE_PRICES',
    CLEAR_LIVE_PRICES: 'CLEAR_LIVE_PRICES',
    FETCHED_SAMPLES: 'FETCHED_SAMPLES',
    FETCHED_SAMPLES_FAILED: 'FETCHED_SAMPLES_FAILED',
    SET_PRODUCT_PRICES: 'SET_PRODUCT_PRICES',
    GET_POSTERS: 'GET_POSTERS',
    CLEAR_PRODUCT_SET_PRICES: 'CLEAR_PRODUCT_SET_PRICES',
    SET_VISITED_PRODUCT: 'SET_VISITED_PRODUCT',
    GET_IF_NEW_PRODUCT: 'GET_IF_NEW_PRODUCT',
    GET_IF_NEW_PRODUCT_FAILED: 'GET_IF_NEW_PRODUCT_FAILED'
}

export const setLastVisitedProduct = (productId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.SET_VISITED_PRODUCT,
            payload: productId
        })
    }
}

export const clearLivePrices = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.CLEAR_LIVE_PRICES,
        })
    }
}

export const getProductLivePrices = (productIds) => {
    return async (dispatch, getState) => {
        try {
            const {customer: {activeCustomer}, product: {variationPrices}} = getState()

            if (!activeCustomer) {
                throw new Error('Please select a customer')
            }

            //Check if we are in preorder season

            let productsToFetch = []
            let productsAlreadyFetched = []

            //Check if we already have live prices
            for (let i = 0; i < productIds.length; i++) {
                for (let j = 0; j < variationPrices.length; j++) {
                    if (variationPrices[j].productId === productIds[i]) {
                        //Already fetched
                        productsAlreadyFetched.push(productIds[i])
                        break
                    }
                }
            }

            for (let i = 0; i < productIds.length; i++) {
                if (productsAlreadyFetched.indexOf(productIds[i]) >= 0) {
                    continue;
                }

                productsToFetch.push(productIds[i])
            }

            let livePrices = [];

            if (productsToFetch.length) {
                livePrices = await Api.product.getLivePrices(productsToFetch, activeCustomer._id)

                dispatch({
                    type: TYPES.FETCHED_LIVE_PRICES,
                    payload: livePrices
                })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const getLivePrices = (productsPayload, activeCustomerId) => {
    return async (dispatch, getState) => {
        try {
            //CLOSED DUE TO PRESENTATION
            // const livePrices = await Api.product.getLivePrices(productsPayload, activeCustomerId)
            //
            // dispatch({
            //     type: TYPES.FETCHED_LIVE_PRICES,
            //     payload: livePrices
            // })
        } catch (e) {
        }
    }
}

export const clearSetPrices = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.CLEAR_PRODUCT_SET_PRICES,
        })
    }
}

export const setProductPrices = (clear) => {
    return async (dispatch, getState) => {

        const {customer: {activeCustomer}} = getState();

        if (clear) {
            dispatch({
                type: TYPES.SET_PRODUCT_PRICES,
            })
        } else {
            dispatch({
                type: TYPES.SET_PRODUCT_PRICES,
                payload: activeCustomer
            })
        }
    }
}

export const getPostersAction = () => {
    return async (dispatch, getState) => {
        const posters = await Api.product.getPosters()

        dispatch({
            type: TYPES.GET_POSTERS,
            payload: posters
        })
    }
}

export const getProductsAction = () => {
    return async (dispatch, getState) => {
        try {
            const products = await Api.product.get()
            dispatch({
                type: TYPES.FETCH_PRODUCTS_SUCCESS,
                payload: products
            })

            dispatch({
                type: TYPES.FETCH_PRODUCTS_SUCCESS,
                payload: products
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: TYPES.FETCH_PRODUCTS_FAIL,
            })
        }
    }
}

export const getSamplesAction = () => {
    return async (dispatch, getState) => {
        try {
            const samples = await Api.product.getSamples()
            dispatch({
                type: TYPES.FETCHED_SAMPLES,
                payload: samples
            })
        } catch (e) {
            dispatch({
                type: TYPES.FETCHED_SAMPLES_FAILED,
            })
        }
    }
}
export const getIfAnyNewProduct = () => {
    return async (dispatch, getState) => {
        try {
            const samples = await Api.product.getIfAnyProduct()
            dispatch({
                type: TYPES.GET_IF_NEW_PRODUCT,
                payload: samples
            })
        } catch (e) {
            dispatch({
                type: TYPES.GET_IF_NEW_PRODUCT_FAILED,
            })
        }
    }
}