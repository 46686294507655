import React, { Component } from 'react'
import { connect } from "react-redux";

import './PresentationPage.scss'
import Loader from "../Common/Loader";
import CustomerVerificationPage from "./CustomerVerificationPage";

class PresentationPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        const { history, seasons, auth: { canPreorder, preorderAuthenticated } } = this.props

        if(!canPreorder) {
            history.push('/app')
        }

        if(canPreorder && !preorderAuthenticated) {
            return <CustomerVerificationPage history={history} />
        }

        if(!seasons || !seasons.presentationSeasons || !seasons.presentationSeasons.length) {
            return <Loader/>
        }

        return <div className="presentation-page">
            <div className="seasons-list-container">
                <ul className="seasons-list">
                    {seasons.presentationSeasons
                        .sort((a, b) => b.softoneCode.replace(/\D/g,'') - a.softoneCode.replace(/\D/g,''))
                        .map((season, index) => {
                        return <li className="seasons-item" key={index}>
                            <div
                                className="season-link"
                                onClick={() => history.push(`/app/presentation/season/${season.softoneId}`)}
                            >
                                <h3>{`${season.summerWinter} ${season.year}`}</h3>
                                <img src={season.image} alt={season.softoneCode}/>
                            </div>
                            <div
                                className="campaign-link"
                                onClick={() => history.push(`/app/presentation/campaign/${season.softoneId}`)}
                            >
                                <img src={season.campaignImage} alt={season.softoneCode}/>
                            </div>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    }
}

const mapStateToProps = ({season, settings, auth}) => {
    return {
        auth,
        seasons: season,
        settings: settings.settings
    }
}

export default connect(mapStateToProps, {})(PresentationPage)
