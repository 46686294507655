import React from 'react'

import './AccountSidebar.scss'

const AccountSidebar = ({children}) => {
    return <ul className="sidebar-list">
        {children}
    </ul>
}

export default AccountSidebar
