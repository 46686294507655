import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {Provider} from 'react-redux';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import store from "./store";

import grTranslations from './i18n/gr'
import enTranslations from './i18n/en'

//Translations
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            /*el: {
                translation: grTranslations
            },*/
            en: {
                translation: enTranslations
            }
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });


ReactDOM.render(<Provider store={store}>
        <Suspense fallback="loading">
            <App/>
        </Suspense>
    </Provider>
    , document.getElementById('root'));

serviceWorker.unregister();
