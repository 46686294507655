import React, {Component} from 'react'
import LoginForm from '../Forms/LoginForm'
import {ReactComponent as LogoSvg} from '../assets/logo.svg';
import {connect} from "react-redux";
import {withTranslation } from "react-i18next";

import Api from '../../controllers/Api'

import {loginAction, clearRedirectionFlagsAction} from '../../actions/authActions'
import ResetPasswordForm from "../Forms/ResetPasswordForm";

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.oldPath = false;

        this.state = {
            loginBanner: ''
        }

        if(props.location.state !== undefined && props.location.state.from !== undefined) {
            this.oldPath = props.location.state.from.pathname + props.location.state.from.search;
        }
    }

    componentDidMount() {

        Api.settings.getLoginBanner().then(res => {
            this.setState({
                loginBanner: res.loginBanner,
            })
        })

        const { clearRedirectionFlagsAction } = this.props
        this.redirectIfAuthenticated()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.redirectIfAuthenticated()
    }

    redirectIfAuthenticated() {
        const { auth: { authenticated, activeUser }, history } = this.props

        if( authenticated ) {

            let redirectPath = activeUser.role === 'customer' ?
                '/app/presentation' : '/app/account';

            if(this.oldPath) {
                redirectPath = this.oldPath;
            }

            history.push(redirectPath);
        }
    }

    handleLogin = (e) => {
        e.preventDefault()
        const {loginAction} = this.props;
        const data = new FormData(e.target);

        loginAction(
            data.get('email'),
            data.get('password'),
            data.get('verificationCode'),
        )
    }

    render() {
        const { t } = this.props;
        const { auth: { triedToAuth, authenticationFailedMessage } } = this.props
        return <div className="login-page">
            <header>
                <div className="login-logo">
                    <LogoSvg/>
                </div>
                <div className="welcome-notice">
                    <p>{t('Registered Customers')}</p>
                    <p>{t('If you have an account')}</p>
                </div>
            </header>
            <LoginForm
                authenticationFailedMessage={authenticationFailedMessage}
                handleLogin={this.handleLogin}
                triedToAuth={triedToAuth}
            />
            <ResetPasswordForm />
            <img
                alt="Login Banner"
                className='login-banner'
                src={this.state.loginBanner}/>
        </div>
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        auth
    }
}

export default withTranslation()(connect(mapStateToProps, {loginAction, clearRedirectionFlagsAction})(LoginPage))
