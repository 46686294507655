import React, {Fragment, useState, useEffect} from 'react'
import PanelHeader from './PanelHeader'
import EditField from "../Common/EditField";

import Api from '../../controllers/Api'

import './AccountPanel.scss'
import './Panels.scss'
import {normalizePrice} from "../../utils/priceUtils";
import Button from "../Button";
import {useTranslation} from "react-i18next";
// import settings from "../../reducers/settingsReducer";

const PrintCustomerInfo = (customer) => {
    const { t } = useTranslation();
    return <Fragment>
        <div className="panel-field customer-alt-code">
            <label>
                {t('User Alt Code')}
            </label>
            <span className="value">
                                {customer[0].altCode ||
                                <span className="account-panel-error">Customer Missing Alt Code</span>}
                            </span>
        </div>
        {customer[0].afm ?
            <div className="panel-field customer-afm">
                <label>
                    {t('User VAT Number')}
                </label>
                <span className="value">
                    {customer[0].afm}
                </span>
            </div>
            : null
        }
    </Fragment>
}

const PrintCustomerEditField = (customerId) => {
    const { t } = useTranslation();
    return <Fragment>
        <div className="panel-field customer-pass-change">
            <label>
                {t('Change Customer Password')}
            </label>
            <span className="value">
                <Button callback={() => {
                    const newPassword = window.prompt('Please enter new user password')

                    if(!newPassword) {
                        alert(t('Password cannot be empty'))
                        return;
                    }

                    Api.customer
                        .updatePassword(customerId, newPassword.trim())
                        .then((res) => alert(t('Password Updated')))
                        .catch((err) => alert(t('Failed to update password')))

                }}>{t('Set Password')}</Button>
            </span>
        </div>
    </Fragment>
}

const PrintCustomerPointsInfo = (customer) => {
    const { t } = useTranslation();
    if(!customer || !customer.length || !customer[0].points) {
        return null
    }

    return <div className="customer-info-panel-points-notice">
        <p>{t('You have points!', {points: customer[0].points})}</p>
        <p>{t('Reedem at Checkout step')}</p>
    </div>
}

const AccountPanel = ({user: {name, email, customer, _id, role, mobile}, editAccess, handleEdit, hidePrices}) => {
    const { t } = useTranslation();

    const [hidePricesValue, setHidePricesValue] = useState(hidePrices === 'true')
    localStorage.setItem("fromDate", '');
    localStorage.setItem("toDate", '');
    localStorage.setItem("sessionId", '');

    const [customerSalesmen, setCustomerSalesmen] = useState([])
    const [customerBalance, setCustomerBalance] = useState({
        balance: 0,
        overdue: 0,
        overdueDays: 0,
        currency: 'EUR'
    })

    useEffect(() => {
        if (role === 'customer') {
            Api.customer.getSalesman(_id).then(salesmen => {
                setCustomerSalesmen(salesmen)
            });

            Api.customer.getBalanceOverdue(_id).then(response => {
                setCustomerBalance({
                    balance: response.balance,
                    overdue: response.overdue,
                    overdueDays: response.overdueDays,
                    currency: response.currency,
                })
            });
        }
    }, [])

    return <div className="panel account-panel-components">
        <PanelHeader title={t('Account Information')}/>
        <div className="panel-body">
            {PrintCustomerPointsInfo(customer)}
            {customerSalesmen && customerSalesmen.length ?
                <div className="panel-row salesmen-row">
                    <h5>{t('Salesmen')}</h5>
                    <ul>
                        {customerSalesmen.map(salesman => {
                            return <li key={salesman}>{salesman}</li>
                        })}
                    </ul>
                </div>
                :
                null
            }
            <div className="panel-row">
                <header>
                    {t('Contact Information')}
                </header>
                <div className="content">
                    <div className="personal-content">
                        <div className="panel-field customer-name">
                            <label>
                                {t('User Name')}
                            </label>
                            <span className="value">
                            {name}
                        </span>
                        </div>
                        <div className="panel-field customer-email">
                            <EditField
                                editAccess={editAccess}
                                type="email"
                                value={email} placeholder="email..."
                                submitAction={(value) => {
                                    handleEdit(value.value)
                                }}
                            />
                        </div>
                        {customer && customer.length ? PrintCustomerInfo(customer) : null}
                        {editAccess ? PrintCustomerEditField(_id) : null}
                    </div>
                    {role === 'customer'
                    ?
                        <div className="customer-settings">
                            <div className="form-row">
                                <span className="label">Hide Prices</span>
                                <input value={hidePricesValue} checked={hidePricesValue} type="checkbox" onChange={(e) => {
                                    setHidePricesValue(e.target.checked)

                                    if(e.target.checked) {
                                        localStorage.setItem('vamp_app_hide_prices', e.target.checked)
                                    }else {
                                        localStorage.removeItem('vamp_app_hide_prices')
                                    }

                                }}/>
                            </div>
                        </div>
                    : null
                    }
                </div>
            </div>
            {customerBalance.balance ?
                <div className="panel-row">
                    <div className="account-balances-container">
                        <div className="account-balance">
                            <span className="label">Account Balance:</span>
                            <span className="value">{customerBalance.currency}{normalizePrice(customerBalance.balance)}</span>
                        </div>
                        <div className="account-balance-overdue">
                            <span className="label">Delayed Balance:</span>
                            <span className="value">{customerBalance.currency}{normalizePrice(customerBalance.overdue)}</span>
                        </div>
                        <div className="account-balance-overdue-days">
                            <span className="label">Delayed Days:</span>
                            <span className="value">{customerBalance.overdueDays}</span>
                        </div>

                    </div>
                </div>
                : null}
        </div>
    </div>
}

export default AccountPanel
