import React, { Component } from 'react'
import { connect } from 'react-redux'
import DateRanges from "../Common/Infos/DateRanges";

import { editOrderAction, clearSavedOrder } from '../../actions/orderActions'
import { getCustomerOrdersByDate, clearCustomerOrders } from '../../actions/customerActions'

import OrdersTable from '../Customer/OrdersTable'
import PanelHeader from "./PanelHeader";

import './Panels.scss'
import './OrdersPanel.scss'
import DatePicker from "react-datepicker";
import Api from "../../controllers/Api";
import Select from 'react-select'
import moment from "moment";
import { withTranslation } from 'react-i18next';


class OrdersPanel extends Component{
    constructor(props) {
        super(props)

        const savedDate = localStorage.getItem("fromDate");
        const savedDateTo = localStorage.getItem("toDate");
        this.state = {
            fromDate: savedDate ? moment(savedDate).toDate() : '',
            toDate: savedDateTo ? moment(savedDateTo).toDate() : '',
            loading: false,
        }

        this.setFromDate = this.setFromDate.bind(this)
        this.setToDate = this.setToDate.bind(this)
    }

    componentDidMount() {
        const {
            customer: { activeCustomer },
            getCustomerOrdersByDate,
            order: { activeSavedOrderId },
            history
        } = this.props

        if(activeSavedOrderId) {
            history.push('/app')
        }

        Api.order.getFilteredSessions().then((sessionList) => {
            const tempArray = []
            sessionList.forEach( session => {
                tempArray.push({
                    id: session.softoneId,
                    value: session.softoneId,
                    name: session.summerWinter + ' ' + session.summerWinter,
                    label: session.summerWinter + ' ' + session.year,
                })
            } )
            this.setState({
                sessionList: tempArray
            })
            const sessionId = localStorage.getItem("sessionId");
            if(sessionId){
                this.setState({
                    presentationSeasons: tempArray.filter( sessi => sessi.id === sessionId ).length ? tempArray.filter( sessi => sessi.id === sessionId )[0] : ''
                })
            }
        })

        // if(!activeCustomer.orders) {
        //     getCustomerOrdersByDate(activeCustomer._id);
        // }
    }

    setFromDate(e) {
        this.setState({
            fromDate: e
        })
    }

    setToDate(e) {
        this.setState({
            toDate: e
        })
    }

    render() {
        const { t } = this.props;
        const { customer: { activeCustomer: { orders } }, editOrderAction, getCustomerOrdersByDate, clearCustomerOrders } = this.props

        const customerCountry = this.props.customer.activeCustomer.customer[0].billingAddress.softoneCountryCode
        const { secondEmailCountries } = this.props.settings
        const { fromDate, toDate, loading } = this.state

        let showExtra = false

        secondEmailCountries.filter(item => {
            if(parseInt(item) === parseInt(customerCountry)) {
                showExtra = true;
            }
        })

        return <div className="customer-orders-panel">
            <PanelHeader title="Customer Orders"/>
            <div className="panel-header">
                <div className="panel-filters">
                    <div className='filter-orders-with-sessions'>
                        <label className='filter-orders-with-sessions-label'>{t('Seasons')}</label>
                        <Select
                            value={this.state.presentationSeasons}
                            onChange={(option) => {
                                this.setState({
                                    presentationSeasons: option
                                })
                            }}
                            name="presentation-seasons"
                            options={this.state.sessionList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                    <div className="dates-inputs">
                        <div className="form-group">
                            <label>{t('From Date')}</label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={fromDate}
                                onChange={date => this.setFromDate(date)}
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('To Date')}</label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={toDate}
                                onChange={date => this.setToDate(date)}
                            />
                        </div>
                    </div>
                    <DateRanges setFromDate={this.setFromDate} setToDate={this.setToDate} today={true}/>
                    <div className="form-group">
                        <button onClick={() => {
                            if(!fromDate && !toDate && !this.state.presentationSeasons) {
                                alert('Please fill at least one field')
                            }else {
                                fromDate ? localStorage.setItem("fromDate", fromDate) : localStorage.setItem("fromDate", '');
                                toDate ? localStorage.setItem("toDate", toDate) : localStorage.setItem("toDate", '');
                                this.state.presentationSeasons ? localStorage.setItem("sessionId", this.state.presentationSeasons.id) : localStorage.setItem("sessionId", '');
                                clearCustomerOrders()
                                getCustomerOrdersByDate(this.props.customer.activeCustomer._id, fromDate, toDate, this.state.presentationSeasons)
                            }
                        }} className="vamp-button">{t('Search')}</button>
                    </div>
                </div>
            </div>
            <div className="panel-body">
                {orders && orders.length ?
                    <OrdersTable editOrderAction={editOrderAction} orders={orders} showExtra={showExtra}/>
                    :
                    <p>
                        {t('There is no orders with your filters')}
                    </p>
                }
            </div>
        </div>;
    }
}

const mapStateToProps = ({customer, order, settings}) => {
    return {
        customer,
        settings: settings.settings,
        order
    }
}

export default withTranslation()(connect(mapStateToProps, { getCustomerOrdersByDate, editOrderAction, clearCustomerOrders })(OrdersPanel))
