import React, {useState} from 'react'
import {connect} from 'react-redux'
import PanelHeader from "../../Panels/PanelHeader";
import DatePicker from "react-datepicker";
import Api from "../../../controllers/Api";
import InfoTable from "../../Common/InfoTable";
import {getCurrencySymbolPerCurrency, normalizePrice} from '../../../utils/priceUtils'
import Loader from "../../Common/Loader";

const sortSeasons = ( a, b ) => {
    if ( a.softoneCode < b.softoneCode ){
        return -1;
    }
    if ( a.softoneCode > b.softoneCode ){
        return 1;
    }
    return 0;
}

const SalesmanSeasonsCompare = ({settings: {settings}, season: {seasons}, activeUser}) => {

    const [mainSeason, setMainSeason] = useState('')
    const [prevSeason, setPrevSeason] = useState('')
    const [type, setType] = useState('103')
    const [compareData, setCompareData] = useState([])
    const [showLoader, setShowLoader] = useState(false)

    const [columns, setColumns] = useState(
        [
            {
                Header: 'Code',
                accessor: 'ClientCode',
                Footer: ({data}) => {
                    return <strong>Totals</strong>
                }
            },
            {
                Header: 'Name',
                accessor: 'ClientName',
            },
            {
                Header: 'Current Season',
                accessor: 'csquant',
            },
            {
                Header: 'Previous Season',
                accessor: 'psquant',
            },
            {
                Header: 'Previous Season',
                accessor: 'psvalue',
            },
            {
                Header: 'Previous Season',
                accessor: 'csvalue',
            },
        ]
    )

    if (!seasons || !seasons.length || !settings) {
        return null
    }

    const compareMainSeasons = seasons.filter(season => settings.compareMainSeasons.includes(season.softoneId)).sort(sortSeasons)
    const comparePreviousSeasons = seasons.filter(season => settings.comparePreviousSeasons.includes(season.softoneId)).sort(sortSeasons)

    if(showLoader) {
        return <Loader/>
    }

    return <div className="infos-panel salesman-infos salesman-seasons-compare">
        <PanelHeader title="Seasons Compare"/>
        <div className="panel-body">
            <div className="panel-filters">
                <div className="form-group">
                    <label>Main Season</label>
                    <select value={mainSeason} onChange={e => setMainSeason(e.target.value)}>
                        <option value="" key={1}>Select a season</option>
                        {compareMainSeasons.map(item => <option key={item.softoneId}
                                                                value={item.softoneId}>{item.name}</option>)}
                    </select>
                </div>
                <div className="form-group">
                    <label>Previous Season</label>
                    <select value={prevSeason} onChange={e => setPrevSeason(e.target.value)}>
                        <option value="" key={1}>Select a season</option>
                        {comparePreviousSeasons.map(item => <option key={item.softoneId}
                                                                    value={item.softoneId}>{item.name}</option>)}
                    </select>
                </div>
                <div className="form-group">
                    <label>Type</label>
                    <select value={type} onChange={e => setType(e.target.value)}>
                        <option value="103" key={1}>Collection</option>
                        <option value="101" key={2}>Underwear</option>
                    </select>
                </div>
                <div className="form-group">
                    <button onClick={() => {
                        if (!prevSeason || !mainSeason) {
                            alert('Please select seasons')
                        } else {

                            setCompareData([])
                            setShowLoader(true)

                            Api.salesman.getSeasonCompare(mainSeason, prevSeason, type).then(data => {
                                if (data && data.length) {

                                    const mainSeasonObj = seasons.filter(season => {
                                        return season.softoneId === mainSeason;
                                    })

                                    const prevSeasonObj = seasons.filter(season => {
                                        return season.softoneId === prevSeason;
                                    })

                                    setColumns([
                                        {
                                            Header: 'Code',
                                            accessor: 'ClientCode',
                                            Footer: ({data}) => {
                                                return <strong>Totals</strong>
                                            }
                                        },
                                        {
                                            Header: 'Name',
                                            accessor: 'ClientName',
                                        },
                                        {
                                            Header: `${(mainSeasonObj && mainSeasonObj.length) ? mainSeasonObj[0].name : mainSeason} (qty)`,
                                            accessor: 'csquant',
                                            Cell: props => {
                                                let value = props.cell.row.original.csquant
                                                return <span>{`${value}`}</span>
                                            },
                                            Footer: ({data}) => {

                                                if (!data || !data.length) {
                                                    return null
                                                }

                                                let total = data.reduce((total, {csquant}) => total += parseInt(csquant), 0)

                                                return <strong>{total}</strong>
                                            }
                                        },
                                        {
                                            Header: `${(prevSeasonObj && prevSeasonObj.length) ? prevSeasonObj[0].name : prevSeason} (qty)`,
                                            accessor: 'psquant',
                                            Cell: props => {
                                                let value = props.cell.row.original.psquant

                                                return <span>{`${value}`}</span>
                                            },
                                            Footer: ({data}) => {

                                                if (!data || !data.length) {
                                                    return null
                                                }

                                                let total = data.reduce((total, {psquant}) => total += parseInt(psquant), 0)

                                                return <strong>{total}</strong>
                                            }
                                        },
                                        {
                                            Header: `${(mainSeasonObj && mainSeasonObj.length) ? mainSeasonObj[0].name : mainSeason} (values)`,
                                            accessor: 'csvalue',
                                            Cell: props => {
                                                let value = props.cell.row.original.csvalue
                                                return <span>{`${value}`}</span>
                                            },
                                            Footer: ({data}) => {

                                                if (!data || !data.length) {
                                                    return null
                                                }

                                                let total = data.reduce((total, {csvalue}) => total += parseInt(csvalue), 0)

                                                return <strong>{total}</strong>
                                            }
                                        },
                                        {
                                            Header: `${(prevSeasonObj && prevSeasonObj.length) ? prevSeasonObj[0].name : prevSeason} (values)`,
                                            accessor: 'psvalue',
                                            Cell: props => {
                                                let value = props.cell.row.original.psvalue

                                                return <span>{`${value}`}</span>
                                            },
                                            Footer: ({data}) => {

                                                if (!data || !data.length) {
                                                    return null
                                                }

                                                let total = data.reduce((total, {psvalue}) => total += parseInt(psvalue), 0)

                                                return <strong>{total}</strong>
                                            }
                                        },
                                    ])

                                    setCompareData(data)
                                } else {
                                    alert('No data found')
                                }
                            }).finally(() => {
                                setShowLoader(false)
                            })
                        }

                    }} className="vamp-button">Search
                    </button>
                </div>
            </div>
            {compareData && compareData.length ?
                <div className="seasons-compare-table">
                    <InfoTable columns={columns} data={compareData}/>
                </div>
                :
                null
            }
        </div>
    </div>
}

const mapStateToProps = ({settings, season, auth}) => {
    return {
        settings,
        season,
        activeUser: auth.activeUser
    }
}

export default connect(mapStateToProps, null)(SalesmanSeasonsCompare)