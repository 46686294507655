import React, { Component } from 'react'
import { connect } from 'react-redux'

import { checkoutSamplesAction, clearSubmittedOrder } from '../../actions/checkoutActions'
import { saveSample } from '../../actions/cartActions'

import ArchiveHeader from '../Common/ArchiveHeader'
import ProductMiniature from "../Product/ProductMiniature";
import SamplesPageControls from "./SamplesPageControls";

import './SamplesPage.scss'
import FullScreenLoader from "../Common/FullScreenLoader";

class SamplesPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            submitting: false,
            products: [],
            perPage: 100,
            page: 1,
            samplesList: [],
            samplesListWithDetail: [],
            viewOnlySelected: false,
        }
    }

    componentDidMount() {
        const { product: { samples }, settings: { settings: { samplesEnabled } }, history, activeUserId, cart : {cartItemsSample} } = this.props

        if(!samplesEnabled) {
            //history.push('/app')
        }

        const tempList = cartItemsSample.map( (cartTemp) => {
            return cartTemp.productId || cartTemp._id
        }  )

        this.setState({
            products: samples,
            samplesListWithDetail: cartItemsSample,
            samplesList: tempList
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { checkout: { submittedOrderId, submittedButFailed }, history, clearSubmittedOrder, cart : {cartItemsSample} } = this.props

        if(submittedOrderId) {
            //Redirect to thank you with order id
            history.push(`/app/thank-you/${submittedOrderId}`)
            clearSubmittedOrder()
        }else if(submittedButFailed) {
            //Redicrect to thank you without order id
            history.push('/app/thank-you')
        }
    }

    removeFromSampleList(productId) {
        const { customer, saveSample } = this.props
        const { samplesList, samplesListWithDetail, activeUserId } = this.state;
        const samplesListFiltered = samplesList.filter((sampleId) => productId !== sampleId)
        const samplesListFilteredWithDetail = samplesListWithDetail.filter((sample) => productId !== sample._id)

        this.setState({
            samplesList: samplesListFiltered,
            samplesListWithDetail: samplesListFilteredWithDetail
        })

        const customerId = customer.activeCustomer ? customer.activeCustomer._id : activeUserId
        saveSample(samplesListFilteredWithDetail, customerId  )

    }

    addToSampleList(productId) {
        const { customer, saveSample } = this.props
        const { samplesList, samplesListWithDetail, products, activeUserId } = this.state;
        samplesList.push(productId)

        const seletectedProduct = products.filter((product) => productId === product._id)
        samplesListWithDetail.push(seletectedProduct[0])

        this.setState({samplesList})
        this.setState({samplesListWithDetail})

        const customerId = customer.activeCustomer ? customer.activeCustomer._id : activeUserId
        saveSample(samplesListWithDetail, customerId  )
    }

    printPaginatedProducts(products) {
        const { page, perPage, samplesList, viewOnlySelected } = this.state

        let showLimit = (page * 100) - 1
        let firstLimit = ( (page - 1) * 100 )

        return products.map((product, key) => {

            if(!viewOnlySelected) {
                if( key > showLimit || key < firstLimit) {
                    return null
                }
            }

            const existsInSamplesList = samplesList.indexOf(product._id) >= 0 ? true : false

            if(viewOnlySelected && !existsInSamplesList) {
                return null
            }

            return <div key={product._id} className="product-item">
                <ProductMiniature
                    lazyLoad={true}
                    sampleAdded={existsInSamplesList}
                    sample={true}
                    fancy={true}
                    product={product}
                    updateAction={(productId, qty) => {
                        if(qty) {
                            this.addToSampleList(productId)
                        }else {
                            this.removeFromSampleList(productId)
                        }
                    }}
                />
            </div>
        })
    }

    printPagination() {
        const { products, perPage, page } = this.state;

        let paginationCount = Math.ceil(products.length / 100)
        let paginationArray = []

        for(let i = 1; i <= paginationCount; i++) {
            paginationArray.push(i)
        }

        const leftLimit = page - 12;
        const rightLimit = page + 12

        return paginationArray.map((number, key) => {

            if((number < leftLimit || number > rightLimit) && key > 0 && number !== paginationCount) {
                return null
            }

            return <li onClick={() => this.setState({page : number})} key={number} className={`pagination-number ${(number === page) ? 'active' : ''}`}>
                {number}
            </li>
        })
    }

    toggleViewMode() {
        const { viewOnlySelected } = this.state

        this.setState({
            viewOnlySelected: !viewOnlySelected
        })
    }

    render() {
        const { checkoutSamplesAction, customer, cart } = this.props
        const { products, page, perPage, samplesList, viewOnlySelected, submitting, samplesListWithDetail, activeUserId } = this.state
        const customerId = customer.activeCustomer ? customer.activeCustomer._id : activeUserId

        if(submitting) {
            return <FullScreenLoader />
        }

        if(!products || !products.length) {
            return <p>No Samples Available</p>
        }

        return <div className="samples-page">
            <ArchiveHeader
                title={`Samples Bazaar`}
                page={page}
                perPage={100}
                itemsCount={products.length}
            />
            <div className="archive-container">
                {this.printPaginatedProducts(products)}
            </div>
            {!viewOnlySelected ? <ul className="pagination-component">
                {this.printPagination()}
            </ul> : null}
            <SamplesPageControls
                toggleViewMode={() => this.toggleViewMode()}
                submitOrderAction={() => {
                    if(window.confirm('Are you sure about submitting your sample order?')) {
                        //this.setState({
                        //    submitting: true
                        //})
                        checkoutSamplesAction(samplesList, this.props.activeUserId, customerId)
                    }
                }}
                viewOnlySelected={viewOnlySelected}
                itemsCount={samplesList.length}
                items={samplesList}
                sampleWithDetails={samplesListWithDetail}
            />
        </div>
    }
}

const mapStateToProps = ({ product, cart, checkout, settings, auth, customer}) => {
    return {
        product,
        cart,
        checkout,
        settings,
        auth,
        activeUserId: auth.activeUser.id,
        customer
    }
}

export default connect(mapStateToProps, { checkoutSamplesAction, clearSubmittedOrder, saveSample })(SamplesPage)
