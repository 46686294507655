import React from "react";
import './ColorsList.scss'

const ColorsList = ({colors, selectedColor, setSelectedColor}) => {
    if (!colors || !colors.length) {
        return null
    }

    return <div className="colors-list-component"><ul className="colors-list">
        {colors.map(({hex, name, code}, index) => {
                let boxStyles = {
                    backgroundColor: hex
                }

                return <li onClick={ () => selectedColor === code ? setSelectedColor(false) : setSelectedColor(code) } className={  ( selectedColor === code && selectedColor ) ? 'selectedColor' : '' } key={index}>
                    <span className="color-hex" style={boxStyles}></span>
                    <span className="color-name">{`${code} - ${name}`}</span>
                </li>
            }
        )}
    </ul></div>
}

export default ColorsList
