import React from 'react'
import {ReactComponent as NextSvg} from '../assets/next.svg';

import './BottomNavigationArrows.scss'

const BottomNavigationArrows = ({ navigateToPrev, navigateToNext }) => {
    return <div className="bottom-navigation-arrows">
        <div className="prev-navigation-item" onClick={navigateToPrev}>
            <NextSvg />
        </div>
        <div className="next-navigation-item" onClick={navigateToNext}>
            <NextSvg />
        </div>
    </div>
}

export default BottomNavigationArrows
