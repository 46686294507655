import React from 'react'

import './ProductsArchiveHeader.scss'

const ProductsArchiveHeader = ({title}) => {
    return <header className="products-archive-header archive-title">
        <div className="page-title">
            <h1>{title}</h1>
        </div>
    </header>
}

export default ProductsArchiveHeader