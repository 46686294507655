import React, { Component } from 'react';
import Quagga from 'quagga';

class Scanner extends Component{

    constructor(props) {
        super(props)

        this._onDetected = this._onDetected.bind(this)
    }

    render() {
        return (
            <div id="interactive" className="viewport"/>
        );
    }

    componentDidMount() {
        Quagga.init({
            numOfWorkers: 4,
            locate: true,
            frequency: 10,
            inputStream: {
                name: "Live",
                type: "LiveStream",
                constraints: {
                    width: 640,
                    height: 480,
                    facingMode: "environment",

                },
                area: {
                    top: "0%",
                    right: "0%",
                    left: "0%",
                    bottom: "0%"
                },
            },
            locator: {
                patchSize: 'medium',
                halfSample: true,
            },
            decoder: {
                readers: ["code_128_reader"]
            }
        }, function(err) {
            if (err) {
                return console.log(err);
            }
            Quagga.start();
        });
        Quagga.onDetected(this._onDetected);
    }

    componentWillUnmount() {
        Quagga.stop();
    }

    _onDetected(result) {
        this.props.onDetected(result);
    }
}

export default Scanner

