import Api from "../controllers/Api";
import { getProductLivePrices } from './productAction'
import cart from "../reducers/cartReducer";

export const TYPES = {
    OPEN_QUICKVIEW: 'OPEN_QUICKVIEW',
    CLOSE_QUICKVIEW: 'CLOSE_QUICKVIEW',
    OPEN_QUICKVIEW_EDIT: 'OPEN_QUICKVIEW_EDIT'
}

export const openQuickViewAction = (productId, editMode = false) => {
    return async(dispatch, getState) => {
        try {
            const { cart: { cartItems }, settings: { settings  }, product: { products } } = getState()

            // //Check if product is preorder
            // let productIsPreorder = false
            //
            // if(settings.preorderSeasonsSelection && settings.preorderSeasonsSelection.length) {
            //     for(let i = 0; i < products.length; i++) {
            //         if(products[i]._id.toString() === productId.toString()) {
            //             if(settings.preorderSeasonsSelection.includes(products[i].season.softoneId)) {
            //                 productIsPreorder = true
            //             }
            //             break
            //         }
            //     }
            // }
            //
            // //Fetch live prices only for NON preorder items
            // if(!productIsPreorder) {
            //     dispatch(getProductLivePrices([productId]))
            // }
            //
            // //Get cart and inputValues to sent
            // let inputValues = []
            //
            // for(let i = 0; i < cartItems.length; i++) {
            //     if(cartItems[i].productId === productId) {
            //         inputValues.push({
            //             barcode: cartItems[i].barcode,
            //             inputValue: cartItems[i].qty
            //         })
            //     }
            // }

            // if(editMode) {
            //     dispatch({
            //         type: TYPES.OPEN_QUICKVIEW_EDIT,
            //         payload: productId,
            //         inputValues
            //     })
            // }else {
            //     dispatch({
            //         type: TYPES.OPEN_QUICKVIEW,
            //         payload: productId
            //     })
            // }

            dispatch({
                type: TYPES.OPEN_QUICKVIEW,
                payload: productId
            })

        }catch (e) {
        }
    }
}

export const closeQuickViewAction = () => {
    return async(dispatch, getState) => {
        try {
            dispatch({
                type: TYPES.CLOSE_QUICKVIEW,
            })
        }catch (e) {

        }
    }
}
