import React from 'react'
import './SizesList.scss'

const SizesList = ({sizes, thin}) => {
    if (!sizes || !sizes.length) {
        return null
    }

    return <div className="sizes-list-component">
        {
            thin ?
                <div className="sizes-info">
                    {`${sizes[0]} - ${sizes[sizes.length - 1]}`}
                </div>
                :
                <ul className="sizes-list">
                    {sizes.map((sizeName, index) => {
                            return <li key={index}>
                                <span className="size-name">{sizeName}</span>
                            </li>
                        }
                    )}
                </ul>
        }
    </div>
}

export default SizesList
