import Api from "../controllers/Api";

export const TYPES = {
    LOGIN_ACTION: 'LOGIN_ACTION',
    LOGIN_FAILED: 'LOGIN_FAILED',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT_ACTION: 'LOGOUT_ACTION',
    COMPANY_SALESMEN_FETCHED: 'COMPANY_SALESMEN_FETCHED',
    REDIRECTED_TO_LOGIN: 'REDIRECTED_TO_LOGIN',
    MISSING_TOKEN: 'MISSING_TOKEN',
    CLEAR_REDIRECT_FLAGS: 'CLEAR_REDIRECT_FLAGS',
    SET_PRESENTATION_ACCESS: 'SET_PRESENTATION_ACCESS',
}

export const setSelectedCustomer = (customerId) => {
    return async (dispatch, getState) => {
        try {
            const setSelectedCustomer = await Api.auth.setSelectedCustomer(customerId)
        }catch (error) {
            console.log(error)
        }
    }
}

export const getCompanySalesmen = () => {
    return async (dispatch, getState) => {

        try {
            const salesmen = await Api.auth.getCompanySalesmen()

            dispatch({
                type: TYPES.COMPANY_SALESMEN_FETCHED,
                payload: salesmen,
            })
        }catch (e) {
            console.log(e)
        }
    }
}

export const authenticateUsingToken = () => {
    return async (dispatch, getState) => {
        //Check for stored token
        const storedToken = localStorage.getItem('vamp_salesapp_token');

        if(!storedToken) {
            dispatch(logoutAction())
            return
        }

        try {
            const tokenValid = await Api.auth.checkToken(storedToken)
            dispatch({
                type: TYPES.LOGIN_SUCCESS,
                payload: tokenValid,
            })
        } catch (e) {
            //Unauthorized or token expired
            dispatch(logoutAction())
        }
    }
}

export const redirectToLoginAction = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.REDIRECTED_TO_LOGIN,
        })
    }
}

export const checkIfCanPreorder = (userId) => {
    return async (dispatch, getState) => {
        try {
            const canPreorder = await Api.auth.checkIfCanPreorder(userId)

            if(canPreorder) {
                dispatch({
                    type: TYPES.SET_PRESENTATION_ACCESS,
                    payload: canPreorder
                })
            }
        }catch (e) {
        }
    }
}

export const loginAction = (email, password, token) => {
    return async (dispatch, getState) => {
        try {
            const authenticationToken = await Api.auth.login(email, password, token)

            if (authenticationToken) {
                dispatch({
                    type: TYPES.LOGIN_SUCCESS,
                    payload: authenticationToken
                })
            } else {
                dispatch({
                    type: TYPES.LOGIN_FAILED,
                })
            }

        } catch (e) {
            let errorMessage = (e.response && e.response.data && e.response.data.message) ? e.response.data.message : 'General error'
            let errorType = (e.response && e.response.data && e.response.data.payload && e.response.data.payload.type) ? e.response.data.payload.type : 'general'

            dispatch({
                type: TYPES.LOGIN_FAILED,
                payload: {
                    errorMessage,
                    errorType
                }
            })
        }
    }
}


export const clearRedirectionFlagsAction = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.CLEAR_REDIRECT_FLAGS,
        })
    }
}

export const logoutAction = () => {
    return async (dispatch, getState) => {

        localStorage.removeItem('vamp_salesapp_token');

        dispatch({
            type: TYPES.LOGOUT_ACTION
        })
    }
}
