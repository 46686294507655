import {TYPES} from '../actions/settingsActions'

const initialState = {
    settings: {
        // secondEmailCountries: [],
        presentation: {
            enabled: false,
        },
        // preorderSeasonsSelection: [],
        // excludedFamilies: '',
        samples: {
            enabled: false,
        },
        site: {
            enabled: false
        },
        defaultSeason: '',
        companyEmail: '',
        orderCompany: '',
        orderSeries: '',
        debugMode: false,
        menuItems: [],
        menuItemsCZ: [],
        presentationSeasons: [],
        preorderSeasons: [],
        popupImage: '',
        popupImageUrl: '',
        archiveBanner: [],
        loginBanner: '',
        downloadLinks: [],
    }
};

const settings = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.FETCHED_SETTINGS: {
            return {
                ...state,
                settings: action.payload,
            }
        }
        default:
            return state
    }
};


export default settings
