import React from 'react'

import {ReactComponent as SearchSvg} from '../assets/search.svg';

import './PanelSearch.scss'
import { useTranslation } from "react-i18next";

const PanelSearch = ({ value, onChange }) => {
    const { t } = useTranslation();
    return <div className="panel-search">
        <SearchSvg />
        <input type="text" placeholder={t('search')} value={value} onChange={onChange} />
    </div>

}

export default PanelSearch
