import React from 'react'
import { Link } from 'react-router-dom'

import './PanelMenu.scss'

const PanelMenu = ({menuItems}) => {

    if(!menuItems || !menuItems.length) {
        return null
    }

    return <div className="panel-menu">
        <ul>
            {menuItems.map(({ link, title }, key) => {
                return <li key={key}>
                    <Link to={link}>{title}</Link>
                </li>
            })}
        </ul>
    </div>
}

export default PanelMenu
