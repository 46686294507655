import {TYPES} from '../actions/cartActions'
import {TYPES as authTypes} from '../actions/authActions'

const initialState = {
    cartItems: [],
    cartCount: 0,
    presentationMode: false,
    showCartMutationMessage: false,
    cartItemsSample: [],
    cartCountSample: 0,
    presentationModeSample: false,
    showCartMutationMessageSample: false,
};

const cart = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.EMPTY_CART: {
            return {
                ...state,
                cartCount: 0,
                cartItems: []
            }
        }
        case TYPES.EMPTY_CART_SAMPLE: {
            return {
                    ...state,
                    cartCountSample: 0,
                    cartItemsSample: []
            }
        }
        case TYPES.CART_MUTATED: {
            return {
                ...state,
                showCartMutationMessage: true
            }
        }
        case TYPES.CLEAR_CART_MUTATED: {
            return {
                ...state,
                showCartMutationMessage: false
            }
        }
        case TYPES.REMOVE_FROM_CART: {
            const barcodes = action.payload

            let cartCount = 0;

            let newCartItems = state.cartItems.filter((cartItem) => {
                const preserveItem = !barcodes.includes(cartItem.barcode);
                return preserveItem
            })

            newCartItems.filter(item => {
                cartCount += parseInt(item.qty)
            })

            return {
                ...state,
                cartCount: cartCount,
                cartItems: newCartItems
            }
        }
        case TYPES.UPDATE_CART: {
            const payloadCartItem = Object.assign({}, action.payload)
            let cartCount = 0;
            let newCartItems = state.cartItems;

            let productUpdated = false

            for (let j = 0; j < newCartItems.length; j++) {
                if (newCartItems[j].barcode === payloadCartItem.barcode) {
                    //Update it
                    const qty = payloadCartItem.qty || 0

                    newCartItems[j].qty = parseInt(qty)
                    productUpdated = true
                    break;
                }
            }

            if(!productUpdated) {
                newCartItems.push(payloadCartItem)
            }

            for (let i = 0; i < newCartItems.length; i++) {
                cartCount += parseInt(newCartItems[i].qty)
            }

            return {
                ...state,
                cartCount: cartCount || state.cartCount,
                cartItems: (newCartItems.length) ? newCartItems : state.cartItems
            }
        }
        case TYPES.CLEAR_CART: {
            return {
                ...state,
                cartItems: initialState.cartItems,
                cartCount: initialState.cartCount,
            }
        }
        case TYPES.GET_CART_SUCCESS: {

            const newCart = action.payload.items
            const presentationMode = action.payload.presentationMode

            let cartFiltered = []
            let cartCount = 0;

            //Remove items with qty 0
            cartFiltered = newCart.filter((item) => item.qty)

            newCart.map(({barcode, productId, qty}) => {
                cartCount += parseInt(qty)

                return {
                    barcode,
                    productId,
                    qty,
                }
            })

            return {
                ...state,
                cartCount: cartCount || state.cartCount,
                cartItems: (cartFiltered.length) ? cartFiltered : state.cartItems,
                presentationMode: presentationMode
            }
        }
        case TYPES.GET_CART_SUCCESS_SAMPLE: {

                const newCart = action.payload.items
                const presentationMode = action.payload.presentationMode

                let cartCount = 0;

                newCart.map(({barcode, productId, qty}) => {
                        cartCount += parseInt(qty)

                        return {
                        barcode,
                            productId,
                            qty,
                        }
                })

            return {
                    ...state,
                    cartCountSample: newCart.length,
                    cartItemsSample: newCart,
                    presentationModeSample: presentationMode
            }
        }
        case TYPES.CLEAR_CART_SAMPLE: {
            return {
                    ...state,
                    cartCountSample: 0,
                    cartItemsSample: [],
                }
        }
        case TYPES.ADD_TO_CART: {
            const payloadCartItems = action.payload.items
            const presentationMode = action.payload.presentationMode

            let cartCount = 0;
            let newCartItems = [...state.cartItems];

            //Loop payload and try to find products in cart in order to update them, if not add them
            for (let i = 0; i < payloadCartItems.length; i++) {

                let payloadItem = Object.assign({}, payloadCartItems[i])
                let productFoundInCart = false

                for (let j = 0; j < newCartItems.length; j++) {
                    if (newCartItems[j].barcode === payloadItem.barcode) {
                        //Update it
                        productFoundInCart = true;
                        newCartItems[j].qty = parseInt(payloadItem.qty)
                        break;
                    }
                }

                if (productFoundInCart) {
                    continue
                }

                //Add product to cart
                newCartItems.push(payloadItem)
            }

            for (let i = 0; i < newCartItems.length; i++) {
                cartCount += parseInt(newCartItems[i].qty)
            }

            //Remove empty lines from cart
            newCartItems = newCartItems.filter(item => item.qty)

            return {
                ...state,
                cartCount: cartCount || state.cartCount,
                cartItems: (newCartItems.length) ? newCartItems : state.cartItems,
                presentationMode: presentationMode
            }
        }
        case authTypes.LOGOUT_ACTION: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state
    }
};


export default cart
