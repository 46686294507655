import React from 'react'
import { ReactComponent as BinSvg } from '../assets/bin_2.svg'
import { ReactComponent as PriceTagSvg } from '../assets/priceTag.svg'
import { ReactComponent as Basket } from '../assets/basket.svg'
import { ReactComponent as EditSvg } from '../assets/edit.svg'
import { useTranslation } from "react-i18next";

import './CartGridHeader.scss'

const CartGridHeader = () => {
    const { t } = useTranslation();
    return <tr className="cart-grid-header-row">
        <th className="cart-table-header cart-header-product">
            <span>{t('Product')}</span>
        </th>
        <th className="cart-table-header cart-header-color">
            <span>{t('Details')}</span>
        </th>
        <th className="cart-table-header cart-header-size">
            <span>{t('Size')} | {t('Quantity')}</span>
        </th>
        <th className="cart-table-header cart-header-price">
            <span>{t('Total pcs | Price')}</span>
        </th>
        <th className="cart-table-header cart-header-color">
            <span>{t('Actions')}</span>
        </th>
    </tr>
}

export default CartGridHeader
