import axios from "axios";

class Wishlist {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async get(customerId) {
        let items = await axios.get(`${this.apiUrl}/wishlist/customer/${customerId}`)
        return items.data.payload.items
    }

    async saveWishlist(items, customerId) {
        await axios.post(`${this.apiUrl}/wishlist/customer/${customerId}`, {
            items,
        })
    }

    // async saveUserPassword(userId, password) {
    //     await axios.post(`${this.apiUrl}/settings/userPassword`, {
    //         userId,
    //         password,
    //     })
    // }
    //
    // async save(settings) {
    //     let settingsResponse = await axios.post(`${this.apiUrl}/settings`, {
    //         settings
    //     })
    //     return settingsResponse.data.payload
    // }
    //
    // async getUsers() {
    //     let users = await axios.get(`${this.apiUrl}/settings/users`)
    //     return users.data.payload
    // }
}

export default Wishlist
