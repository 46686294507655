import React, {Component} from 'react';
import SortableTree, {removeNodeAtPath, changeNodeAtPath} from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app

import './MenuEditor.scss'

class MenuEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            link: '',
            treeData: [],
            fetchedInitData: false
        };
    }

    componentDidMount() {
        const { menuItems } = this.props

        this.setState({
            treeData: menuItems
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.treeData.length && this.props.menuItems.length && !this.state.fetchedInitData) {
            this.setState({
                treeData: this.props.menuItems,
                fetchedInitData: true
            })
        }
    }

    addNewEntryToTree() {
        if(this.state.name && this.state.link) {
            this.setState({
                treeData: [
                    ...this.state.treeData,
                    {
                        title: this.state.name,
                        subtitle: this.state.link,
                        expanded: true
                    },
                ],
                name: '',
                link: '',
            })
        }
    }

    removeNode(path) {
        const { treeData } = this.state
        let newTreeData = JSON.parse(JSON.stringify(treeData))
        newTreeData = this.removeNodeInPath(newTreeData, path)

        this.setState({
            treeData: newTreeData
        })
    }

    removeNodeInPath(newTreeData, path, nodesCount = 0) {
        const self = this

        if(!nodesCount) {
            nodesCount = {
                count: 0
            }
        }

        return newTreeData.filter((item) => {
            nodesCount.count += 1

            if(path && path.length && ( nodesCount.count - 1 ) === path[0]) {
                path.shift();

                if(!path.length) {
                    return false
                }
            }

            if(item.children && item.children.length) {
                item.children = self.removeNodeInPath(item.children, path, nodesCount)
            }

            return true
        })
    }

    changeDataInPath(key, value, path) {
        const { treeData } = this.state

        let newTreeData = JSON.parse(JSON.stringify(treeData))

        newTreeData = this.modifyNode(newTreeData, key, value, path, 0)

        this.setState({
            treeData: newTreeData
        })
    }

    modifyNode(newTreeData, key, value, path, nodesCount = 0) {
        const self = this

        if(!nodesCount) {
            nodesCount = {
                count: 0
            }
        }

        return newTreeData.map((item) => {
            nodesCount.count += 1
            if(path && path.length && ( nodesCount.count - 1 ) === path[0]) {
                path.shift();

                if(!path.length) {
                    item[key] = value
                    item.expanded = true
                }
            }

            if(item.children && item.children.length) {
                item.children = self.modifyNode(item.children, key, value, path, nodesCount)
            }

            return item
        })
    }

    render() {
        const {name, link} = this.state

        return (
            <div className="menu-editor" style={{height: 400}}>
                <SortableTree
                    treeData={this.state.treeData}
                    onChange={treeData => this.setState({treeData})}
                    generateNodeProps={({node, path}) => {
                        return ({
                            title: (
                                <input
                                    value={node.title}
                                    onChange={event => {
                                        this.changeDataInPath('title', event.target.value, path)
                                    }}
                                />
                            ),
                            subtitle: (
                                <input
                                    value={node.subtitle}
                                    onChange={event => {
                                        this.changeDataInPath('subtitle', event.target.value, path)
                                    }}
                                />
                            ),
                            buttons: [
                                <div>
                                    <button
                                        label='Delete'
                                        onClick={() => {
                                            this.removeNode(path)
                                        }}
                                    >Remove</button>

                                </div>,
                            ],
                            style: {
                                height: '55px',
                            }
                        })
                    }}
                    // generateNodeProps={rowInfo => ({
                    //     buttons: [
                    //         <div>
                    //             <button label='Delete'
                    //                     onClick={(event) => this.removeNode(rowInfo)}>Remove</button>
                    //         </div>,
                    //     ],
                    //     style: {
                    //         height: '50px',
                    //     }
                    // })}
                />
                <div className="menu-actions">
                    <div className="add-node-container">
                        <div className="form-group">
                            <label>Node name:</label>
                            <input type="text" value={name} onChange={(e) => this.setState({name: e.target.value})}/>
                        </div>
                        <div className="form-group">
                            <label>Node link:</label>
                            <input type="text" placeholder="/app/*" value={link} onChange={(e) => this.setState({link: e.target.value})}/>
                        </div>
                        <div className="form-group">
                            <button className="vamp-button" onClick={() => {
                                this.addNewEntryToTree()
                            }}>Add</button>
                        </div>
                    </div>
                    <div className="save-all-nodes-container">
                        <button className="vamp-button" onClick={() => this.props.saveMenu(this.state.treeData)}>Save menu</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuEditor