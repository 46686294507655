import React, {useState, useRef, useEffect} from 'react'
import {Range} from 'react-range';
import Button from "../Button";
import {ReactComponent as SearchSvg} from "../assets/search.svg";
import {ReactComponent as CloseSvg} from "../assets/close.svg";
import {ReactComponent as ClearSvg} from "../assets/bin.svg";
import {camelize} from "../../utils/utils";
import { useTranslation } from "react-i18next";

import FiltersComponent from './FiltersComponent'

import _ from 'loadsh'

import './ProductFilters.scss'
import BottomNavigationArrows from "../Common/BottomNavigationArrows";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, toggleFiltersShow) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleFiltersShow(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const handlePriceFilterDebounced = _.debounce((values, callback) => {
    callback(values)
}, 500)

const ProductFilters = ({
                            filters,
                            activeFilters,
                            inputTerm,
                            handleInputChange,
                            viewPerLine,
                            setViewPerLine,
                            toggleFilter,
                            handlePriceFilter,
                            clearFilters,
                            minPrice,
                            maxPrice,
                            currencySymbol,
                            bottomNavigation,
                            navigationPrev,
                            navigationNext,
                            hideSearch,
                            productsCount,
                            prevFamily,
                            nextFamily,
                        }) => {
    const { t } = useTranslation();
    let [showFilters, toggleFiltersShow] = useState(false)
    let [priceFilterValue, setPriceFilterValue] = useState([minPrice, maxPrice])

    useEffect(() => {

        if(parseFloat(minPrice) && parseFloat(minPrice)) {
            setPriceFilterValue([
                minPrice, maxPrice
            ])
        }
    }, [minPrice, maxPrice])

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, toggleFiltersShow);

    return <nav ref={wrapperRef} className={`product-filters ${bottomNavigation ? 'has-bottom-navigation' : null}`}>
        <div className="left-section">
            <FiltersComponent
                toggleFiltersShow={toggleFiltersShow}
                showFilters={showFilters}
                clearFilters={clearFilters}
                minPrice={minPrice}
                maxPrice={maxPrice}
                priceFilterValue={priceFilterValue}
                handlePriceFilterDebounced={handlePriceFilterDebounced}
                handlePriceFilter={handlePriceFilter}
                setPriceFilterValue={setPriceFilterValue}
                currencySymbol={currencySymbol}
                toggleFilter={toggleFilter}
                filters={filters}
                activeFilters={activeFilters}
            />
        </div>
        <div className="right-section">
            <div className="products-found">
                <span className="item-label">{t('Found itemnumber items', { itemnumber: productsCount })}</span>
            </div>
            <div className="view-per-line">
                <span className="item-label">{t('Row Count')}</span>
                <select value={viewPerLine} onChange={(e) => setViewPerLine(e.target.value)}>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </select>
            </div>
            {hideSearch ? null
            :
                <div className="product-search">
                    <div className="search-icon">
                        <SearchSvg/>
                    </div>
                    <input placeholder={t('search')} type="text" value={inputTerm}
                           onChange={(e) => handleInputChange(e.target.value)}/>
                    <div className="clear-icon" onClick={() => handleInputChange('')}>
                        <CloseSvg/>
                    </div>
                </div>
            }

            {bottomNavigation ? <BottomNavigationArrows navigateToNext={navigationNext} navigateToPrev={navigationPrev} /> : null}
            {prevFamily || nextFamily ? <BottomNavigationArrows navigateToNext={nextFamily} navigateToPrev={prevFamily} /> : null}
        </div>

    </nav>
}

export default ProductFilters
