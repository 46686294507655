import moment from "moment";
import React from "react";

export const printDeliveryWeek = (product) => {
    if (product.isPreorder && product.deliveryDate) {
        let dateItem = moment(product.deliveryDate).utc()

        return <div className="delivery-week">
            <span className="label">Delivery Week:</span><span className="value">{dateItem.week()}</span>
        </div>
    }
    return null;
}