import Api from '../controllers/Api'
import React from "react";

import {getCurrencyByCode} from './customerUtils'

export const addLivePricesToProduct = (product, livePrices) => {
    if(!livePrices || !Object.keys(livePrices).length) {
        return product
    }

    if(product.type !== 'variable' || !product.variations.length) {
        return product
    }

    product.variations.map(variation => {
        for(let i = 0; i < livePrices.length; i++) {
            if(livePrices[i].barcode === variation.barcode) {
                variation.price = livePrices[i].price
                break
            }
        }

        return variation
    })

    return product
}

export const getLivePrices = async (products, customer, lite = true) => {

    if(!customer || !products || !products.length) {
        return [];
    }

    //Check if preorder
    let markedAsPreorder = false;
    const productIds = products.map(product => {
        if(product.markedAsPreorder) {
            markedAsPreorder = true
        }

        return product._id
    })

    if(markedAsPreorder) {
        return []
    }

    const prices = await Api.product.getLivePrices(productIds, customer._id, lite)

    let pricesArray = [];

    if(!prices || !prices.length) {
        return pricesArray
    }

    for(let i = 0; i < Object.keys(prices).length; i++) {
        pricesArray.push(prices[i])
    }

    return pricesArray
}

export const getProductPrices = (product, livePrices = [], customerHasLocalPrices = false) => {
//todo check regular prices
    let price = 0
    let regularPrice = 0
    let livePriceMatched = false

    //Return local prices here
    if(customerHasLocalPrices && product.price && product.price.final) {
        return {
            price: parseFloat(product.price.final).toFixed(2),
            regularPrice: parseFloat(product.price.regular).toFixed(2),
            livePriceMatched: true
        }
    }

    if(product.type === 'variable' && product.variations && product.variations.length) {
        let variationPrices = [];

        if(livePrices && livePrices.length) {
            //Live prices
            livePriceMatched = true

            variationPrices = livePrices
                .filter(livePrice => livePrice.mtrl.toString() === product.mtrl.toString())
                .map(livePrice => {
                    return {
                        price: livePrice.price,
                        regularPrice: livePrice.regularPrice,
                    }
                })
        }else {
            //Normal prices
            variationPrices = product.variations.map(variation => {
                return {
                    price: variation.price,
                    regularPrice: variation.price,
                }
            }).sort()
        }

        if(variationPrices && variationPrices.length) {
            price = variationPrices[0].price
            regularPrice = variationPrices[0].regularPrice
        }
    }else {
        price = product.price.final
    }

    return {
        price: parseFloat(price).toFixed(2),
        regularPrice: parseFloat(regularPrice).toFixed(2),
        livePriceMatched
    }
}

//Returns a live price object for a given product
export const getProductLivePrice = (product, livePrices) => {
    const keys = Object.keys(livePrices)

    for(let i = 0; i < keys.length; i++) {
        if(product.mtrl.toString() === livePrices[keys[i]].mtrl.toString()) {
            return livePrices[keys[i]];
        }
    }

    return 0
}

export const printProductDisplayPrice = (product, variationPrices = []) => {
    let lowestPrice = 999999
    let lowestLivePrice = 999999

    if(product.type === 'variable') {
        product.variations.filter((variation) => {
            if(variation.price < lowestPrice) {
                lowestPrice = variation.price
            }
        })

        variationPrices.filter((variationPrice) => {
            if(parseInt(variationPrice.mtrl) === parseInt(product.mtrl)) {
                if(variationPrice.price < lowestLivePrice) {
                    lowestLivePrice = variationPrice.price
                }
            }
        })
    }else {
        lowestPrice = 0
        lowestLivePrice = 0
    }

    if(lowestLivePrice === 999999) {
        lowestLivePrice = 0
    }

    if(lowestPrice === 999999) {
        lowestPrice = 0
    }

    return {
        lowestPrice: lowestPrice.toFixed(2),
        lowestLivePrice: lowestLivePrice.toFixed(2)
    }
}

export const calculateLowestPrice = (product, activeCurrency = 'EURO') => {
    let lowestPrice = 999999

    if (product.variations && product.variations.length) {
        for(let i = 0; i < product.variations.length; i++) {
            const variationPrice = Object.assign({}, product.variations[i].prices);

            Object.keys(variationPrice).filter((priceItem) => {
                if(variationPrice[priceItem].currency === activeCurrency) {
                    if(lowestPrice > parseFloat(variationPrice[priceItem].price)) {
                        lowestPrice = parseFloat(variationPrice[priceItem].price)
                    }
                }
            })
        }
    }

    if(lowestPrice == 999999) {
        return 0
    }

    return lowestPrice
}

export const mergeWithLivePrice = (product, variationPrices) => {

    let productObj = Object.assign({}, product)
    let lowestPrice = 0


    if (productObj.variations && productObj.variations.length) {
        productObj.variations = productObj.variations.map((variation) => {
            for (let i = 0; i < variationPrices.length; i++) {

                //Removed product id check, might be important, if so we need to feed it from the api
                if (
                    variation.barcode === variationPrices[i].barcode
                ) {

                    variation.price = variationPrices[i].price
                    if (parseFloat(variation.price) < parseFloat(lowestPrice) || !lowestPrice) {
                        lowestPrice = parseFloat(variation.price)
                    }
                    break
                }
            }
            return variation
        })
    }

    productObj.lowestLivePrice = lowestPrice
    return productObj
}

export const getCurrencySymbol = (customer) => {
    if(!customer || !customer.currency) {
        return '€'
    }

    switch (customer.currency) {
        case 'EUR': {
            return '€';
        }
        case 'USD': {
            return '$';
        }
        case 'CZK': {
            return 'CZK';
        }
        case 'PLN': {
            return 'PLN';
        }
        // default : {
        //     return '€';
        // }
    }

    //Check for currency with numbers code
    return getCurrencyByCode(customer.currency)
}

export const getCurrencySymbolPerCurrency = (currency) => {
    const data = currency.toUpperCase()

    switch (data) {
        case 'EUR': {
            return '€';
        }
        case 'USD': {
            return '$';
        }
        case 'CZK': {
            return 'CZK';
        }
        case 'PLN': {
            return 'PLN';
        }
        default : {
            return '€';
        }
    }
}

export const printProductPrice = (price, regularPrice, livePriceMatched, currency, product) => {
    if(!livePriceMatched) {
        return <div className="prices"><span className="price">{currency}{price}</span></div>
    }

    if((parseFloat(regularPrice)) !== parseFloat(price)) {

        // let discountPercent = parseFloat(((price - regularPrice) / regularPrice)).toFixed(2);
        // discountPercent = discountPercent * 100

        return <div className="prices">
            <span className="regular-price">{currency}{regularPrice}</span>
            <span className="price">{currency}{price}</span>
            {
                (product && product.price && product.price.sale && parseFloat(product.price.sale) > 0) ?
                    <span className="discount-bubble">
                    {product.price.sale}%
                    </span>
                    :
                    null
            }
        </div>
    }


    return <div className="prices">
        <span className="price">{currency}{price}</span>
    </div>
}

export const normalizePrice = (number) => {
    let decimals = 2
    let decpoint = ',' // Or Number(0.1).toLocaleString().substring(1, 2)
    let thousand = '.' // Or Number(10000).toLocaleString().substring(2, 3)

    let n = Math.abs(number).toFixed(decimals).split('.')
    n[0] = n[0].split('').reverse().map((c, i, a) =>
        i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
    ).reverse().join('')
    let final = (Math.sign(number) < 0 ? '-' : '') + n.join(decpoint)

    return final
}

export const getCustomerPriceZone = (customer) => {

    if(!customer || !customer.customer || !customer.customer.length) {
        return 'WPrice'
    }

    return customer.customer[0].priceZone
}

export const checkIfCustomerHasLocalPrices = (customer) => {

    if(!customer) {
        return false
    }

    const priceZone = customer.customer[0].priceZone

    if(priceZone === 'PRICEW01' || priceZone === 'PRICEW02') {
        return false;
    }

    return true;
}
