import React from 'react'
import Button from "../Button";

import {ReactComponent as ImportSvg} from '../assets/import.svg';
import {ReactComponent as DeleteSvg} from '../assets/delete.svg';
import { useTranslation } from "react-i18next";

import './OrdersTable.scss'

const OrdersTable = ({orders, loadOrderAction, deleteOrder}) => {
    const { t } = useTranslation();

    if(!orders || !orders.length) {
        return <p className="orders-table-notice">{t('You do not have any saved orders')}</p>
    }

    return <table className="orders-table">
        <tbody>
        <tr>
            <th>
                {t('Order ID')}
            </th>
            <th>
                {t('Customer Name')}
            </th>
            <th>
                {t('Created')}
            </th>
            <th>
                {t('Total')}
            </th>
            <th>
                {t('Load')}
            </th>
            <th>
                {t('Delete')}
            </th>
        </tr>
        {orders.map((order) => {
            return <tr key={order.id}>
                <td>
                    {order.id}
                </td>
                <td>
                    {order.customerName}
                </td>
                <td>
                    {order.created_at}
                </td>
                <td>
                    {order.total}
                </td>
                <td>
                    <div className="action-area" onClick={() => {
                        loadOrderAction(order.id)
                    }}>
                        <ImportSvg/>
                    </div>
                </td>
                <td>
                    <div className="action-area" onClick={() => {
                        if(window.confirm('Are you sure about deleting this order?')) {
                            deleteOrder(order.id)
                        }
                    }}>
                        <DeleteSvg/>
                    </div>
                </td>
            </tr>
        })}
        </tbody>
    </table>
}

export default OrdersTable
