import React from 'react'
import FamilyItem from "./FamilyItem";

import './FamiliesList.scss'

const FamiliesList = ({entries, handleClick, wishlistToggle, wishlistItems}) => {
    return <ul className="families-list">
        {[...entries].map((entry, index) => <li
            key={index}><FamilyItem
            item={entry}
            handleClick={(familyId) => handleClick(familyId)}
            wishlistToggle={wishlistToggle}
            isInWishlist={wishlistItems.families.includes(entry._id)}
        /></li>)}
    </ul>
}

export default FamiliesList
