import React from 'react'

import { ReactComponent as CustomerSvg } from '../assets/customer.svg';
import { ReactComponent as CloseSvg } from '../assets/close.svg';

import './Modals.scss'
import Button from "../Button";

const SelectFromExistingCustomersPopup = ({ existingCustomers, closeAction, clickAction }) => {

    if(!existingCustomers || !existingCustomers.length) {
        return null
    }

    return <div className="select-from-existing-customers modal-component">
        <div className="modal-container">
            <div className="modalClose" onClick={closeAction}>
                <CloseSvg/>
            </div>
            <header>
                <h1>
                    The following customers have the given VAT number, please select from the list or use a different VAT number
                </h1>
            </header>
            <div className="customers-list">
                <ul>
                    {existingCustomers.map(({id, name}) => {
                        return <li key={id}>
                            <CustomerSvg/>
                            <span className="customer-name">
                                {name}
                            </span>
                            <Button
                                title="Select"
                                callback={() => {
                                    clickAction(id)
                                }}
                            />
                        </li>
                    })}
                </ul>
            </div>
        </div>
    </div>
}

export default SelectFromExistingCustomersPopup
