import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as _ from "loadsh";

import { clearNotices } from "../actions/noticeActions";
import './NoticePresenter.scss'

class NoticePresenter extends Component {
    constructor(props) {
        super(props);

        this.debouncedClearNotices = _.debounce(this.clearNoticesAction, 1500)
    }

    clearNoticesAction() {
        const { clearNotices } = this.props

        clearNotices();
    }

    render() {

        const { notice } = this.props

        if(!notice || !notice.notices || !notice.notices.length) {
            return null
        }

        this.debouncedClearNotices()

        return <aside className="notices-presenter">
            <ul>{notice.notices.map((notice, index) => <li key={index}>{notice}</li>)}</ul>
        </aside>
    }
}

const mapStateToProps = ({notice}) => {
    return {
        notice
    }
}

export default connect(mapStateToProps, { clearNotices })(NoticePresenter)