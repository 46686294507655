import React from 'react'
import { ReactComponent as HeartSvg } from '../assets/heart.svg'
import { ReactComponent as LikeSvgWhite } from '../assets/like-white.svg'
import { ReactComponent as LikeSvgBlack } from '../assets/like-black.svg'

import './WishlistButton.scss'

const WishlistButton = ({handleWishlistClick, isInWishlist, icon = 'heart'}) => {
    return <div className={`wishlist-button icon-${icon} ${isInWishlist ? 'is-in-wishlist' : ''}`}>
        <button onClick={() => {
            if(isInWishlist) {
                const response = window.confirm('Are you sure about removing this item from your wishlist?')
                if(response) {
                    handleWishlistClick()
                }
            }else {
                handleWishlistClick()
            }
        }}>
            {icon === 'like' ?
                isInWishlist ? <LikeSvgBlack/> : <LikeSvgWhite/>
                : <HeartSvg />}
        </button>
    </div>
}

export default WishlistButton
