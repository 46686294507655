import React from 'react'
import Api from "../../controllers/Api";
import {ReactComponent as PdfSvg} from "../assets/pdf.svg";

import './PdfLinkButton.scss'

const PdfLinkButton = ({company, fincdoc}) => {
    return <div>
        <button className="orders-pdf-link" onClick={() => {
            Api.order.getOrderPdfWithParams(fincdoc, company)
                .then((res) => {
                    if (res) {

                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = res;

                        a.click();
                    }
                })
                .catch(err => {
                    alert("Something went wrong, please try again later or contact us")
                })
                .finally(() => {
                })
        }
        }>
            <PdfSvg/>
        </button>
    </div>
}

export default PdfLinkButton;