import React from 'react'

import './AdminSection.scss'

const AdminSection = ({title, children}) => {
    return <section className="admin-section">
        <header>
            <span>{title}</span>
        </header>
        <div className="admin-section-content">
            {children}
        </div>
    </section>
}

export default AdminSection