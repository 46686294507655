import React, { useState, useEffect } from 'react'
import Api from "../../controllers/Api";
import {normalizePrice} from "../../utils/priceUtils";
import {ReactComponent as PdfSvg} from "../assets/pdf.svg";
import {Link} from "react-router-dom";
import InfoTable from "../Common/InfoTable";

import './OrderDetails.scss'

const OrderDetails = ({ match, history }) => {

    const [ orderData, setOrderData ] = useState([])
    let finDoc = match.params.id;

    useEffect(() => {
        if(finDoc) {
            Api.order.getDetails(finDoc).then(response => {
                if(response && response.length) {
                    setOrderData(response)
                }
            })
        }

    }, [finDoc])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Image',
                accessor: 'image',
                skipFilter: true,
                Cell: props => {
                    let value = props.cell.row.original.image
                    return <a href={value ? value : '#0'} target="_blank"><img src={value ? value : '#0'} /></a>
                },
            },
            {
                Header: 'Code',
                accessor: 'code',
                skipFilter: true,
            },
            {
                Header: 'Title',
                accessor: 'name',
                skipFilter: true,
            },
        ],
        []
    )


    if(!orderData || !orderData.length) {
        return <div className="order-details">
            <h3>Order Details not found, please contact us</h3>
        </div>
    }

    return <div className="order-details">
        <h3>Order Details</h3>
        <div className="panel-body">
            <InfoTable columns={columns} data={orderData} />
        </div>
    </div>

}

export default OrderDetails