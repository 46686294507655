import React from 'react'
import './BottomBar.scss'

const BottomBar = ({children}) => {
    return <aside className="site-global-bottom-bar">
        {children}
    </aside>
}

export default BottomBar
