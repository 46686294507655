import axios from "axios";

class Payment {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async get(customerId) {
        let response = await axios.get(`${this.apiUrl}/payment/${customerId}`)
        return response.data.payload
    }
}

export default Payment
