import {TYPES} from '../actions/checkoutActions'
import {TYPES as authTypes} from '../actions/authActions'

const initialState = {
    submittedOrderId: false,
    submittedButFailed: false,
    failedOrderPayload: {},
};

const checkout = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.CLEAR_SUBMITTED_ORDER_ID: {
            return {
                ...state,
                submittedOrderId: false
            }
        }
        case TYPES.SUBMIT_ORDER_SUCCESS: {
            return {
                ...state,
                submittedOrderId: action.payload
            }
        }
        case TYPES.SUBMIT_ORDER_FAILED: {
            return {
                ...state,
                failedOrderPayload: action.payload
            }
        }
        case TYPES.CLEAR_ORDER_FAIL_MESSAGE: {
            return {
                ...state,
                failedOrderPayload: {}
            }
        }
        case authTypes.LOGOUT_ACTION: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state
    }
};


export default checkout
