import React, {useState, useEffect} from 'react'
import Api from '../../controllers/Api'
import Loader from "../Common/Loader";

import './CustomerVerificationPage.scss'

const CustomerVerificationPage = () => {

    const [method, setMethod] = useState('')
    const [requested, setRequested] = useState(false)
    const [requestingVerification, setRequestingVerification] = useState(false)
    const [formSent, setFormSent] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        Api.auth.initCustomerVerification().then(res => {
            setMethod(res.verificationMethod)
            setRequested(true)
        })
    }, [])

    const requestVerification = () => {
        setRequestingVerification(true)
        setRequested(false)
        setFormSent(false)
        setErrorMessage('')

        Api.auth.initCustomerVerification().then(res => {
            setMethod(res.verificationMethod)
            setRequested(true)
        }).finally(() => {
            setRequestingVerification(false)
        })
    }

    return <div className="customer-verification-page">
        {!requested ? <Loader/> :
            <div className="customer-verification-form">
                <form onSubmit={(e) => {
                    e.preventDefault()

                    if (!formSent) {
                        const {pin} = e.target.elements

                        if (pin && pin.value) {
                            Api.auth.verifyUser(pin.value).then(res => {
                                if (res) {
                                    //Customer Verified
                                    alert('You have successfully verified your account')
                                    window.location.reload()
                                }
                            }).catch(e => {
                                setErrorMessage(e.response.data.message)
                            }).finally(() => {
                                setFormSent(true)
                            })
                        }

                    } else {
                        alert('Some thing went wrong please contact us')
                        window.location.reload()
                    }
                }}>
                    <h5>A PIN has been sent to you by <strong>{method}</strong></h5>
                    <p>Enter the PIN Below</p>

                    <div className="form-group">
                        <input type="text" name="pin" id="pin" placeholder="PIN..."/>
                    </div>

                    {errorMessage ? <div className="error-messages">
                        <strong>{errorMessage}</strong>
                        <div className="request-new-pin">
                            <span>You can request a new pin in case you did not received one</span>
                            {!requestingVerification ?
                                <button className="btn btn-dark" onClick={() => {
                                    requestVerification()
                                }}>Request Pin</button>
                                : null}
                        </div>
                    </div> : null}
                    {!formSent ? <div className="form-group">
                        <button className="btn btn-dark" type="submit">Submit</button>
                    </div> : null}
                </form>
            </div>
        }
    </div>

}

export default CustomerVerificationPage