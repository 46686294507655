import React from 'react'

import './SearchResultsList.scss'

const SearchResultsList = ({children}) => {

    if (!children.length) {
        return <span className="search-error">Nothing Matches your search term</span>
    }

    return <ul className='search-results-list'>{children}</ul>
}

export default SearchResultsList
