import React from 'react'

import './CheckoutAddressBox.scss'
import { useTranslation } from "react-i18next";

const CheckoutAddressBox = ({ address, name }) => {
    const { t } = useTranslation();
    return <div className="checkout-address-box">
        <div className="form-group">
            <span className="label">{t('Name')}</span>
            <span className="value">
                {name}
            </span>
        </div>
        <div className="form-group">
            <span className="label">{t('Address')}</span>
            <span className="value">
                {`${address.address}, ${address.city}, ${address.zip}, ${address.country}`}
            </span>
        </div>
        <div className="form-group">
            <span className="label">{t('Phone')}</span>
            <span className="value">
                {address.phone}
            </span>
        </div>
    </div>
}

export default CheckoutAddressBox