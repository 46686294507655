import React, { Component } from 'react'
import './SingleProductPage.scss'
class SingleProductPage extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return <div className="single-product-page">
            <div>
                <video controls={true} src="https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/videos/1280X1920_H264_47-100.mp4"></video>
            </div>
            <div>
                <video controls={true} src="https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/videos/1280X1920H2651-25.mp4"></video>
            </div>
            <div>
                <video controls={true} src="https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/videos/Const_25_128_4,2,0_8bit.webm"></video>
            </div>
        </div>
    }

}

export default SingleProductPage
