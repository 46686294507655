import React, {useState} from 'react'
import PanelHeader from "../../Panels/PanelHeader";
import DatePicker from "react-datepicker";

import Api from '../../../controllers/Api'
import InfoTable from "../../Common/InfoTable";

import './CustomerCredits.scss'
import './CustomerInfos.scss'
import DateRanges from "../../Common/Infos/DateRanges";
import {getCurrencySymbolPerCurrency, normalizePrice} from "../../../utils/priceUtils";
import Loader from "../../Common/Loader";
import { useTranslation } from "react-i18next";

const CustomerCredits = ({ user }) => {
    const { t } = useTranslation();

    const [ date, setDate ] = useState('')
    const [ credits, setCredits ] = useState([])
    const [ showLoader, setShowLoader ] = useState(false)

    const columns = React.useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'FINCODE',
                Footer: ({data}) => {
                    if(!data || !data.length) {
                        return null
                    }

                    return <strong>{t('Total')}</strong>
                }
            },
            {
                Header: 'Date',
                accessor: 'TRNDATE',
            },
            {
                Header: 'Type',
                accessor: 'TYPE',
            },
            {
                Header: 'Value',
                accessor: 'VAL',
                Cell: props => {
                    let value = props.cell.row.original.VAL
                    value = normalizePrice(value)

                    const currency = getCurrencySymbolPerCurrency(props.cell.row.original.CURRENCY)

                    return <span>{`${currency} ${value}`}</span>
                },
                Footer: ({data}) => {

                    if(!data || !data.length) {
                        return null
                    }
                    const currency = getCurrencySymbolPerCurrency(data[0].CURRENCY)

                    let total = data.reduce((total, { VAL }) => total += parseFloat(VAL), 0).toFixed(2)
                    total = normalizePrice(total)

                    return <strong>{`${currency} ${total}`}</strong>
                }
            },
            {
                Header: 'Pdf',
                Cell: props => {
                    const findoc = props.cell.row.original.FINDOC
                    const defaultprint = props.cell.row.original.DEFAULT_PRINT

                    return <span className="vamp-button" onClick={() => {
                        setShowLoader(true)
                        Api.customer.getCreditsPdf(user._id, findoc, defaultprint).then(link => {
                            if(link) {
                                window.open(link, '_blank');
                            }
                        }).finally(() => {
                            setShowLoader(false)
                        })
                    }
                    }>Pdf</span>
                }
            },
        ],
        []
    )

    if(showLoader || !user) {
        return <Loader/>
    }

    return <div className="customer-credits">
        <PanelHeader title="Credits"/>
        <div className="panel-body">
            <div className="panel-filters">
                <div className="form-group">
                    <label>{t('From Date')}</label>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={date}
                        onChange={date => setDate(date)}
                    />
                </div>
                <DateRanges setFromDate={setDate}/>
                <div className="form-group">
                    <button onClick={() => {

                        if(!user) {
                            alert('General Error, please reload and try again')
                        }else {
                            setShowLoader(true)
                            Api.customer.getCredits(user._id, date).then(data => {
                                if(data && data.length) {
                                    setCredits(data)
                                }
                            }).finally(() => {
                                setShowLoader(false)
                            })
                        }


                    }} className="vamp-button">{t('Search')}</button>
                </div>
            </div>
            {credits && credits.length ?
                <div className="credits-table">
                    <InfoTable columns={columns} data={credits} />
                </div>
                :
                null
            }
        </div>
    </div>
}

export default CustomerCredits