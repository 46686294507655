import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import Api from '../../controllers/Api'

import ProductMiniature from '../Product/ProductMiniature'

import {ReactComponent as CloseSvg} from '../assets/close.svg';

import './PostersModal.scss'

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    touchThreshold:100,
    responsive: [
        {
            breakpoint: 768, // a unrealistically big number to cover up greatest screen resolution
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }
    ]
};

const PostersModal = ({show, closeModal, updateCartAction, cartItems, removeFromCartAction}) => {
    const [ posters, setPosters ] = useState([])

    useEffect(() => {
        Api.product.getPosters().then(response => {
            setPosters(response)
        })
    }, [])

    if(!show) {
        return null
    }

    if(!posters || !posters.length) {
        return null
    }

    const findCartQuantity = (productId) => {
        for(let i = 0; i < cartItems.length; i++) {
            if(cartItems[i].type !== 'simple') {
                continue;
            }

            if(productId === cartItems[i]._id) {
                return cartItems[i].inputValue
            }
        }

        return 0
    }

    return <div className="posters-modal">
        <div className="posters-modal-container">
            <div className="close-poster-modal" onClick={closeModal}>
                <CloseSvg/>
            </div>
            <h2 className="modal-title">Select from our Posters</h2>
            <Slider {...settings}>
                {posters.map((poster, key) => <div
                    key={key}
                    className="poster-slider-item">
                    <ProductMiniature
                        updateAction={updateCartAction}
                        deleteAction={removeFromCartAction}
                        lazyLoad={false}
                        key={poster._id}
                        product={poster}
                        qty={findCartQuantity(poster._id)}
                    />
                </div>)}
            </Slider>
        </div>
    </div>
}

export default PostersModal
