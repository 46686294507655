import Api from "../controllers/Api";
import * as _ from 'loadsh'

export const TYPES = {
    FETCHED_WISHLIST_ITEMS: 'FETCHED_WISHLIST_ITEMS',
    FETCHED_WISHLIST_ITEMS_FAILED: 'FETCHED_WISHLIST_ITEMS_FAILED',
    ADD_TO_WISHLIST: 'ADD_TO_WISHLIST',
    REMOVE_ITEM_FROM_WISHLIST: 'REMOVE_ITEM_FROM_WISHLIST',
    CLEARED_WISHLIST: 'CLEARED_WISHLIST',
    TOOGLE_WISHLIST_ITEM: 'TOOGLE_WISHLIST_ITEM',
    TOOGLE_WISHLIST_ITEM_FAILED: 'TOOGLE_WISHLIST_ITEM_FAILED',
}

export const clearWishlist = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: TYPES.CLEARED_WISHLIST,
            })
        } catch (e) {
        }
    }
}

export const addToWishlistAction = (productId) => {
    return async (dispatch, getState) => {
        try {

            //Check if active customer
            const {customer: {activeCustomer}} = getState()

            if (!activeCustomer) {
                alert('Please select a customer first')
                return
            }

            dispatch({
                type: TYPES.ADD_TO_WISHLIST,
                payload: productId
            })

            dispatch(saveWishlist())
        } catch (e) {
        }
    }
}

export const getWishlistItems = () => {
    return async (dispatch, getState) => {
        try {

            //Check if active customer
            const {customer: {activeCustomer}} = getState()

            if (!activeCustomer) {
                throw new Error('Please select a customer first')
            }

            const items = await Api.wishlist.get(activeCustomer._id)

            dispatch({
                type: TYPES.FETCHED_WISHLIST_ITEMS,
                payload: items
            })
        } catch (e) {
            console.error(e)
            dispatch({
                type: TYPES.FETCHED_WISHLIST_ITEMS_FAILED,
            })
        }
    }
}

export const removeFromWishlist = (itemId, type) => {
    return async (dispatch, getState) => {
        //Check if active customer
        const {customer: {activeCustomer}} = getState()

        if (activeCustomer) {
            dispatch({
                type: TYPES.REMOVE_ITEM_FROM_WISHLIST,
                payload: {
                    itemId,
                    type
                }
            })

            dispatch(saveWishlist())
        }
    }
}

export const wishlistToggleAction = (itemId, type) => {
    return async (dispatch, getState) => {
        try {
            //Check if active customer
            const {customer: {activeCustomer}} = getState()

            if (!activeCustomer) {
                throw new Error('Please select a customer first')
            }

            dispatch({
                type: TYPES.TOOGLE_WISHLIST_ITEM,
                payload: {
                    itemId,
                    type
                }
            })

            dispatch(saveWishlist())
        } catch (e) {

            switch (e.message) {
                case 'Please select a customer first': {
                    alert(e.message)
                    break
                }
                default: {
                    alert('There was an error saving your wishlist. Please try again later')
                }
            }

            dispatch({
                type: TYPES.TOOGLE_WISHLIST_ITEM_FAILED,
            })
        }
    }
}

const saveWishlistFunction = async (items, customerId) => {
    try {
        await Api.wishlist.saveWishlist(items, customerId)
    } catch (e) {
        // alert('Failed to wishlist. Please refresh and check your internet connection.')
        // window.location.reload();
        console.log(e)
    }
}

const debouncedSaveWishlist = _.debounce(saveWishlistFunction, 500)

export const saveWishlist = () => {
    return async (dispatch, getState) => {
        const {wishlist: {items}, customer: {activeCustomer}} = getState()
        if (activeCustomer && activeCustomer._id) {
            debouncedSaveWishlist(items, activeCustomer._id)
        }
    }
}
