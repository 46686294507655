import React, {Component} from 'react'
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import {getProductsAction, getSamplesAction, setProductPrices, getPostersAction, getIfAnyNewProduct} from '../../actions/productAction'
import {getFamilies} from '../../actions/familiesActions'
import {getCartAction, clearCartMutatedMessage, clearCartAction, getSampleCartAction} from '../../actions/cartActions'
import {saveOrderAction} from '../../actions/checkoutActions'
import {getSettings} from '../../actions/settingsActions'
import {logoutAction, getCompanySalesmen} from '../../actions/authActions'
import {getCustomersAction, getCustomerCountriesAction} from "../../actions/customerActions";
import {getSeasons, getActiveSeasons} from '../../actions/seasonActions'
import {bootstrapAction} from '../../actions/bootstrapActions'

import AccountPage from './AccountPage'

import WelcomePage from "./WelcomePage";
import CartPage from "./CartPage";
import CheckoutPage from "./CheckoutPage";
import ThankYouPage from "./ThankYouPage";
import CustomerPage from "./CustomerPage";
import SamplesPage from "./SamplesPage";
import FamilyPage from "../Family/FamilyPage";
import WishlistPage from "../Wishlist/WishlistPage";
import PresentationPage from "./PresentationPage";
import SingleProductPage from "./SingleProductPage";
import SeasonArchivePage from "./SeasonArchivePage";
import SeasonProductsPage from "./SeasonProductsPage";
import TopProductsPage from "./TopProductsPage";
import CampaignPage from "../Pages/Campaigns/CampaignPage";
import AdminPage from "../Admin/AdminPage";
import Header from "../Header";
import Loader from '../Common/Loader'
import Api from "../../controllers/Api";
import ProductSingle from "./ProductSingle";
import SalesmanInformationPage from '../Pages/SalesmanInformationPage'
import CustomerInformationPage from '../Pages/CustomerInformationPage'

import ArchivePage from '../Pages/Archive/ArchivePage'
import AdminMenu from "../Admin/AdminMenu";
import NoticePresenter from "../NoticePresenter";
import CustomersPanel from "../Panels/CustomersPanel";
import CartMutationPopup from "../CartMutationPopup";
import ScrollToTop from "../ScrollToTop";

class UserPage extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            getProductsAction,
            getPostersAction,
            getSamplesAction,
            getIfAnyNewProduct,
            auth: {authenticated},
            getCustomersAction,
            getCustomerCountriesAction,
            getCompanySalesmen,
            getSettings,
            getSeasons,
            bootstrapAction,
        } = this.props;

        if (authenticated) {
            bootstrapAction();
            getCustomersAction();
            getCustomerCountriesAction();
            getCompanySalesmen();
            getSamplesAction();
            getIfAnyNewProduct();
            Api.notification.subscribeUser();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            auth: {authenticated},
            customer: {activeCustomer},
            product: {pricesSet, products},
            history,
            setProductPrices
        } = this.props;

        if (!pricesSet && products.length) {
            setProductPrices()
        }

        //Check if we've switched customer
        if (
            prevProps.customer.activeCustomer &&
            prevProps.customer.activeCustomer._id &&
            activeCustomer &&
            activeCustomer._id &&
            prevProps.customer.activeCustomer._id !== activeCustomer._id
        ) {
            history.push('/app')
        }

        var tempElement = document.getElementById('link_1')
            if(tempElement){
                //tempElement.click()
            }
    }

    render() {
        const {
            auth: {activeUser, canPreorder},
            customer: {activeCustomer},
            history,
            logoutAction,
            settings: {settings},
            headerSettings: {headerSettings},
            cartCounter,
            cartCounterSample,
            clearCartMutatedMessage,
            showCartMutationMessage,
            saveOrderAction,
            product
        } = this.props

        if (!activeUser) {
            return <div className="loading-container">
                <Loader/>
            </div>
        }

        return <div className="user-page">
            <CartMutationPopup
                showCartMutationMessage={showCartMutationMessage}
                clearCartMutatedMessage={clearCartMutatedMessage}
                completeOrder={() => {
                    clearCartMutatedMessage()
                    history.push('/app/checkout')
                }}
                saveOrderAction={saveOrderAction}
            />
            <NoticePresenter/>
            <Header
                activeCustomer={activeCustomer}
                activeUser={activeUser}
                history={history}
                logoutAction={logoutAction}
                navigateToUserPanel={() => history.push('/app/account')}
                settings={settings}
                headerSettings={headerSettings}
                cartCounter={cartCounter}
                cartCounterSample={cartCounterSample}
                canPreorder={canPreorder}
                isNewProductExist={product.isNewProductExist}
            />
            <Route path="/app/" exact component={WelcomePage}/>

            <Route path="/app/cart" exact component={CartPage}/>
            {/*<Route path="/app/campaign" exact component={CampaignPage}/>*/}
            <Route path="/app/admin" exact component={AdminPage}/>
            <Route path="/app/admin/menu" exact component={AdminMenu}/>
            <Route path="/app/samples" exact component={SamplesPage}/>
            <Route path="/app/customer" component={CustomerPage}/>
            <Route path="/app/customer-information" component={CustomerInformationPage}/>
            <Route path="/app/checkout" exact component={CheckoutPage}/>
            <Route path="/app/thank-you/" exact component={ThankYouPage}/>
            <Route path="/app/thank-you/:orderId" exact component={ThankYouPage}/>
            <Route path="/app/account" component={AccountPage}/>
            <Route path="/app/salesman/information" component={SalesmanInformationPage}/>
            <Route path="/app/wishlist" exact component={WishlistPage}/>
            <Route path="/app/single" component={SingleProductPage}/>

            {/*Product Archives*/}
            <Route path="/app/archive" exact component={ArchivePage}/>

            {/*Product Archives For Cooming Soon*/}
            <Route
                path="/app/coming-soon-archive"
                exact
                component={(props) => <ArchivePage
                    {...props}
                    disabledBuy={true}
                    comingSoonProducts={true}
                />}
            />

            <Route path="/app/presentation" exact component={PresentationPage}/>
            <Route path="/app/presentation/season/:seasonId" exact component={SeasonArchivePage}/>
            <Route path="/app/presentation/season/:seasonId/products" exact component={SeasonProductsPage}/>
            <Route path="/app/presentation/season/:seasonId/products/top" exact component={TopProductsPage}/>
            <Route path="/app/presentation/season/:seasonId/products/top/salesman" exact component={TopProductsPage}/>
            <Route path="/app/presentation/family/:familyId" exact component={FamilyPage}/>
            <Route path="/app/presentation/campaign/:seasonId" exact component={CampaignPage}/>

            {/*Product Single*/}
            <Route path="/app/product/:productId" exact component={ProductSingle}/>
            <Route
                path="/app/presentation/product/:productId"
                exact
                component={(props) => <ProductSingle
                    {...props}
                    presentationMode={true}
                />}
            />

            <ScrollToTop/>

        </div>
    }
}

const mapStateToProps = ({product, auth, customer, settings, cart, headerSettings}) => {
    return {
        product,
        auth,
        customer,
        settings,
        headerSettings,
        cartCounter: cart.cartCount,
        cartCounterSample: cart.cartCountSample,
        showCartMutationMessage: cart.showCartMutationMessage
    }
}

export default connect(mapStateToProps, {
    getProductsAction,
    getPostersAction,
    getSamplesAction,
    getIfAnyNewProduct,
    getCartAction,
    getSampleCartAction,
    logoutAction,
    getCustomersAction,
    getCustomerCountriesAction,
    setProductPrices,
    getSettings,
    getFamilies,
    getCompanySalesmen,
    getSeasons,
    getActiveSeasons,
    bootstrapAction,
    clearCartMutatedMessage,
    clearCartAction,
    saveOrderAction,
})(UserPage)

