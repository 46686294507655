import axios from 'axios';

class Auth {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async initCustomerVerification() {
        const verificationMethod = await axios.get(`${this.apiUrl}/auth/initCustomerVerification`)

        if(!verificationMethod || !verificationMethod.status === 200) {
            return false
        }

        return verificationMethod.data.payload

    }

    async checkIfCanPreorder(userId) {
        const canPreorder = await axios.post(`${this.apiUrl}/auth/userCanPreorder`, {
            userId
        })

        if(!canPreorder || !canPreorder.status === 200 || !canPreorder.data.payload) {
            return false
        }

        return canPreorder.data.payload

    }

    async verifyUser(pin) {
        const verificationMethod = await axios.post(`${this.apiUrl}/auth/verifyUser`, {
            pin
        })

        if(!verificationMethod || !verificationMethod.status === 200) {
            return false
        }

        return true
    }

    async requestPasswordReset(email) {
        const requestPassResponse = await axios.post(`${this.apiUrl}/auth/requestPass`, {
            email
        })

        return true
    }

    async setSelectedCustomer(customerId) {
        const setSelectedCustomer = await axios.post(`${this.apiUrl}/auth/setSelectedCustomer`, {
            customerId
        })
    }

    async getSelectedCustomer() {
        const selectedCustomer = await axios.get(`${this.apiUrl}/auth/getSelectedCustomer`)
        if(!selectedCustomer || !selectedCustomer.status === 200 || !selectedCustomer.data.payload.customerId) {
            return false
        }

        return selectedCustomer.data.payload.customerId
    }

    async getCompanySalesmen() {
        const salesmen = await axios.get(`${this.apiUrl}/auth/salesmen`)

        if(!salesmen || !salesmen.status === 200 || !salesmen.data.payload.length) {
            return []
        }

        return salesmen.data.payload
    }

    async login(email, password, token) {
        const loginResult = await axios.post(`${this.apiUrl}/auth/login`, {
            email,
            password,
            token
        })

        if(loginResult.status === 200 && loginResult.data.payload && loginResult.data.payload.token) {
            //Store token to localstorage
            localStorage.setItem('vamp_salesapp_token', loginResult.data.payload.token);
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + loginResult.data.payload.token;
            axios.defaults.withCredentials = true;

            return loginResult.data.payload.token
        }
    }

    async checkToken(token) {
        axios.defaults.headers.common['Authorization'] =
            'Bearer ' + token;
        axios.defaults.withCredentials = true;

        let tokenValid = await axios.get(`${this.apiUrl}/auth/checkToken`);

        if(tokenValid.status !== 200) {
            throw new Error('Invalid Token')
        }

        return token;
    }
}

export default Auth
