import React, { Component } from 'react'
import {ReactComponent as ScrollToTopSvg} from './assets/up-arrow.svg';

import './ScrollToTop.scss'

class ScrollToTop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isScrolled: false
        };

        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

   trackScrolling() {
        let isScrolled = false

        if(window.pageYOffset > 500) {
            isScrolled = true
        }

        this.setState({
            isScrolled
        })
   }

    render() {
        return <div className={`${this.state.isScrolled ? 'show-element' : ''}`} id="scroll-to-top-component" onClick={() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });}
        }>
            <div className="scroll-to-top-container">
                <ScrollToTopSvg/>
            </div>
        </div>
    }
}

export default ScrollToTop