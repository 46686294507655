import React from 'react';

import './MultiGridTotals.scss'

const MultiGridTotals = ({rows: { productRows }, currency}) => {
    let totalQty = 0;
    let totalPrice = 0;

    if(!productRows || !productRows.length) {
        return null
    }

    for(let i = 0; i < productRows.length; i++) {
        if(productRows[i].sizes) {
            //Variable
            for(let j = 0; j < productRows[i].sizes.length; j++) {
                totalQty += parseInt(productRows[i].sizes[j].inputValue)
                totalPrice += parseInt(productRows[i].sizes[j].inputValue) * parseFloat(productRows[i].sizes[j].price)
            }
        }else {
            //Simple, we have the lowest price as a price reference here
            totalQty += parseInt(productRows[i].inputValue)
            totalPrice += parseInt(productRows[i].inputValue) * parseFloat(productRows[i].lowestPrice)
        }
    }

    totalPrice = totalPrice.toFixed(2)

    return <div className="grid-totals">
        <div className="grid-total-qty">
            <div className="label">
                total quantity
            </div>
            <div className="value">
                {totalQty}
            </div>
        </div>
        <div className="grid-total-price">
            <div className="label">
                total price
            </div>
            <div className="value">
                {currency}{totalPrice}
            </div>
        </div>
    </div>
}

export default MultiGridTotals
