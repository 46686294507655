import React, {Component} from 'react'
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Link} from "react-router-dom";

import {getCustomersAction} from '../../actions/customerActions'
import {loadOrderAction, deleteOrderAction, clearSavedOrder} from '../../actions/orderActions'

import AccountSidebar from '../AccountSidebar/AccountSidebar'
import AccountSidebarItem from '../AccountSidebar/AccountSidebarItem'
import AccountPanel from "../Panels/AccountPanel";
import CustomerAccessPage from "../Customer/CustomerAccessPage"
import CustomersBalancesPage from "./Customer/CustomersBalancesPage"
import UserOrdersPanel from "../Panels/UserOrdersPanel";
import CustomersPanel from "../Panels/CustomersPanel";
import CustomersCreateForm from "../Forms/CustomersCreateForm";
import SalesmanSeasonsCompare from '../Salesman/Infos/SalesmanSeasonsCompare'
import SalesmanTurnovers from '../Salesman/Infos/SalesmanTurnovers'

import {ReactComponent as SalesmanOrdersSvg} from '../assets/salesman/salesmanOrderStatistics.svg';
import {ReactComponent as SalesmanTurnoversSvg} from '../assets/salesman/salesmanTurnovers.svg';
import {ReactComponent as BackSvg} from '../assets/back_flip.svg';
import {ReactComponent as CustomerBalancesSvg} from '../assets/invoices_new.svg';

import './AccountPage.scss'
import Api from "../../controllers/Api";
import {ReactComponent as InvoicesNewSvg} from "../assets/customer_balances_new.svg";
import {ReactComponent as OrderSvg} from "../assets/order-1.svg";
import CustomerInvoices from "../Customer/Infos/CustomerInvoices";
import CustomerCredits from "../Customer/Infos/CustomerCredits";
import { withTranslation } from 'react-i18next';

class CustomerInformationPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const { t } = this.props;
        const {
            auth: {activeUser},
            customer: {customers, activeCustomer},
            loadOrderAction,
            history
        } = this.props

        return <div className={`account-page customer-information-page role-${activeUser.role}`}>
            <div className="account-sidebar">
                <AccountSidebar>
                    <AccountSidebarItem
                        title={t("Back to my Account")}
                        link="/app/customer/account"
                        icon={<BackSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Invoices")}
                        link="/app/customer-information/invoices"
                        icon={<InvoicesNewSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Credit Notes")}
                        link="/app/customer-information/credits"
                        icon={<OrderSvg/>}
                    />
                </AccountSidebar>
            </div>
            <div className="account-panel">
                <Route path="/app/customer-information/invoices" exact component={(props) => <CustomerInvoices {...props}
                                                                                                   user={activeCustomer}
                />}/>
                <Route path="/app/customer-information/credits" exact component={(props) => <CustomerCredits {...props}
                                                                                                 user={activeCustomer}
                />}/>
            </div>
        </div>
    }
}

const mapStateToProps = ({auth, customer}) => {
    return {
        auth,
        customer
    }
}

export default withTranslation()(connect(mapStateToProps, {
    getCustomersAction,
    loadOrderAction,
    deleteOrderAction,
    clearSavedOrder,
})(CustomerInformationPage))

