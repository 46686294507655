import React, { Component } from 'react'

import AddressListItem from './AddressListItem'
import AddressForm from '../../components/Forms/AddressForm'
import { withTranslation } from 'react-i18next';

import './AddressList.scss'
import Button from "../Button";

class AddressList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editAddressId: null,
            showAddressForm: false
        }
    }

    editAddress(addressId) {
        this.setState({
            editAddressId: addressId,
            showAddressForm: true
        })
    }

    closeForm() {
        this.setState({
            editAddressId: null,
            showAddressForm: false
        })
    }

    openAddressForm() {
        this.setState({
            showAddressForm: true,
            editAddressId: null
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const element = document.getElementById("customer-address-edit-form");

        if(element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    }

    render() {
        const { t } = this.props;
        const { addresses, setDefaultAddress, customerId } = this.props
        const { editAddressId, showAddressForm } = this.state

        if(!addresses || !addresses.length) {
            return <div>
                <p><strong>{t('The billing address will be used as shipping.')}</strong></p>
                <p>{t('There are not any additional shipping addresses.')}</p>
                {showAddressForm ? <AddressForm closeForm={() => this.closeForm()} address={addresses.filter((address) => address._id === editAddressId)} /> : null }
                {!showAddressForm ? <Button callback={() => this.openAddressForm()} title={t("Create new Address")} /> : null}
            </div>
        }

        return <div className="address-list-component">
            <ul className="address-list">
                {addresses.map((address) => <AddressListItem
                    setDefaultAddress={(addressId) => {
                        setDefaultAddress(addressId, customerId)
                    }}
                    key={address._id}
                    editAddress={(addressId) => this.editAddress(addressId)}
                    address={address}
                />)}
            </ul>
            {showAddressForm ? <AddressForm id="customer-address-edit-form" closeForm={() => this.closeForm()} address={addresses.filter((address) => address._id === editAddressId)} /> : null }
            {!showAddressForm ? <Button callback={() => this.openAddressForm()} title={t("Create new Address")} /> : null}
        </div>
    }

}

export default withTranslation()(AddressList)
