import React from 'react'

import './PanelHeader.scss'

const PanelHeader = ({title}) => {
    return <header className="panel-header">
        {title}
    </header>
}

export default PanelHeader
