import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Api from '../../controllers/Api';
import {
    updateCustomerSeasonsAction,
    createCustomerAccessAction,
    forceVerifyCustomer,
    revokeCustomerAccessAction,
    forceAllowCustomerAccessAction,
    updateEmail,
    updateMobile
} from '../../actions/customerActions'

import CustomerList from "./CustomerList";
import PanelSearch from "../Common/PanelSearch";
import PanelTopControls from "../Panels/PanelTopControls";
import CustomerAccessFilters from './Access/CustomerAccessFilters'
import { customerCanLogin } from '../../utils/utils'
import './CustomerAccessPage.scss'
import PanelHeader from "../Panels/PanelHeader";

const filterAccessCustomers = (customers, searchTerm, customersWithPreorder, salesmanId, filters) => {

    if(!customers || !customers.length) {
        return []
    }

    const activeFilters = Object.values(filters).filter(filterItem => {
        if(filterItem.type === 'select') {
            if(filterItem.value != 0) {
                return true;
            }
        }else if(filterItem.type === 'checkbox') {
            return filterItem.value;
        }

        return false
    })

    return customers.filter(customer => {
        if((!activeFilters || !activeFilters.length) && (!searchTerm || searchTerm.length < 3)) {
            return true
        }

        const validFilters = activeFilters.map((filterItem) => {
            if(filterItem.key === 'myCustomers') {
                const customerBelongsToSalesman = customer.customer[0].salesmen.includes(salesmanId)
                if(filterItem.value) {
                    return customerBelongsToSalesman
                }else {
                    return !customerBelongsToSalesman
                }
            }

            if(filterItem.key === 'hasOrder') {
                const customerHasOrder = customersWithPreorder.includes(customer.softoneId.toString())
                if(filterItem.value == 1) {
                    return customerHasOrder
                }else {
                    return !customerHasOrder
                }
            }

            if(filterItem.key === 'canPreorder') {
                if(filterItem.value == 1) {
                    return customer.customer[0].canPreorder === true
                }else {
                    return customer.customer[0].canPreorder === false
                }
            }

            if(filterItem.key === 'didLogin') {
                if(filterItem.value == 1) {
                    return customer.firstLoginIp !== null
                }else {
                    return customer.firstLoginIp === null
                }
            }

            if(filterItem.key === 'potential') {
                if(filterItem.value == 1) {
                    return customer.customer[0].potential === true
                }else {
                    return customer.customer[0].potential === false
                }
            }
        })

        if(searchTerm && searchTerm.length > 3) {
            let searchTermNormalized = searchTerm.toLowerCase().trim()

            if(
                customer.name.toLowerCase().includes(searchTermNormalized) ||
                customer.customer[0].altCode && customer.customer[0].altCode.toLowerCase().includes(searchTermNormalized) ||
                customer.email && customer.email.toLowerCase().includes(searchTermNormalized) ||
                customer.customer[0].billingAddress.city && customer.customer[0].billingAddress.city.toLowerCase().includes(searchTermNormalized)
            ) {
                validFilters.push(true)
            }else {
                validFilters.push(false)
            }
        }

        return !validFilters.includes(false)
    })
}

const CustomerAccessPage = ({
                                customers,
                                seasons,
                                updateCustomerSeasonsAction,
                                createCustomerAccessAction,
                                forceVerifyCustomer,
                                revokeCustomerAccessAction,
                                forceAllowCustomerAccessAction,
                                settings,
                                updateEmail,
                                updateMobile,
                                activeUserId,
                                activeUserCountryCode,
                                activeUserRole,
}) => {

    const [ searchTerm, setSearchTerm ] = useState('')
    const [ customersSoftoneIdsWithSeasonOrders, setCustomersSoftoneIdsWithSeasonOrders ] = useState([])
    const [ fetchingSeasonOrder, setFetchingSeasonOrder ] = useState(false)

    useEffect(() => {
        //Get customers with season orders on mount
        if(
            settings.defaultPresentationSeason &&
            !customersSoftoneIdsWithSeasonOrders.length &&
            !fetchingSeasonOrder
        ) {
            Api.customer.getSeasonOrders(settings.defaultPresentationSeason).then(preorderCustomersSoftoneIds => {
                setCustomersSoftoneIdsWithSeasonOrders(preorderCustomersSoftoneIds)
            }).catch((e) => {
                console.log(e)
            })

            setFetchingSeasonOrder(true)
        }
    }, [settings])

    const [ filters, setFilters ] = useState({
        myCustomers: {
            type: "checkbox",
            key: "myCustomers",
            label: "My Customers",
            value: false,
        },
        canPreorder: {
            type: "select",
            key: "canPreorder",
            label: "Can Preorder",
            value: 0,
        },
        hasOrder: {
            type: "select",
            key: "hasOrder",
            label: "Order",
            value: 0,
        },
        // didLogin: {
        //     type: "select",
        //     key: "didLogin",
        //     label: "Did login",
        //     value: 0,
        // },
        potential: {
            type: "select",
            key: "potential",
            label: "Potential",
            value: 0,
        },
    })

    //Prepare seasons array
    const seasonsArray = seasons.filter(season => settings.presentationSeasons.includes(season.softoneId))

    return <div className="customers-access-page">
        <PanelHeader title="Customers Access"/>
        <PanelTopControls>
            <PanelSearch value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            <CustomerAccessFilters
                filters={filters}
                handleFilterChange={(filterName, filterValue) => setFilters({
                    ...filters,
                    [filterName]: {
                        ...filters[filterName],
                        value: filterValue
                    }
                })}
            />
        </PanelTopControls>
        <CustomerList
            perPage={9}
            handleCustomerSelect={() => {}}
            customers={filterAccessCustomers(
                customers,
                searchTerm,
                customersSoftoneIdsWithSeasonOrders,
                activeUserId,
                filters
            )}
            accessData={true}
            seasons={seasonsArray}
            customersSoftoneIdsWithSeasonOrders={customersSoftoneIdsWithSeasonOrders}
            updateCustomerSeasons={updateCustomerSeasonsAction}
            createCustomerAccess={createCustomerAccessAction}
            forceVerifyCustomer={forceVerifyCustomer}
            revokeCustomerAccess={revokeCustomerAccessAction}
            forceAllowCustomerAccess={forceAllowCustomerAccessAction}
            updateEmail={updateEmail}
            updateMobile={updateMobile}
            role={activeUserRole}
            activeUserCountryCode={activeUserCountryCode}
        />
    </div>
}

const mapStateToProps = ({customer, season, settings, auth}) => {
    return {
        customers: customer.customers,
        seasons: season.seasons,
        settings: settings.settings,
        activeUserId: auth.activeUser.id,
        activeUserCountryCode: auth.activeUser.softoneCountryCode,
        activeUserRole: auth.activeUser.role,
    }
}

export default connect(mapStateToProps, {
    updateCustomerSeasonsAction,
    createCustomerAccessAction,
    forceVerifyCustomer,
    revokeCustomerAccessAction,
    forceAllowCustomerAccessAction,
    updateEmail,
    updateMobile,
})(CustomerAccessPage)
