import axios from 'axios';

class Cart {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async saveCart(cart, customerId, presentationMode = false) {
        await axios.post(`${this.apiUrl}/cart`, {
            cart,
            customerId,
            presentationMode,
        })
    }

    async saveSampleCart(cart, customerId, presentationMode = false) {
        await axios.post(`${this.apiUrl}/cart/sample/save`, {
                cart,
                customerId,
                presentationMode,
            })
    }

    async clearCart(customerId) {
        await axios.delete(`${this.apiUrl}/cart/customer/${customerId}`)
    }

    async getCartByOrderId(orderId) {
        const cartResponse = await axios.get(`${this.apiUrl}/cart/order/${orderId}`)
        if(!cartResponse || !cartResponse.status === 200) {
            return false
        }

        return cartResponse.data.payload
    }

    async getCart(customerId) {
        const cart = await axios.get(`${this.apiUrl}/cart/customer/${customerId}`)

        if(!cart || !cart.status === 200 || !cart.data.payload.items || !cart.data.payload.items.length) {
            return {
                items: [],
                presentationMode: false
            }
        }

        return {
            items: cart.data.payload.items,
            presentationMode: cart.data.payload.presentationMode
        }
    }

    async getSampleCart(customerId) {
       const cart = await axios.get(`${this.apiUrl}/cart/customer/sample/${customerId}`)

           if(!cart || !cart.status === 200 || !cart.data.payload.items || !cart.data.payload.items.length) {
               return {
                       items: [],
                       presentationMode: false
               }
           }

        return {
            items: cart.data.payload.items,
            presentationMode: cart.data.payload.presentationMode
        }
    }

}

export default Cart
