import { TYPES } from '../actions/authActions'
import { TYPES as SEASONTYPES } from '../actions/seasonActions'
import { parseJwt } from '../utils/authenticationUtils'

const initialState = {
    authenticated: false,
    token: null,
    missingToken: false,
    triedToAuth: false,
    authenticationFailedMessage: '',
    redirectToLogin: false,
    activeUser: {
        id: null,
        email: null,
        name: null,
        softoneCountryCode: null,
        b2bView: null,
        allAccess: false,
        role: 'salesman'
    },
    userSalesmen: [],

    canPreorder: false,
    preorderAuthenticated: false,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_PRESENTATION_ACCESS: {

            const presentationAccessResponse = action.payload


            return {
                ...state,
                canPreorder: presentationAccessResponse.canPreorder,
                preorderAuthenticated: presentationAccessResponse.preorderAuthenticated,
            }
        }
        case TYPES.CLEAR_REDIRECT_FLAGS: {
            return {
                ...state,
                redirectToLogin: true,
            }
        }
        case TYPES.MISSING_TOKEN: {
            return {
                ...state,
                missingToken: true
            }
        }
        case TYPES.REDIRECTED_TO_LOGIN: {
            return {
                ...state,
                redirectToLogin: false,
                triedToAuth: true,
            }
        }
        case TYPES.COMPANY_SALESMEN_FETCHED: {

            const salesmen = action.payload

            return {
                ...state,
                userSalesmen: salesmen,
            }
        }
        case TYPES.LOGIN_SUCCESS: {
            //Get user data from access token
            const jwtDecoded = parseJwt(action.payload)

            //Decode user name
            var decodedName = decodeURIComponent(escape(window.atob( jwtDecoded.user.name )))

            return {
                ...state,
                authenticated: true,
                token: action.payload,
                triedToAuth: true,
                redirectToLogin: false,
                authenticationFailedMessage: '',
                activeUser: {
                    id: jwtDecoded.user.id,
                    email: jwtDecoded.user.email,
                    name: decodedName,
                    b2bView: jwtDecoded.user.b2bView,
                    softoneCountryCode: jwtDecoded.user.softoneCountryCode,
                    currency: jwtDecoded.user.currency,
                    allAccess: jwtDecoded.user.allAccess,
                    role: jwtDecoded.user.role,
                },
            }
        }
        case TYPES.LOGIN_FAILED: {
            return {
                ...state,
                triedToAuth: true,
                authenticationFailedMessage: action.payload
            }
        }
        case TYPES.LOGOUT_ACTION: {
            return {
                ...state,
                ...initialState,
                triedToAuth: true
            }
        }
        default:
            return state
    }
};


export default auth
