import {TYPES} from '../actions/settingsActions'

const initialState = {
    settings: {
        homepage_image_1: '',
        homepage_image_2: '',
        homepage_image_3: '',
        simone_perele_buy_now_image: '',
    }
};

const homepageSettings = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.FETCHED_HOMEPAGE_SETTINGS: {
            return {
                ...state,
                settings: action.payload,
            }
        }
        default:
            return state
    }
};


export default homepageSettings
