import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux'

import {ReactComponent as BackSvg} from "../assets/back.svg";

import {setLastVisitedProduct} from "../../actions/productAction";
import { wishlistToggleAction } from "../../actions/wishlistActions";
import {openQuickViewAction} from '../../actions/quickViewActions'

import './ProductSingle.scss'
import Loader from "../Common/Loader";
import Api from "../../controllers/Api";
import SimpleMode from "./Product/SimpleMode";
import GridMode from "./Product/GridMode";
import { useTranslation } from "react-i18next";

const ProductSingle = ({
                           match,
                           history,
                           wishlistToggleAction,
                           wishlistItems,
                           openQuickViewAction,
                           quickView: { quickViewProductId },
                           variationPrices,
                           presentationMode,
                           activeCustomer,
                           activeUser,
                           setLastVisitedProduct
}
                           ) => {
    const { t } = useTranslation();
    //Get product id
    const {params: {productId}} = match

    const [ product, setProduct ] = useState(false)

    useEffect(() => {

        setLastVisitedProduct(productId)

        if(!product || product._id !== productId) {
            Api.product.get(productId, presentationMode)
                .then(productPayload => setProduct((productPayload)))
                .catch(res => {
                history.push('/app')
            })
        }
    }, [])

    if(!product) {
        return <div className="product-loader">
            <Loader />
        </div>
    }

    //If product is not isPreorder, get the "Quickview mode"
    return <div className="product-single-page">
        <header className="product-single-header">
            <button className="back-button" onClick={() => history.goBack()}><BackSvg/><span>{t('Back')}</span></button>
        </header>
        {/*{product.markedAsPreorder ?*/}
        {presentationMode ?
            <SimpleMode
            product={product}
            variationPrices={variationPrices}
            activeCustomer={activeCustomer}
            activeUser={activeUser}
            openQuickViewAction={openQuickViewAction}
            quickViewProductId={quickViewProductId}
            wishlistToggleAction={wishlistToggleAction}
            isInWishlist={wishlistItems.includes(product._id)}
            /> :
            <GridMode
                product={product}
                activeCustomer={activeCustomer}
            />
        }

    </div>
}

const mapStateToProps = ({auth, product, wishlist, quickView, customer}) => {
    return {
        activeUser: auth.activeUser,
        products: product.products,
        wishlistItems: wishlist.items.products,
        quickView,
        variationPrices: product.variationPrices,
        activeCustomer: customer.activeCustomer
    }
}

export default connect(mapStateToProps, { wishlistToggleAction, openQuickViewAction, setLastVisitedProduct })(ProductSingle)
