import React from 'react'

import './FormSection.scss'

const FormSection = ({ title, children }) => {
    return <div className="form-section">
        <header className="form-section-title">
            {title}
        </header>
        <div className="form-section-content">
            {children}
        </div>
    </div>
}

export default FormSection
