import React from 'react'
import Scanner from '../Common/Scanner'
import {ReactComponent as CloseSvg} from '../assets/close.svg';

import './BarcodeModal.scss'

const BarcodeModal = ({closeBarcodeScanner, setCode}) => {
    return <div className="barcode-modal">
        <div className="modal-container">
            <div className="close-modal" onClick={() => closeBarcodeScanner()}>
                <CloseSvg />
            </div>
            <Scanner onDetected={(code) => {
                const foundCode = Object.assign({}, code)
                if(foundCode && foundCode.codeResult && foundCode.codeResult.code) {
                    setCode(foundCode.codeResult.code)
                    closeBarcodeScanner()
                }
            }}/>
        </div>

    </div>
}

export default BarcodeModal
