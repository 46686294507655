import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./reducers";
import reduxThunk from "redux-thunk";
import { createLogger } from 'redux-logger'
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger({
    // ...options
});

// const store = createStore(
//     rootReducer,
//     composeEnhancer(applyMiddleware(reduxThunk, logger)),
// );

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(reduxThunk)),
);

export default store;