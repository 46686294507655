import React, { Component } from 'react'
import { connect } from 'react-redux'
import Cart from '../Cart/Cart'
import QuickView from '../Quickview/QuickView'

import './CartPage.scss'

class CartPage extends Component {

    constructor(props) {
        super(props);
    }

    printCheckoutErrorMessage(failedOrderPayload) {
        if(!failedOrderPayload || !Object.keys(failedOrderPayload).length) {
            return null
        }
        switch (failedOrderPayload.type) {
            case 'missing_stock': {
                return <div className="out-of-stock-products checkout-error-response">
                    <p>Some of the requested products are currently out of stock</p>
                    <p>Please update your cart and proceed to checkout</p>
                    {/*<ul>*/}
                    {/*    {Object.keys(failedOrderPayload.items).map(barcode => {*/}
                    {/*        return <li>*/}
                    {/*            <strong>{barcode}</strong>*/}
                    {/*            Requested: {failedOrderPayload.items[barcode].requested} / Available: {failedOrderPayload.items[barcode].available}*/}
                    {/*        </li>*/}
                    {/*    })}*/}
                    {/*</ul>*/}
                </div>
            }
        }

        return null
    }

    render() {
        const { history, quickView: { quickViewProductId }, checkout: { failedOrderPayload } } = this.props

        let failedOrdersBarcodes = []

        if(
            failedOrderPayload &&
            failedOrderPayload.items &&
            Object.keys(failedOrderPayload.items).length
        ) {
            Object.keys(failedOrderPayload.items).filter((key => failedOrdersBarcodes.push({
                key,
                data: failedOrderPayload.items[key]
            })))
        }

        return <div className="cart-page">
            {this.printCheckoutErrorMessage(failedOrderPayload)}
            <Cart
                history={history}
                failedOrderItems={failedOrdersBarcodes}
            />
            {quickViewProductId ? <QuickView productId={quickViewProductId}/> : null}
        </div>
    }
}

const mapStateToProps = ({ quickView, checkout }) => {
    return {
        quickView,
        checkout,
    }
}

export default connect(mapStateToProps, {})(CartPage)
