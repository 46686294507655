import React, { Fragment } from 'react'
import {ReactComponent as CartSvg} from '../assets/wheel.svg';
import { useTranslation } from "react-i18next";

import './CartSummaryWidget.scss'

const CartSummaryWidget = ({cartCount, cartTotalPrice, collectionsCount, underwearCount, points, hasPreorders}) => {
    const { t } = useTranslation();
    return <div className="cart-summary-widget">
        <CartSvg/>
        {points ? <span className="cart-count-item cart-count"><strong>{t('Available Points')}</strong> ({points})</span>
            : null}
        {hasPreorders ?
            <Fragment>
                <span className="cart-count-item cart-count"><span>{t('Collection')}</span> ({collectionsCount})</span>
                <span className="cart-count-item cart-count"><span>{t('Underwear')}</span> ({underwearCount})</span>
            </Fragment>
            : null}
        <span className="cart-count-item cart-count"><strong>{t('Total Qty')}</strong> ({cartCount})</span>
        <span className="cart-count-item cart-total-price"><strong>{t('Total Price')}</strong> ({cartTotalPrice})</span>
    </div>
}

export default CartSummaryWidget
