import { closeQuickViewAction } from "./quickViewActions";
import { getLivePrices } from "./productAction";
import { clearSavedOrder } from './orderActions'
import { removeFromWishlist, wishlistToggleAction } from './wishlistActions'
import Api from "../controllers/Api";
import * as _ from 'loadsh'

import {TYPES as noticeTypes} from './noticeActions'
import {TYPES as checkoutTypes} from './checkoutActions'

export const TYPES = {
    ADD_TO_CART: 'ADD_TO_CART',
    GET_CART: 'GET_CART',
    CLEAR_CART: 'CLEAR_CART',
    UPDATE_CART: 'UPDATE_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    GET_CART_SUCCESS: 'GET_CART_SUCCESS',
    GET_CART_SUCCESS_SAMPLE: 'GET_CART_SUCCESS_SAMPLE',
    EMPTY_CART: 'EMPTY_CART',
    EMPTY_CART_SAMPLE: 'EMPTY_CART_SAMPLE',
    CART_MUTATED: 'CART_MUTATED',
    CLEAR_CART_MUTATED: 'CLEAR_CART_MUTATED',
    CLEAR_CART_SAMPLE: 'CLEAR_CART_SAMPLE',
}

const saveCartFunction = async (cartItems, customerId, dispatch, presentationMode = false) => {
    try {
        await Api.cart.saveCart(cartItems, customerId, presentationMode)
    }catch (e) {

        let messageToReturn = 'Failed to save cart. Your order current status have been saved. Please refresh and check your internet connection.'

        if(e.response && e.response.data && e.response.data.message && e.response.data.message === 'cart_mutated') {
            // messageToReturn = 'Your cart cannot contain Preorder and Afterorder products at the same time'
            dispatch({
                type: TYPES.CART_MUTATED
            })
        }

        // alert(messageToReturn)



        dispatch(getCartAction())

        //window.location.reload();
    }
}

export const saveSampleCartFunction = async (cartItems, customerId, dispatch, presentationMode = false) => {
    try {
        await Api.cart.saveSampleCart(cartItems, customerId, presentationMode)
        console.log('success')
        dispatch({
            type: TYPES.GET_CART_SUCCESS_SAMPLE,
                payload: {
                items: cartItems,
                presentationMode: presentationMode
            }
        })
    }catch (e) {
        console.log('error')
        dispatch({
            type: TYPES.GET_CART_SUCCESS_SAMPLE,
            payload: {
                items: cartItems,
                presentationMode: presentationMode
            }
        })
    }
}

const debouncedSaveCart = _.debounce(saveCartFunction, 500)

export const getCartAction = () => {
    return async(dispatch, getState) => {
        try {

            const { customer: { activeCustomer } } = getState();

            if(!activeCustomer || !activeCustomer._id) {
                throw new Error('Please select a customer')
            }

            const cart = await Api.cart.getCart(activeCustomer._id)

            if(!cart || !cart.items || !cart.items.length) {
                dispatch({
                    type: TYPES.EMPTY_CART,
                })
            }else {

                //Check for live prices
                const productIds = cart.items.map(cartItem => cartItem.productId);
                dispatch(getLivePrices(productIds, activeCustomer._id))

                dispatch({
                    type: TYPES.GET_CART_SUCCESS,
                    payload: {
                        items: cart.items,
                        presentationMode: cart.presentationMode
                    }
                })
            }
        }catch (e) {
            console.log(e)
        }
    }
}

export const getSampleCartAction = () => {
    return async(dispatch, getState) => {
        try {

            const { customer: { activeCustomer } } = getState();

            if(!activeCustomer || !activeCustomer._id) {
                throw new Error('Please select a customer')
            }

            const cart = await Api.cart.getSampleCart(activeCustomer._id)

            if(!cart || !cart.items || !cart.items.length) {
                dispatch({
                        type: TYPES.EMPTY_CART_SAMPLE,
                    })
            }else {
                dispatch({
                        type: TYPES.GET_CART_SUCCESS_SAMPLE,
                        payload: {
                            items: cart.items,
                            presentationMode: cart.presentationMode
                        }
                })
            }
            }catch (e) {
                console.log(e)
            }
        }
}

export const clearCartMutatedMessage = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.CLEAR_CART_MUTATED
        })
    }
}

export const addToCartAction = (productsPayload, presentationMode = false) => {
    return async(dispatch, getState) => {
        try {

            //Check if active customer
            const {
                customer: { activeCustomer },
                product: { products },
                settings: { settings: { preorderSeasonsSelection } },
                cart: { cartItems }
            } = getState()

            if(!activeCustomer) {
                alert('Please select a customer first')
                return
            }

            dispatch({
                type: TYPES.ADD_TO_CART,
                payload: {
                    items: productsPayload,
                    presentationMode
                }
            })

            dispatch({
                type: noticeTypes.SUCCESS_MESSAGE,
                payload: 'Product Added to Cart successfully'
            })

            dispatch({
                type: checkoutTypes.CLEAR_ORDER_FAIL_MESSAGE,
            })

            dispatch(saveCart(presentationMode))

            //Check if items exists in wishlist
            const { wishlist } = getState()

            if(
                wishlist &&
                wishlist.items &&
                wishlist.items.products &&
                wishlist.items.products.length
            ) {

                const productIds = new Set();

                //Get product id
                for(let j = 0; j < productsPayload.length; j++) {
                    const productId = productsPayload[j].productId

                    if(productId) {
                        productIds.add(productId.toString())
                    }
                }

                if(productIds.size) {
                    const productIdsArray = Array.from(productIds)

                    for(let i = 0; i < wishlist.items.products.length; i++) {
                        if(productIdsArray.includes(wishlist.items.products[i].toString())) {
                            dispatch(wishlistToggleAction(wishlist.items.products[i], 'product'))
                            break;
                        }
                    }
                }
            }

            dispatch(closeQuickViewAction())
        }catch (e) {
            alert('There was an error while adding the product to your cart, please try again later')
        }
    }
}

export const updateCartAction = (productsPayload, presentationMode = false) => {
    return async(dispatch, getState) => {
        try {
            productsPayload.filter((item) => {
                dispatch({
                    type: TYPES.UPDATE_CART,
                    payload: item
                })
            })

            dispatch(saveCart(presentationMode))
            dispatch(closeQuickViewAction())

            dispatch({
                type: checkoutTypes.CLEAR_ORDER_FAIL_MESSAGE,
            })
        }catch (e) {
        }
    }
}

export const removeFromCartAction = (barcodes, presentationMode = false) => {
    return async(dispatch, getState) => {
        try {
            dispatch({
                type: TYPES.REMOVE_FROM_CART,
                payload: barcodes
            })

            dispatch(saveCart(presentationMode))

            dispatch({
                type: checkoutTypes.CLEAR_ORDER_FAIL_MESSAGE,
            })
        }catch (e) {
        }
    }
}

export const clearCartActionInState = () => {
    return async(dispatch, getState) => {
        try {
            dispatch({
                type: TYPES.CLEAR_CART,
            })

            dispatch(clearSavedOrder())
            dispatch(closeQuickViewAction())

            dispatch({
                type: checkoutTypes.CLEAR_ORDER_FAIL_MESSAGE,
            })
        }catch (e) {
        }
    }
}

export const clearCartAction = () => {
    return async(dispatch, getState) => {
        try {
            dispatch({
                type: TYPES.CLEAR_CART,
            })

            dispatch(clearSavedOrder())
            dispatch(clearCart())
            dispatch(closeQuickViewAction())

            dispatch({
                type: checkoutTypes.CLEAR_ORDER_FAIL_MESSAGE,
            })
        }catch (e) {
        }
    }
}

export const saveCart = (presentationMode = false) => {
    return async(dispatch, getState) => {
        const { cart: { cartItems }, customer: { activeCustomer } } = getState()
        if(activeCustomer && activeCustomer._id) {
            debouncedSaveCart(cartItems, activeCustomer._id, dispatch, presentationMode)
        }
    }
}

export const saveSample = (cartItems, activeCustomerId) => {
    return async(dispatch, getState) => {
        saveSampleCartFunction(cartItems, activeCustomerId, dispatch, false)
    }
}

export const clearCart = () => {
    return async(dispatch, getState) => {
        try {

            const { customer: { activeCustomer } } = getState()

            if(activeCustomer && activeCustomer._id) {
                await Api.cart.clearCart(activeCustomer._id)
            }
        }catch (e) {
        }
    }
}


