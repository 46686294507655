import { TYPES } from '../actions/seasonActions'

const initialState = {
    seasons: [],
    presentationSeasons: [],
};

const season = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.FETCH_SEASONS_SUCCESS: {
            return {
                ...state,
                seasons: action.payload
            }
        }
        case TYPES.FETCH_ACTIVE_SEASONS_SUCCESS: {
            return {
                ...state,
                seasons: action.payload
            }
        }
        case TYPES.FETCH_SEASONS_PRESENTATION_SUCCESS: {
            return {
                ...state,
                presentationSeasons: action.payload
            }
        }
        default:
            return state
    }
};


export default season
