import React from 'react'
import { useTranslation } from "react-i18next";

const CustomerAccessFilters = ({filters, handleFilterChange}) => {
    const { t } = useTranslation();
    return <ul className="customer-access-panel-filters">
        {Object.keys(filters).map((filterKey) => {
            return <li key={filterKey}>
                <span>{filters[filterKey].label}</span>

                {filters[filterKey].type === 'select'
                ?
                    <select name={filterKey} id={filterKey} onChange={(e) => handleFilterChange(filterKey, e.target.value)}>
                        <option value="0">{t('Select')}</option>
                        <option value="1">{t('Yes')}</option>
                        <option value="2">{t('No')}</option>
                    </select>
                :
                    <input type={filters[filterKey].type} value={filters[filterKey].value} onChange={(e) => handleFilterChange(filterKey, e.target.checked)}/>
                }

            </li>
        })}
    </ul>
}

export default CustomerAccessFilters
