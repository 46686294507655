import React from 'react'

import './SearchResultItem.scss'

const SearchResultItem = ({item: {titleEN, code, _id, image }, openQuickView}) => {
    return <li key={_id} className="search-result" onClick={(e) => openQuickView(_id)}>
        <img src={image} alt={code}/>
        <span className="code">{code}</span>
        <span className="title">{titleEN}</span>
    </li>
}

export default SearchResultItem
