import React, { useRef, useState, Fragment } from 'react';
import {ReactComponent as EditSvg} from '../assets/edit.svg';
import {ReactComponent as CloseSvg} from '../assets/close.svg';

import './EditField.scss'
import Button from "../Button";
import { useTranslation } from "react-i18next";

const EditField = ({editAccess, type, value, placeholder, submitAction}) => {
    const { t } = useTranslation();

    const [ edit, toggleEdit ] = useState(false)
    const inputRef = useRef('');

    return <div className="edit-field-container">
        <label>
            <span className="label">{t('User ' + type)}</span>
            {editAccess
                ?
                <span onClick={() => toggleEdit(!edit)}>{edit ? <CloseSvg/> : <EditSvg/>}</span>
                : null
            }
        </label>
        <span className="value">
            {edit ? <form className="edit-input-form" onSubmit={(e) => {
                e.preventDefault()
                submitAction(inputRef.current)
            }}>
                <input type={type === 'email' ? 'email' : 'text'} placeholder={placeholder} ref={inputRef}/>
                <button type="submit">{t('Save')}</button>
            </form> : ( value || 'No data' )}
        </span>
    </div>
}

export default EditField
