import React from 'react'
import PanelHeader from "../Panels/PanelHeader";
import {ReactComponent as DownloadSvg} from "../../components/assets/download.svg";

import './DownloadsPage.scss'

const DownloadsPage = ({ links }) => {
    return <div className="panel account-panel-components">
        <PanelHeader title="Downloads"/>
        <div className="panel-body">
            <ul className="downloads-list">
                {
                    links.map( ( link ) => {
                        return link.link ? <li>
                            <DownloadSvg/>
                            <a href={link.link}>
                                {link.text}
                            </a>
                        </li> : ''
                    } )
                }
            </ul>
        </div>
    </div>
}

export default DownloadsPage
