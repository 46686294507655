import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import PanelHeader from "../../Panels/PanelHeader";
import "react-datepicker/dist/react-datepicker.css";
import Api from '../../../controllers/Api'
import { withTranslation } from 'react-i18next';
//import DateRanges from "../../Common/Infos/DateRanges";
import {calculateDate} from '../../../utils/utils';
import Loader from "../../Common/Loader";

import './CustomerStatements.scss'
import './CustomerInfos.scss'

class CustomerStatements extends Component{

    constructor(props) {
        super(props);

        const { startDate, endDate } = calculateDate('current_year')

        this.state = {
            fromDate: startDate,
            toDate: endDate,
            requestId: false,
            pages: 0,
            activePage: 0,
            infoPage: '',
            showLoader: false,
            firstPageAutoFetched: false
        }
    }

    getInfosRequestId() {
        const { user: { _id } } = this.props
        const { fromDate, toDate } = this.state

        if(!fromDate || !toDate) {
            alert("Please select date ranges")
        }

        this.setState({
            showLoader: true,
            requestId: false,
            pages: 0,
            infoPage: '',
            firstPageAutoFetched: false
        })

        Api.customer.initInfosRequest(_id, fromDate, toDate).then(res => {
            if(res) {
                this.setState({
                    requestId: res.requestId,
                    pages: res.pages
                })
            }
        }).finally(() => {
            this.setState({
                showLoader: false
            })
        })
    }

    getInfoPage(page = 1) {
        const { user: { _id } } = this.props
        const { requestId } = this.state

        Api.customer.getInfosPage(_id, requestId, page).then(res => {
            if(res) {
                this.setState({
                    infoPage: res,
                    activePage: page
                })
            }
        })
    }

    printRequestControls() {
        const { fromDate, toDate, requestId, infoPage, pages, firstPageAutoFetched, activePage } = this.state

        if(!requestId) {
            return null
        }

        const pageNumbers = [];

        for(let i = 0; i < parseInt(pages); i++) {
            pageNumbers.push(i+1)
        }

        if(!firstPageAutoFetched) {
            this.getInfoPage(1)

            this.setState({
                firstPageAutoFetched: true
            })
        }

        return <div className="infos-print-pagination">
            {/*<h4>Result pages</h4>*/}
            <p>Please select one of the following pages</p>
            <ul>
                {pageNumbers.map(pageItem => <li className={pageItem === activePage ? 'active-page' : ''} key={pageItem} onClick={() => this.getInfoPage(pageItem)}>{pageItem}</li>)}
            </ul>
        </div>
    }

    render() {
        const { fromDate, toDate, requestId, infoPage, pages, showLoader } = this.state
        const { t } = this.props;

        if(showLoader) {
            return <Loader/>
        }

        return <div className="customer-statements">
            <PanelHeader title="Statements" />
            <div className="panel-body">
                <div className="panel-filters">
                    <div className="left-container">
                        {/*<div className="date-filters">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>From</label>*/}
                        {/*        <DatePicker*/}
                        {/*            dateFormat="dd/MM/yyyy"*/}
                        {/*            selected={fromDate}*/}
                        {/*            onChange={date => this.setState({ fromDate: date })}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>To</label>*/}
                        {/*        <DatePicker*/}
                        {/*            dateFormat="dd/MM/yyyy"*/}
                        {/*            selected={toDate}*/}
                        {/*            onChange={date => this.setState({ toDate: date })}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<DateRanges*/}
                        {/*    setFromDate={(date) => this.setState({ fromDate: date })}*/}
                        {/*    setToDate={(date) => this.setState({ toDate: date })}*/}
                        {/*/>*/}
                    </div>
                    <div className="right-container">
                        <div className="form-group">
                            <button onClick={() => this.getInfosRequestId()} className="vamp-button">{t('Request')}</button>
                        </div>
                        {this.printRequestControls()}
                    </div>
                </div>
                {infoPage ?
                    <div className="panel-content">
                        <div dangerouslySetInnerHTML={{__html: infoPage}} />
                    </div>
                    : null}
            </div>
        </div>
    }
}

export default withTranslation()(CustomerStatements)