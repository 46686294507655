export const getCustomerPoints = (customer) => {
    let points = 0

    if(customer && customer.customer && customer.customer.length) {
        points = customer.customer[0].points
    }

    return points
}

export const getCustomerPointsOptions = (orderTotal, points) => {

    let availableOptions = []

    if(points >= 10000) {
        availableOptions.push({
            value: 10000,
            cost: 10,
            label: '10€ ( Redeem 10000 points )'
        })
    }
    if(points >= 20000) {
        availableOptions.push({
            value: 20000,
            cost: 20,
            label: '20€ ( Redeem 20000 points )'
        })
    }
    if(points >= 50000) {
        availableOptions.push({
            value: 50000,
            cost: 50,
            label: '50€ ( Redeem 50000 points )'
        })
    }

    // availableOptions = availableOptions.filter(point => {
    //     return point.cost <= orderTotal
    // })

    return availableOptions
}

export const getCurrencyByCode = (currencyNumber) => {
    let currencyString = '€'

    currencyNumber = parseInt(currencyNumber)

    switch (currencyNumber) {
        case 100: {
            currencyString = '€';
            break;
        }
        case 287: {
            currencyString = 'CZK';
            break;
        }
        case 249: {
            currencyString = 'PLN';
            break;
        }
        case 113: {
            currencyString = 'USD';
            break;
        }
        default: {
            currencyString = '€';
            break;
        }
    }

    return currencyString
}
