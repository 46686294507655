import React from 'react'
import {Link} from 'react-router-dom'
import './AccountSidebarItem.scss'

const AccountSidebarItem = ({title, link, handleClick, icon}) => {
    return <li className="account-sidebar-item" onClick={handleClick}>
        {link ?
            <Link to={link}>
                {icon ? icon : null}
                <span>{title}</span>
            </Link>
            : <div>{icon ? icon : null}<span>{title}</span></div>}
    </li>
}

export default AccountSidebarItem
