import {TYPES} from '../actions/noticeActions'

const initialState = {
    notices: [],
};

const notice = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SUCCESS_MESSAGE: {

            let newNotices = state.notices

            if(action.payload) {
                newNotices.push(action.payload)
            }

            return {
                ...state,
                notices: newNotices,
            }
        }
        case TYPES.CLEAR_NOTICES: {
            return {
                notices: []
            }
        }
        default:
            return state
    }
};


export default notice
