import React, {Component} from 'react'
import CartGridHeader from "./CartGridHeader";
import CartPageControls from "./CartPageControls";
import PostersModal from "./PostersModal";
import CartGridRow from "./CartGridRow";
import Loader from "../Common/Loader";

import {hasPreorderProduct} from '../../utils/cartUtils'
import {extractProductFilters} from "../../utils/utils";
import { withTranslation } from 'react-i18next';

import {ReactComponent as CloseSvg} from '../assets/close.svg';

import './CartTable.scss'

const CartSearch = ({searchTerm, handleInputChange, foundCount, totalCartItemsCount}) => {
    return <div className="cart-search-container">
        <span className="label">Search in cart:</span>
        <input value={searchTerm} onChange={(e) => handleInputChange(e.target.value)} type="text"
               placeholder="search in cart..."/>
        <span className="clear-search" onClick={() => handleInputChange('')}><CloseSvg/></span>
        {(searchTerm && searchTerm.length >= 3) ?
            <span className="found-results">{`Found ${foundCount} of ${totalCartItemsCount}`}</span> : null}
    </div>
}

class CartTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showPostersModal: false,
            cartSearchTerm: '',
            filters: {},
            activeFilters: {}
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.removeFromCart = this.removeFromCart.bind(this)
    }

    calculateCartSizes() {
        const {cartItems} = this.props;

        let availableCartSizes = []
        for (let i = 0; i < cartItems.length; i++) {
            let cartItem = cartItems[i]

            if (!cartItem.variations || !cartItem.variations.length) {
                continue;
            }

            for (let j = 0; j < cartItem.variations.length; j++) {
                if (!availableCartSizes.includes(cartItem.variations[j].sizeCode)) {
                    availableCartSizes.push(cartItem.variations[j].sizeCode)
                }
            }
        }

        return availableCartSizes
    }

    printCartRows(cartItems, failedOrderItems) {
        const {openQuickView, currency} = this.props;
        return cartItems.map((cartItem, key) => {

            return <CartGridRow
                failedOrderItems={failedOrderItems}
                key={key}
                currency={currency}
                item={cartItem}
                handleInputChange={this.handleInputChange}
                removeFromCart={this.removeFromCart}
                openQuickView={openQuickView}
            />
        })
    }

    handleInputChange(productId, barcode, qty, presentationMode = false) {
        const {updateCartAction} = this.props

        if (!productId || !barcode) {
            return
        }

        updateCartAction([{
            productId, barcode, qty
        }], presentationMode)
    }

    removeFromCart(barcodes, presentationMode = false) {
        if (!barcodes) {
            return
        }

        const {removeFromCartAction} = this.props
        removeFromCartAction(barcodes, presentationMode)
    }

    handleCartSearch(cartItems, cartSearchTerm, filters, activeFilters) {
        let foundCount = 0
        const totalCartItemsCount = cartItems.length

        let cartItemsFiltered = cartItems.map(item => {
            item.foundInSearch = false;

            if (cartSearchTerm && cartSearchTerm.length >= 3) {
                if (item.code.toLowerCase().includes(cartSearchTerm) ||
                    item.bsample.toLowerCase().includes(cartSearchTerm) ||
                    item.titleEN.toLowerCase().includes(cartSearchTerm)) {

                    foundCount++
                    item.foundInSearch = true
                }
            }
            //Check for filters
            //Check if product applies to filters
            const activeFiltersKeys = Object.keys(activeFilters);

            if (activeFiltersKeys && activeFiltersKeys.length) {

                for (let i = 0; i < activeFiltersKeys.length; i++) {
                    item.foundInSearch = false;

                    if (
                        item[activeFiltersKeys[i]] &&
                        activeFilters[activeFiltersKeys[i]].has(item[activeFiltersKeys[i]])
                    ) {
                        item.foundInSearch = true
                        break;
                    }
                }
            }

            return item
        }).sort((item) => item.foundInSearch ? -1 : 1)

        if (!foundCount) {
            cartItemsFiltered.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0));
        }

        return {
            foundCount,
            totalCartItemsCount,
            cartItemsFiltered,
        }
    }

    clearFilters() {
        let newFilters = Object.assign({}, this.state.filters)

        Object.keys(newFilters).filter((key) => {
            newFilters[key].active = []
        })

        this.setState({
            filters: newFilters
        })
    }

    toggleFilter(key, value) {
        let newFilters = Object.assign({}, this.state.activeFilters)

        if (!newFilters[key]) {
            newFilters[key] = new Set()
        }

        if (newFilters[key].has(value)) {
            newFilters[key].delete(value)
        } else {
            newFilters[key].add(value)
        }

        this.setState({
            activeFilters: newFilters
        })
    }

    componentDidMount() {
        this.setState({
            filters: extractProductFilters(this.props.cartItems)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cartItems.length !== this.props.cartItems.length) {
            this.setState({
                filters: extractProductFilters(this.props.cartItems)
            })
        }
    }

    render() {
        const {
            cartItems,
            clearCartAction,
            handleCheckoutRedirect,
            updateCartAction,
            saveOrderAction,
            removeFromCartAction,
            fetchingCartProducts,
            failedOrderItems,
        } = this.props;
        const { t } = this.props;

        const {
            showPostersModal,
            cartSearchTerm,
            filters,
            activeFilters,
        } = this.state;

        const cartSizes = this.calculateCartSizes();
        const hasPreorders = hasPreorderProduct(cartItems)

        if (fetchingCartProducts) {
            return <div className="cart-loader-container"><Loader/></div>
        }

        if (!cartItems.length) {
            return <div className="cart-empty-msg">{t('You have no items in your shopping cart')}</div>
        }

        const {foundCount, totalCartItemsCount, cartItemsFiltered} = this.handleCartSearch(cartItems, cartSearchTerm, filters, activeFilters)

        return <div className="cart-table-container">
            <CartSearch
                foundCount={foundCount}
                totalCartItemsCount={totalCartItemsCount}
                searchTerm={cartSearchTerm}
                handleInputChange={(value) => {
                    this.setState({
                        cartSearchTerm: value
                    })
                }}/>
            <table className="cart-table">
                <tbody>
                <CartGridHeader sizes={cartSizes}/>
                {this.printCartRows(cartItemsFiltered, failedOrderItems)}
                </tbody>
            </table>
            <CartPageControls
                hasPreorders={hasPreorders}
                filters={filters}
                activeFilters={activeFilters}
                clearFilters={() => this.clearFilters()}
                toggleFilter={(key, value) => this.toggleFilter(key, value)}
                saveOrderAction={() => {
                    let saveOrderConfirm = window.confirm('Are you sure about saving this order for later use?')
                    if (saveOrderConfirm) {
                        saveOrderAction()
                    }
                }
                }
                openPostersAction={() => {
                    this.setState({
                        showPostersModal: !showPostersModal
                    })
                }}
                clearCartAction={() => {
                    let deleteConfirmed = window.confirm('Are you sure about deleting active cart?')
                    if (deleteConfirmed) {
                        clearCartAction()
                    }
                }}
                handleCheckoutRedirect={handleCheckoutRedirect}
            />
            {hasPreorders ?
                <PostersModal
                    updateCartAction={updateCartAction}
                    removeFromCartAction={removeFromCartAction}
                    show={showPostersModal}
                    cartItems={cartItems}
                    closeModal={() => {
                        this.setState({
                            showPostersModal: false
                        })

                        this.props.refetchCartProducts()
                    }}/>
                : null}
        </div>
    }
}

export default withTranslation()(CartTable)
