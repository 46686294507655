import React, { useState, useEffect } from 'react'
import InfoTable from "../../Common/InfoTable";

import './CustomersBalancesPage.scss'
import PageHeader from "../PageHeader";
import PanelHeader from "../../Panels/PanelHeader";
import {getCurrencySymbolPerCurrency, normalizePrice} from "../../../utils/priceUtils";
import Loader from "../../Common/Loader";

const CustomersBalancesPage = ({ fetchCustomersBalances, balances }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'altCode',
                Footer: ({data}) => {
                    return <strong>Totals</strong>
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'City',
                accessor: 'city',
            },
            {
                Header: 'Balance',
                accessor: 'balance',
                skipFilter: true,
                Cell: props => {
                    let value = props.cell.row.original.balance
                    value = normalizePrice(value)
                    const currency = getCurrencySymbolPerCurrency(props.cell.row.original.currency)

                    return <span>{`${currency} ${value}`}</span>
                },
                Footer: ({data}) => {

                    if (!data || !data.length) {
                        return null
                    }

                    const currency = getCurrencySymbolPerCurrency(data[0].currency)
                    let total = data.reduce((total, {balance}) => total += parseFloat(balance), 0).toFixed(2)
                    total = normalizePrice(total)

                    return <strong>{`${currency}${total}`}</strong>
                }
            },
            {
                Header: 'Overdue',
                accessor: 'overdue',
                skipFilter: true,
                Cell: props => {
                    let value = props.cell.row.original.overdue
                    value = normalizePrice(value)
                    const currency = getCurrencySymbolPerCurrency(props.cell.row.original.currency)

                    return <span>{`${currency} ${value}`}</span>
                },
                Footer: ({data}) => {

                    if (!data || !data.length) {
                        return null
                    }

                    const currency = getCurrencySymbolPerCurrency(data[0].currency)
                    let total = data.reduce((total, {overdue}) => total += parseFloat(overdue), 0).toFixed(2)
                    total = normalizePrice(total)

                    return <strong>{`${currency}${total}`}</strong>
                }
            },
            {
                Header: 'Overdue Days',
                accessor: 'overdueDays',
                skipFilter: true,
            },
        ],
        []
    )


    // //Get customer balances
    useEffect(() => {
        fetchCustomersBalances()
    }, [])

    if(!balances.length) {
        return <Loader/>
    }

    return <div className="customers-balances-page">
        <div className="table-container">
            <PanelHeader title="Customers Balances" />
            <div className="panel-body">
                <InfoTable columns={columns} data={balances} />
            </div>
        </div>
    </div>
}

export default CustomersBalancesPage
