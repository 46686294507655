import React, {useState} from 'react'
import Button from "../Button";

import {ReactComponent as OrderSvg} from '../assets/order.svg';
import {ReactComponent as DeleveSvg} from '../assets/bin.svg';
import {ReactComponent as CheckoutSvg} from '../assets/checkout.svg';
import {ReactComponent as AdvertisingSvg} from '../assets/posters.svg';
import {ReactComponent as SaveSvg} from '../assets/save.svg';
import { useTranslation } from "react-i18next";

import './CartPageControls.scss'
import FiltersComponent from "../Product/FiltersComponent";

const CartPageControls = ({
                              clearCartAction,
                              handleCheckoutRedirect,
                              openPostersAction,
                              saveOrderAction,
                              filters,
                              clearFilters,
                              hasPreorders,
                              toggleFilter,
                              activeFilters,
                          }) => {
    const { t } = useTranslation();

    const [showFilters, setShowFilters] = useState(false)

    return <div className="cart-page-controls">
        <div className="cart-page-controls-container">
            <div className="left-controls">
                <div className="action-element">
                    <FiltersComponent
                        toggleFiltersShow={() => {
                            setShowFilters(!showFilters)
                        }}
                        showFilters={showFilters}
                        clearFilters={clearFilters}
                        toggleFilter={toggleFilter}
                        filters={filters}
                        activeFilters={activeFilters}
                    />
                </div>
                <div className="action-element" onClick={saveOrderAction}>
                    <SaveSvg/>
                </div>
                {hasPreorders ?
                    <div className="action-element" onClick={openPostersAction}>
                        <AdvertisingSvg/>
                    </div>
                : null}
                <div className="action-element" onClick={clearCartAction}>
                    <DeleveSvg/>
                </div>
            </div>
            <div className="right-controls">
                <Button callback={handleCheckoutRedirect} className='action-element checkout-action-element'>
                    <span>{t('Checkout')}</span>
                    <CheckoutSvg/>
                </Button>
            </div>
        </div>
    </div>
}

export default CartPageControls
