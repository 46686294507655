import React from 'react'
import {ReactComponent as EditSvg} from '../assets/edit.svg';

import './AddressListItem.scss'

const AddressListItem = ({address, editAddress, setDefaultAddress}) => {
    return <li className="address-list-item">
        <div className="address-label">
            {`${address.address}, ${address.city}, ${address.zip}, ${address.country}`}
        </div>
        <div className="address-controls">
            <span className="address-control" onClick={() => {
                if(address.defaultAddress) {
                    return;
                }
                setDefaultAddress(address._id)
            }}>
                {address.defaultAddress ? 'Default Address' : 'Set Address as default'}
            </span>
            <span className="address-control" onClick={() => editAddress(address._id)}>
                <EditSvg />
            </span>
        </div>
    </li>
}

export default AddressListItem
