import React, {useState} from 'react'
import PanelHeader from "../../Panels/PanelHeader";
import "react-datepicker/dist/react-datepicker.css";

import Api from '../../../controllers/Api'
import InfoTable from "../../Common/InfoTable";
import Loader from "../../Common/Loader";
import PdfLinkButton from "../PdfLinkButton";
import './CustomerStatements.scss'
import './CustomerInfos.scss'
import { useTranslation } from "react-i18next";

const CustomerShipments = ({ user }) => {
    const [shipmentsData, setShipmentsData] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const { t } = useTranslation();

    const columns = React.useMemo(
        () => [
            {
                Header: 'Order Number',
                accessor: 'fincode',
            },
            {
                Header: 'Order Type',
                accessor: 'ordertype',
            },
            {
                Header: 'Season',
                accessor: 'season',
            },
            {
                Header: 'Order Date',
                accessor: 'trndate',
            },
            {
                Header: 'Total Quantity',
                accessor: 'OrderTotalQty',
            },
            {
                Header: 'Sent Quantity',
                accessor: 'OrderCloseQty',
            },
            {
                Header: 'Open Quantity',
                accessor: 'OrderOpenQty',
            },
            {
                Header: 'Pdf',
                accessor: 'pdfUrl',
                skipFilter: true,
                Cell: props => {
                    const {company, findoc} = props.cell.row.original

                    return <PdfLinkButton
                        company={company}
                        fincdoc={findoc}
                    />
                },
            },
        ],
        []
    )

    if(showLoader) {
        return <Loader/>
    }

    return <div className="infos-panel customer-shipments">
        <PanelHeader title="Shipments"/>
        <div className="panel-body">
            <div className="panel-filters">
                <div className="form-group">
                    <button onClick={() => {

                        setShipmentsData([])
                        setShowLoader(true)

                        Api.customer.getShipments(user._id).then((data) => {
                            if(data) {
                                setShipmentsData(data)
                            }

                            if(!data || !data.length) {
                                alert('No data found')
                            }
                        }).finally(() => {
                            setShowLoader(false)
                        })
                    }} className="vamp-button">{t('View')}
                    </button>
                </div>
            </div>
            {shipmentsData && shipmentsData.length ?
                <div className="panel-content">
                    <div className="shipments-table">
                        <InfoTable columns={columns} data={shipmentsData} />
                    </div>
                </div>
                : null}
        </div>
    </div>
}

export default CustomerShipments