import {TYPES} from '../actions/wishlistActions'

const initialState = {
    items: {
        products: [],
        families: []
    }
};

const settings = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.CLEARED_WISHLIST: {
            return {
                ...state,
                ...initialState
            }
        }
        case TYPES.REMOVE_ITEM_FROM_WISHLIST: {
            const { itemId, type } = action.payload

            let products = state.items.products.slice()
            let families = state.items.families.slice()

            switch (type) {
                case 'product': {
                    products = products.filter(productId => productId !== itemId)
                    break
                }
                case 'family': {
                    families = families.filter(productId => productId !== itemId)
                    break
                }
            }

            return {
                ...state,
                items: {
                    products,
                    families
                },
            }
        }
        case TYPES.TOOGLE_WISHLIST_ITEM: {

            const { itemId, type } = action.payload

            let products = state.items.products.slice()
            let families = state.items.families.slice()


            switch (type) {
                case 'product': {
                    if(!products.includes(action.payload.itemId)) {
                        products.push(action.payload.itemId)
                    }else {
                        products = products.filter(productId => productId !== action.payload.itemId)
                    }

                    break
                }
                case 'family': {
                    if(!families.includes(action.payload.itemId)) {
                        families.push(action.payload.itemId)
                    }else {
                        families = families.filter(productId => productId !== action.payload.itemId)
                    }

                    break
                }
            }

            return {
                ...state,
                items: {
                    products,
                    families
                },
            }
        }
        case TYPES.FETCHED_WISHLIST_ITEMS: {
            return {
                ...state,
                items: action.payload,
            }
        }
        case TYPES.ADD_TO_WISHLIST: {
            let items = state.items.slice()

            if(!items.includes(action.payload)) {
                items.push(action.payload)
            }

            return {
                ...state,
                items,
            }
        }
        case TYPES.REMOVE_ITEM_FROM_WISHLIST: {
            let items = state.items.slice()

            if(items.includes(action.payload)) {
                items = items.filter(productId => productId !== action.payload)
            }

            return {
                ...state,
                items,
            }
        }
        case TYPES.CLEARED_WISHLIST: {
            return {
                ...state,
                items: action.payload,
            }
        }
        default:
            return state
    }
};


export default settings
