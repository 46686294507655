import React from 'react'

import './CartMessage.scss'

const CartMessage = ({message}) => {

    if(!message) {
        return null
    }

    return <aside className="cart-message">
        <h4>{message}</h4>
    </aside>

}

export default CartMessage