import React, {Component, useState, useEffect} from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import {ReactComponent as AngleThinSvg} from '../assets/angleThin.svg';

import './ProductImages.scss'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import {cloudflareImage} from "../../utils/utils";

const ProductImages = ({images, infinite, slidesPerPage, videos, responsive, selectedColor, imagesWithDetail}) => {

    //Try to detect user agent
    // const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    /*let imageItems = images.slice().map(image => {
        return {
            url: image,
            type: 'image'
        }
    })*/
    let imageItems = images
    const mp4Videos = []
    const webmVideos = []

    videos.filter(videoUrl => {
        const videoObj = {
            url: videoUrl,
            type: "video"
        }

        if (videoUrl.endsWith('.mp4')) {
            videoObj.type = "video/mp4"
            mp4Videos.push(videoObj)
        } else {
            videoObj.type = "video/webm"
            webmVideos.push(videoObj)
        }
    })

    let finalVideosArrays = []
    finalVideosArrays = webmVideos.concat(mp4Videos)

    if (finalVideosArrays.length) {
        imageItems.splice(imageItems.length, 0, {
            type: "video",
            sources: [
                ...finalVideosArrays
            ]
        })
    }

    const imagesContent = imageItems.map((item, index) =>{
        return (item.visible === true || item.type === 'video' ? <div className="carousel-item" key={item.url || 'video'}>
            {item.type === 'video'
                ?
                <div data-fancybox="gallery" href={item.sources[0].url}>
                    <video controls={true}>
                        {item.sources.map(source => <source key={source.url} src={source.url} type={source.type}/>)}
                    </video>
                </div>
                :
                <div data-fancybox="gallery" href={item.url}><img src={ !responsive ? cloudflareImage(item.url, 310) : item.url }/></div>
            }
        </div> : '')
    } )

    return <div className="product-images-container">
        <OwlCarousel
            className="owl-theme"
            margin={10}
            nav
            items={1}
            responsive={responsive || {}}
            key={Date.now()}
        >
            {imagesContent}
        </OwlCarousel>
        {/*<Carousel*/}
        {/*    arrowLeft={<div><AngleThinSvg/></div>}*/}
        {/*    arrowRight={<div><AngleThinSvg/></div>}*/}
        {/*    addArrowClickHandler*/}
        {/*    dots*/}
        {/*    slidesPerPage={slidesPerPage || 1}*/}
        {/*    infinite={false}*/}
        {/*    breakpoints={responsive || {}}*/}
        {/*>*/}
        {/*    {imageItems.map((item, index) => <div className="carousel-item" key={item.url || 'video'}>*/}
        {/*        {item.type === 'video'*/}
        {/*        ?*/}
        {/*            <a data-fancybox="gallery" href={item.sources[0].url}>*/}
        {/*                <video controls={true}>*/}
        {/*                    {item.sources.map(source => <source key={source.url} src={source.url} type={source.type}></source>)}*/}
        {/*                </video>*/}
        {/*            </a>*/}
        {/*        :*/}
        {/*            <a data-fancybox="gallery" href={item.url}><img src={item.url}/></a>*/}
        {/*        }*/}
        {/*    </div>)}*/}
        {/*</Carousel>*/}
    </div>
}

export default ProductImages
