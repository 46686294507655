import Api from "../controllers/Api";
import {getHomepageSettings, getSettings, getHeaderSettings} from "./settingsActions";
import {getPresentationSeasons, getSeasons} from "./seasonActions";
import { checkIfCanPreorder } from "./authActions";

export const TYPES = {
    BOOTSTRAP_FINISHED: 'BOOTSTRAP_FINISHED',
}

export const bootstrapAction = () => {
    return async (dispatch, getState) => {
        try {
            const { auth: { activeUser: { id } } } = getState()
            dispatch(checkIfCanPreorder(id))

            dispatch(getHomepageSettings())
            dispatch(getSettings())
            dispatch(getHeaderSettings())
            dispatch(getSeasons())
            dispatch(getPresentationSeasons())
        }catch (error) {
            console.log(error)
        }
    }
}
