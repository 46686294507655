import Api from "../controllers/Api";

export const TYPES = {
    FETCH_FAMILIES_SUCCESS: 'FETCH_FAMILIES_SUCCESS',
}

export const getFamilies = () => {
    return async (dispatch, getState) => {
        const families = await Api.family.get()

        dispatch({
            type: TYPES.FETCH_FAMILIES_SUCCESS,
            payload: families
        })
    }
}
