export const TYPES = {
    SUCCESS_MESSAGE: 'SUCCESS_MESSAGE',
    CLEAR_NOTICES: 'CLEAR_NOTICES',
}

export const addNotice = (message) => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.SUCCESS_MESSAGE,
            payload: message
        })
    }
}

export const clearNotices = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.CLEAR_NOTICES,
        })
    }
}
