import React, {Component} from 'react'
import {connect} from 'react-redux'

import Button from '../Button'
import CartHeader from './CartHeader'
import CartTable from './CartTable'
import CartMessage from "./CartMessage";

import {openQuickViewAction} from '../../actions/quickViewActions'
import {clearCartAction, updateCartAction, removeFromCartAction} from '../../actions/cartActions'
import {saveOrderAction} from '../../actions/checkoutActions'
import {normalizeCartItemsByColor, calculateTotalPrice, getCountByCollection} from '../../utils/cartUtils'
import {clearSavedOrder} from '../../actions/orderActions'
import {addLivePricesToProduct, getCurrencySymbol, getLivePrices} from "../../utils/priceUtils";
import { getCustomerPoints } from '../../utils/customerUtils'
import { withTranslation } from 'react-i18next';

import Api from '../../controllers/Api'

import {ReactComponent as BackSvg} from "../assets/back.svg";
import './Cart.scss'

class Cart extends Component {
    constructor(props) {
        super(props)

        this.cartItems = []
        this.cartHeaderElement = null

        this.state = {
            fixedHeader: false,
            fetchingCartProducts: false,
            cartProducts: []
        }

        window.onscroll = () => {
            this.handleStickyTopBar()
        }
    }

    fetchCartProducts(cartItems, presentationMode = false) {
        const { customer: { activeCustomer } } = this.props
        const productIds = cartItems.map(cartItem => cartItem.productId)

        this.setState({
            fetchingCartProducts: true
        })

        Api.product.getMultiple(productIds, 'id', presentationMode).then(products => {
            this.setState({
                cartProducts: products,
                fetchingCartProducts: false,
            })
        }).then(() => {
            //Fetch live prices
            getLivePrices(this.state.cartProducts, activeCustomer, false).then((livePrices) => {
                if(livePrices && livePrices.length) {
                    const productsWithLivePrices = this.state.cartProducts.map(product => {
                        return addLivePricesToProduct(product, livePrices)
                    })

                    this.setState({
                        cartProducts: productsWithLivePrices
                    })
                }
            })
        }).catch(err => {
            //Clear the cart
            this.props.clearCartAction()
        })
    }

    componentDidMount() {
        const { cart: {cartItems, presentationMode} } = this.props
        this.handleStickyTopBar()

        Api.auth.getSelectedCustomer()

        if(cartItems && cartItems.length) {
            this.fetchCartProducts(cartItems, presentationMode)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const cart = this.props.cart
        const oldCart = prevProps.cart

        if(
            cart &&
            oldCart
        ){
            if(
                cart.cartItems.length !== oldCart.cartItems.length
            ) {
                this.fetchCartProducts(cart.cartItems, cart.presentationMode)
            }
        }
    }

    componentWillUnmount() {
        window.onscroll = null
    }

    handleStickyTopBar() {
        let elementVisible = this.isInViewport();

        if (!elementVisible) {
            this.setState({fixedHeader: false})
        } else {
            this.setState({fixedHeader: true})
        }
    }

    isInViewport(offset = 0) {
        if (!this.cartHeaderElement) return false;
        const top = this.cartHeaderElement.getBoundingClientRect().top;
        return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
    }

    groupCartItemsByColor(cartItemProduct) {
        let productsByColors = [];

        for (let i = 0; i < cartItemProduct.length; i++) {
            let cartItem = Object.assign({}, cartItemProduct[i]);
            let productVariations = cartItem.variations

            for (let j = 0; j < productVariations.length; j++) {
                let variation = Object.assign({}, productVariations[j])
                let productInArray = false

                for (let k = 0; k < productsByColors.length; k++) {
                    let productByColor = productsByColors[k]

                    if (variation.colorId === productByColor.colorId && variation.colorCode === productByColor.colorCode) {
                        productsByColors[k].sizes.push(variation)
                        productInArray = true;
                        break
                    }
                }

                if (!productInArray) {
                    let newCartItem = Object.assign({}, cartItem)

                    newCartItem.sizes = []
                    newCartItem.sizes.push(variation)
                    newCartItem.colorId = variation.colorId
                    newCartItem.colorCode = variation.colorCode
                    newCartItem.colorName = variation.colorName
                    newCartItem.colorHex = variation.colorHex
                    productsByColors.push(newCartItem)
                }
            }
        }

        return productsByColors;
    }

    showCartMessages() {
        const {
            order: {activeSavedOrderId},
            clearSavedOrder,
            clearCartAction,
            saveOrderAction,
            history
        } = this.props

        if (activeSavedOrderId) {
            return <div className="edit-order-message">
                <p>Editing Order <span>{activeSavedOrderId}</span></p>
                <Button title="Cancel Order Edit" callback={() => {
                    const saveOrderBeforeCancel = window.confirm("Do you want to save your changes before leaving the order edit?")

                    if (saveOrderBeforeCancel) {
                        saveOrderAction().then(res => {
                            history.push('/app/account/orders')
                        })
                    }
                }}/>
            </div>
        }
    }

    render() {
        const { t } = this.props;
        const {
            product: {variationPrices},
            cart: {cartCount, cartItems},
            customer: {activeCustomer},
            openQuickViewAction,
            removeFromCartAction,
            updateCartAction,
            clearCartAction,
            history,
            saveOrderAction,
            failedOrderItems
        } = this.props

        const {
            fixedHeader,
            cartProducts,
            fetchingCartProducts,
        } = this.state

        const currency = getCurrencySymbol(activeCustomer);
        const cartItemsByColor = normalizeCartItemsByColor(cartProducts, cartItems, variationPrices)
        const cartTotalPrice = calculateTotalPrice(cartItemsByColor, currency)

        const { underwearCount, collectionsCount, hasPreorders } = getCountByCollection(cartItemsByColor)

        let customerPoints = getCustomerPoints(activeCustomer)

        return <div className="cart-container">
            <CartMessage message={this.showCartMessages()}/>
            <div
                className={`cart-header-outer-wrapper ${!fixedHeader ? 'cart-header-sticky' : ''}`}
                ref={(e) => this.cartHeaderElement = e}
            >
                <CartHeader
                    points={customerPoints}
                    openQuickView={openQuickViewAction}
                    cartCount={cartCount}
                    cartTotalPrice={cartTotalPrice}
                    underwearCount={underwearCount}
                    hasPreorders={hasPreorders}
                    collectionsCount={collectionsCount}
                    history={history}
                />
                <div className="cart-header-bottom">
                    <button className="back-button" onClick={() => history.goBack()}><BackSvg/><span>{t('Back')}</span>
                    </button>
                </div>
            </div>
            <CartTable
                failedOrderItems={failedOrderItems}
                refetchCartProducts={() => this.fetchCartProducts(cartItems)}
                currency={currency}
                cartItems={cartItemsByColor}
                clearCartAction={clearCartAction}
                updateCartAction={updateCartAction}
                removeFromCartAction={removeFromCartAction}
                saveOrderAction={saveOrderAction}
                openQuickView={openQuickViewAction}
                history={history}
                fetchingCartProducts={fetchingCartProducts}
                handleCheckoutRedirect={() => {
                    if (!activeCustomer) {
                        alert('Please select a customer first')
                    } else {
                        history.push('/app/checkout')
                    }
                }}
            />
        </div>
    }
}

const mapStateToProps = ({product, cart, customer, order}) => {
    return {
        product,
        cart,
        customer,
        order
    }
}

export default withTranslation()(connect(mapStateToProps, {
    clearSavedOrder,
    openQuickViewAction,
    clearCartAction,
    updateCartAction,
    removeFromCartAction,
    saveOrderAction,
})(Cart))
