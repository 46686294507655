import {TYPES} from '../actions/settingsActions'

const initialState = {
    headerSettings: {
        header_banner_image: false,
        header_banner_url: false,
        header_banner_image_cz: false,
        header_banner_url_cz: false,
    }
};

const headerSettings = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.FETCHED_HEADER_SETTINGS: {
            return {
                ...state,
                headerSettings: action.payload,
            }
        }
        default:
            return state
    }
};


export default headerSettings
