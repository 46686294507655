import React from 'react'
import Button from "../Button";
import { ReactComponent as BinSvg } from '../assets/bin.svg';
import { useTranslation } from "react-i18next";

import './MultiGridControls.scss'

const MultiGridControls = ({addToCart, resetGridAction, editMode}) => {
    const { t } = useTranslation();
    return <div className="multigrid-controls">
        <Button className="reset-grid-button" title="Reset" callback={resetGridAction}>
            <BinSvg/>
        </Button>
        <Button className="add-to-cart-button vamp-button-blue" title={editMode ? t('Update Order') : t('Add to Order')} callback={addToCart}/>
    </div>
}

export default MultiGridControls
