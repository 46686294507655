import React, {Component} from 'react'
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Link} from "react-router-dom";

import {getCustomersAction} from '../../actions/customerActions'
import {loadOrderAction, deleteOrderAction, clearSavedOrder} from '../../actions/orderActions'

import AccountSidebar from '../AccountSidebar/AccountSidebar'
import AccountSidebarItem from '../AccountSidebar/AccountSidebarItem'
import AccountPanel from "../Panels/AccountPanel";
import CustomerAccessPage from "../Customer/CustomerAccessPage"
import CustomersBalancesPage from "./Customer/CustomersBalancesPage"
import UserOrdersPanel from "../Panels/UserOrdersPanel";
import CustomersPanel from "../Panels/CustomersPanel";
import CustomersCreateForm from "../Forms/CustomersCreateForm";
import SalesmanSeasonsCompare from '../Salesman/Infos/SalesmanSeasonsCompare'
import SalesmanTurnovers from '../Salesman/Infos/SalesmanTurnovers'

import {ReactComponent as SalesmanOrdersSvg} from '../assets/salesman/salesmanOrderStatistics.svg';
import {ReactComponent as SalesmanTurnoversSvg} from '../assets/salesman/salesmanTurnovers.svg';
import {ReactComponent as BackSvg} from '../assets/back_flip.svg';
import {ReactComponent as CustomerBalancesSvg} from '../assets/invoices_new.svg';
import { withTranslation } from 'react-i18next';

import './AccountPage.scss'
import Api from "../../controllers/Api";

class SalesmanInformationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customersBalances: [],
            fetchingBalances: false,
        }
    }

    componentDidMount() {
    }

    fetchCustomersBalances() {
        const {
            auth: {activeUser},
        } = this.props
        const {fetchingBalances, customersBalances} = this.state

        if (!customersBalances.length && !fetchingBalances) {

            this.setState({
                fetchingBalances: true
            })

            Api.customer.getBalances(activeUser.id).then(data => {
                this.setState({
                    customersBalances: data,
                    fetchingBalances: false
                })
            })
        }
    }

    render() {
        const { t } = this.props;
        const {
            auth: {activeUser},
            customer: {customers},
            loadOrderAction,
            history
        } = this.props

        return <div className={`account-page salesman-information-page role-${activeUser.role}`}>
            <div className="account-sidebar">
                <AccountSidebar>
                    <AccountSidebarItem
                        title={t("Back to my Account")}
                        link="/app/account"
                        icon={<BackSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Customers Balances")}
                        link="/app/salesman/information/balances"
                        icon={<CustomerBalancesSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Compare Seasons")}
                        link="/app/salesman/information/seasons-compare"
                        icon={<SalesmanOrdersSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Turnovers")}
                        link="/app/salesman/information/turnovers"
                        icon={<SalesmanTurnoversSvg/>}
                    />
                </AccountSidebar>
            </div>
            <div className="account-panel">
                <Route path="/app/salesman/information/balances" exact component={(props) => <CustomersBalancesPage
                    {...props}
                    balances={this.state.customersBalances}
                    fetchCustomersBalances={() => this.fetchCustomersBalances()}/>
                }/>
                <Route path="/app/salesman/information/seasons-compare" exact
                       component={(props) => <SalesmanSeasonsCompare {...props} />}/>
                <Route path="/app/salesman/information/turnovers" exact
                       component={(props) => <SalesmanTurnovers {...props} />}/>
            </div>
        </div>
    }
}

const mapStateToProps = ({auth, customer}) => {
    return {
        auth,
        customer
    }
}

export default withTranslation()(connect(mapStateToProps, {
    getCustomersAction,
    loadOrderAction,
    deleteOrderAction,
    clearSavedOrder,
})(SalesmanInformationPage))

