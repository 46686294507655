import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Formik, Field, Form} from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import { updateAddressAction, createAddressAction } from '../../actions/customerActions'

import FormSection from "./FormSection";
import FormElement from "./FormElement";

import './AddressForm.scss'
import FullScreenLoader from "../Common/FullScreenLoader";

class AddressForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitProcess: false
        }
    }

    render() {
        const { t } = this.props;
        const {
            customer: {countries, activeCustomer},
            auth: {activeUser},
            address,
            closeForm,
            id,
            updateAddressAction,
            createAddressAction,
        } = this.props

        const { submitProcess } = this.state

        let editAddress = false;
        let initialValues = {
            address: '',
            city: '',
            district: '',
            zip: '',
            country: activeCustomer.customer[0].billingAddress.softoneCountryCode,
            phone: '',
        }

        if (address && address.length) {
            initialValues = address[0]
            editAddress = true
        }

        if(submitProcess) {
            return <FullScreenLoader message="We are processing your new address, please wait" />
        }

        return <div id={id} className="address-form-container">
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    this.setState({submitProcess: true})

                    if(editAddress) {
                        updateAddressAction(activeCustomer._id, values)
                    }else {
                        createAddressAction(activeCustomer._id, values)
                    }
                }}
                validationSchema={
                    Yup.object().shape({
                        address: Yup.string().required('Shipping Address is required'),
                        zip: Yup.string().required('Shipping Zip is required'),
                        city: Yup.string().required('Shipping City is required'),
                    })}
                render={({values, errors, status, touched, isSubmitting, handleChange}) => (
                    <Form>
                        <FormSection title={editAddress ? 'Edit Shipping Address' : 'Create Shipping Address'}>
                            <FormElement label={t('Address')}>
                                <Field type="text" name="address"/>
                                {errors.address && touched.address && <div className="errors">{errors.address}</div>}
                            </FormElement>
                            <FormElement label={t("District")}>
                                <Field type="text" name="district"/>
                                {errors.district && touched.district && <div className="errors">{errors.district}</div>}
                            </FormElement>
                            <FormElement label={t("Zip")}>
                                <Field type="text" name="zip"/>
                                {errors.zip && touched.zip && <div className="errors">{errors.zip}</div>}
                            </FormElement>
                            <FormElement label={t("City")}>
                                <Field type="text" name="city"/>
                                {errors.city && touched.city && <div className="errors">{errors.city}</div>}
                            </FormElement>
                            <FormElement label={t("Phone")}>
                                <Field type="text" name="phone"/>
                                {errors.phone && touched.phone && <div className="errors">{errors.phone}</div>}
                            </FormElement>
                            <FormElement label={t("Country")}>
                                <select
                                    name="country"
                                    value={initialValues.softoneCountryCode}
                                    onChange={handleChange}
                                    style={{ display: 'block' }}
                                >
                                    {countries.map((country) => {
                                        return <option name="country" key={country.softoneId}
                                                       value={country.softoneId}>{country.name}</option>
                                    })}
                                </select>
                                {errors.country && touched.country && <div className="errors">{errors.country}</div>}
                            </FormElement>
                        </FormSection>
                        <div className="form-controls">
                            <button className="vamp-button" type="submit">
                                {editAddress ? t('Update') : t('Create')}
                            </button>
                            <button className="vamp-button" onClick={() => closeForm()}>
                                {t("Cancel")}
                            </button>
                        </div>
                    </Form>
                )}
            />
        </div>
    }
}

const mapStateToProps = ({customer, auth}) => {
    return {
        customer,
        auth
    }
}

export default withTranslation()(connect(mapStateToProps, {updateAddressAction, createAddressAction})(AddressForm))
