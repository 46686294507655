import axios from "axios";

class Order {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async refreshOrder(finDoc) {
        const orderId = await axios.post(`${this.apiUrl}/order/refresh/`, {
            finDoc
        })

        if(
            orderId &&
            orderId.status === 200 &&
            orderId.data &&
            orderId.data.success &&
            orderId.data.payload
        ) {
            return orderId.data.payload
        }

        return false
    }

    async getPdfLink(orderId) {
        let orderPdflink = await axios.get(`${this.apiUrl}/order/getPdfLink/${orderId}`)

        if(
            orderPdflink &&
            orderPdflink.status === 200 &&
            orderPdflink.data &&
            orderPdflink.data.success &&
            orderPdflink.data.payload
        ) {
            return orderPdflink.data.payload
        }

        return false
    }

    async getOrderPdfWithParams(id, company) {
        let orderPdflink = await axios.post(`${this.apiUrl}/order/orderLinkWithParameters/`, {
            id,
            company
        })

        if(
            orderPdflink &&
            orderPdflink.status === 200 &&
            orderPdflink.data &&
            orderPdflink.data.success &&
            orderPdflink.data.payload
        ) {
            return orderPdflink.data.payload
        }

        return false
    }

    async getOrderSummary(orderId) {
        let orderSummary = await axios.get(`${this.apiUrl}/order/getSummary/${orderId}`)

        if(
            orderSummary &&
            orderSummary.status === 200 &&
            orderSummary.data &&
            orderSummary.data.success &&
            orderSummary.data.payload
        ) {
            return orderSummary.data.payload
        }

        return false
    }

    async getFilteredSessions() {
        let filteredSession = await axios.get(`${this.apiUrl}/order/getSessions`)
        console.log(filteredSession)
        if(
            filteredSession &&
            filteredSession.status === 200 &&
            filteredSession.data &&
            filteredSession.data.success &&
            filteredSession.data.payload
        ) {
            return filteredSession.data.payload
        }
        return false
    }

    async getDetails(finDoc) {
        let orderDetails = await axios.get(`${this.apiUrl}/order/getDetails/${finDoc}`)

        if(
            !orderDetails.data ||
            !orderDetails.data.payload ||
            !orderDetails.data.payload.length
        )
        {
            return false
        }

        return orderDetails.data.payload
    }

    async sendSamples(samplesList, userId, customerId) {
        let orderResponse = await axios.post(`${this.apiUrl}/order/save/samples`, {
            samplesList,
            userId,
            customerId
        })

        if(
            orderResponse &&
            orderResponse.status === 200 &&
            orderResponse.data &&
            orderResponse.data.success &&
            orderResponse.data.payload
        ) {
            return orderResponse.data
        }

        return false
    }

    async save(customerId, cartItems, activeSavedOrderId = false) {
        let orderResponse = await axios.post(`${this.apiUrl}/order/save`, {
            customerId,
            cartItems,
            activeSavedOrderId
        })

        if(
            orderResponse &&
            orderResponse.status === 200 &&
            orderResponse.data &&
            orderResponse.data.success &&
            orderResponse.data.payload
        ) {
            return orderResponse.data.payload
        }

        return false
    }

    async delete(orderId) {
        await axios.delete(`${this.apiUrl}/order/${orderId}`)
    }

    async send(shippingAddressId, customerId, cartItems, extraOrderData, activeSavedOrderId, editMode) {

        let orderResponse = null

        if(activeSavedOrderId && editMode) {
            //Order edit mode
            orderResponse = await axios.patch(`${this.apiUrl}/order/${activeSavedOrderId}`, {
                shippingAddressId,
                customerId,
                cartItems,
                extraOrderData,
            })
        }else {
            //Normal order post
            orderResponse = await axios.post(`${this.apiUrl}/order`, {
                shippingAddressId,
                customerId,
                cartItems,
                extraOrderData,
                activeSavedOrderId
            })
        }

        if(
            orderResponse &&
            orderResponse.status === 200 &&
            orderResponse.data &&
            orderResponse.data.success &&
            orderResponse.data.payload
        ) {
            return orderResponse.data.payload
        }


        return false
    }
}

export default Order
