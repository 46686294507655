import React, {Component, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {normalizePrice} from "../../utils/priceUtils";
import Loader from "../Common/Loader";
import InfoTable from "../Common/InfoTable";
import PdfLinkButton from "./PdfLinkButton";
import { useTranslation } from "react-i18next";
import './OrdersTable.scss'

const OrdersTable = ({orders, history, showExtra}) => {
    const { t } = useTranslation();

    let colArray = [
        {
            Header: 'Order Number',
            accessor: 'fincode',
        },
        {
            Header: 'Order Date',
            accessor: 'date',
        },
        {
            Header: 'Season',
            accessor: 'season',
        },
        {
            Header: 'Order Type',
            accessor: 'ftype',
        },
        {
            Header: 'Total Qty',
            accessor: 'qty',
        },
        {
            Header: 'Total Price',
            accessor: 'price',
            Cell: props => {
                let value = props.cell.row.original.price
                return normalizePrice(value)
            },
        },
        {
            Header: 'Pdf',
            accessor: 'pdfUrl',
            skipFilter: true,
            Cell: props => {
                const {company, fincdoc} = props.cell.row.original

                return <PdfLinkButton
                    company={company}
                    fincdoc={fincdoc}
                />
            },
        },
    ]

    if (showExtra) {
        colArray.push({
            Header: 'Description',
            accessor: 'fincdoc',
            skipFilter: true,
            Cell: props => {
                let value = props.cell.row.original.fincdoc

                return <div className="order-details-btn">
                    <Link to={`/app/customer/orders/details/${value}`}>
                        <button className="vamp-button">{t('Details')}</button>
                    </Link>
                </div>
            },
        },)
    }

    const columns = React.useMemo(
        () => colArray,
        []
    )

    if (!orders || !orders.length) {
        return <h2>{t('Customer has no order, please search using the date filters above')}</h2>
    }

    return <div className="customer-orders-page">
        <div className="table-container">
            {/*<PanelHeader title="Customers Orders" />*/}
            <div className="panel-body">
                <InfoTable columns={columns} data={orders}/>
            </div>
        </div>
    </div>

}

export default OrdersTable
