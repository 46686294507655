import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    withRouter
} from 'react-router-dom'

const ConditionalRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => {
        return (
            (!rest.authenticated)
                ? <Redirect to='/login' />
                : <Redirect to='/app' />
        )
    }} />
)

export default ConditionalRoute
