import { TYPES } from '../actions/quickViewActions'
import {TYPES as authTypes} from '../actions/authActions'

const initialState = {
    quickViewProductId: null,
    inputValues: [],
};

const quickView = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.OPEN_QUICKVIEW_EDIT: {
            return {
                ...state,
                quickViewProductId: action.payload,
                inputValues: action.inputValues
            }
        }
        case TYPES.OPEN_QUICKVIEW: {
            return {
                ...state,
                quickViewProductId: action.payload
            }
        }
        case TYPES.CLOSE_QUICKVIEW: {
            return {
                ...state,
                quickViewProductId: initialState.quickViewProductId,
                inputValues: initialState.inputValues
            }
        }
        case authTypes.LOGOUT_ACTION: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state
    }
};


export default quickView
