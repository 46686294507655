import { TYPES } from '../actions/productAction'

const initialState = {
    lastVisitedProductId: null,
};

const general = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_VISITED_PRODUCT: {
            return {
                ...state,
                lastVisitedProductId: action.payload
            }
        }
        default:
            return state
    }
};


export default general
