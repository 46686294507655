import React from 'react'
import { connect } from 'react-redux'
import { wishlistToggleAction } from '../../actions/wishlistActions'
import ProductsList from "../Product/ProductsList";

import './WishlistPage.scss'
import {getCurrencySymbol} from "../../utils/priceUtils";
import ProductsArchiveHeader from "../Common/Archive/ProductsArchiveHeader";
import ArchivePage from "../Pages/Archive/ArchivePage";

const WishlistPage = ({ products, wishlistItems, history, wishlistToggleAction, variationPrices, activeCustomer }) => {
    return <div className="wishlist-page">
        <ArchivePage
            hideNavigation={true}
            history={history}
            wishlist={true}
        />
    </div>
}

const mapStateToProps = ({ wishlist, product, customer, auth }) => {
    return {
        wishlistItems: wishlist.items,
        products: product.products,
        variationPrices: product.variationPrices,
        activeCustomer: customer.activeCustomer,
        activeUser: auth.activeUser,
    }
}

export default connect(mapStateToProps, { wishlistToggleAction })(WishlistPage)
