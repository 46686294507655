import React from 'react'
import Button from "../Button";
import {ReactComponent as CheckoutSvg} from '../assets/checkout.svg';

import {ReactComponent as OrderSvg} from '../assets/order.svg';
import {ReactComponent as EyeSvg} from '../assets/eye.svg';
import { useTranslation } from "react-i18next";

import './SamplesPageControls.scss'

const SamplesPageControls = ({ itemsCount, toggleViewMode, viewOnlySelected, submitOrderAction, items, sampleWithDetails }) => {
    const { t } = useTranslation();

    const sum = sampleWithDetails.reduce((previousValue, currentValue) => previousValue + parseInt(currentValue.price),0,);

    return <div className="sample-page-controls">
            <div className="info-container">
                <div className="qty-info">
                    <span className="label">{t('Quantity')}</span>
                    <span className="value">{ itemsCount || 0 } </span>
                    <span className="label">{t('Total Price')}</span>
                    <span className="value">{ sum || 0 }</span>
                </div>
            </div>
            <div className="actions-container">
                <div className={`action-element view-only-selected ${viewOnlySelected ? 'active' : ''}`} onClick={toggleViewMode}>
                    <EyeSvg />
                </div>
                <div className="action-element" onClick={submitOrderAction}>
                    <Button className='action-element checkout-action-element'>
                        <span>{t('Checkout')}</span>
                        <CheckoutSvg/>
                    </Button>
                </div>
            </div>
    </div>

}

export default SamplesPageControls