import React, { Component } from 'react'
import { connect } from 'react-redux';

import { selectCustomerAction } from '../../actions/customerActions'
import { filterCustomers } from '../../utils/utils'
import PanelHeader from "./PanelHeader";
import PanelTopControls from "./PanelTopControls";
import PanelMenu from "./PanelMenu";
import CustomerList from "../Customer/CustomerList";

import './CustomersPanel.scss'
import './Panels.scss'
import PanelSearch from "../Common/PanelSearch";
import { withTranslation } from 'react-i18next';

class CustomersPanel extends Component{
    constructor(props) {
        super(props)

        this.state = {
            customers: [],
            searchTerm: '',
        }

        this.handleCustomerSearch = this.handleCustomerSearch.bind(this)
        this.handleCustomerSelect = this.handleCustomerSelect.bind(this)
    }

    componentDidMount() {
        const { customers } = this.props

        this.setState({
            customers
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevState.customers.length && this.props.customers && this.props.customers.length) {
            this.setState({
                customers: this.props.customers
            })
        }
    }

    handleCustomerSearch(e) {
        let searchTerm = e.target.value

        this.setState({
            searchTerm
        })
    }



    handleCustomerSelect(customer) {
        const { selectCustomerAction, history } = this.props
        selectCustomerAction(customer._id)
        history.push('/app')
    }

    render() {
        const { customers, searchTerm } = this.state
        const { potentialCustomer } = this.props
        const { t } = this.props;

        return <div className="panel customers-panel">
            <PanelHeader title="Customers"/>
            <div className="panel-body">
                <PanelTopControls>
                    <PanelSearch value={searchTerm} onChange={this.handleCustomerSearch} />
                    <PanelMenu
                        menuItems={[
                            {
                                link: `${(potentialCustomer ? '/app/account/customers/potential/create' : '/app/account/customers/create')}`,
                                title: `${(potentialCustomer) ? t('Create Potential') :  t('Create Customer')}`
                            }
                        ]}
                    />
                </PanelTopControls>
                <CustomerList perPage={12} handleCustomerSelect={this.handleCustomerSelect} customers={filterCustomers(customers, searchTerm)}/>
            </div>
        </div>
    }
}

const mapStateToProps = ({auth}) => {
    return auth
}

export default withTranslation()(connect(mapStateToProps, { selectCustomerAction })(CustomersPanel))
