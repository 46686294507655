import React, {useState} from 'react'
import SeasonArchiveFilters from './SeasonArchiveFilters'
import FamiliesList from "./FamiliesList";

import './SeasonFamiliesArchive.scss'

const SeasonFamiliesArchive = ({families, collections, history, wishlistToggle, wishlistItems}) => {

    const [activeFilter, setActiveFilter] = useState('ALL')
    const [activeGender, setActiveGender] = useState('ALL')

    if (!families.length) {
        return <h3 className="not-found-msg">No families matching your search</h3>
    }

    let familiesArray = families.filter(family => {
        if (activeFilter === 'ALL' || !activeFilter) {
            return true;
        }

        if(activeFilter === 'Favorites') {
            return  wishlistItems.families.includes(family._id)
        }

        if(!family.collectionName || activeFilter !== family.collectionName) {
            return false;
        }


        return true
    })

    // filter by gender
    familiesArray = familiesArray.filter(family => {
        if (activeGender === 'ALL' || !activeGender) {
            return true;
        }

        if(!family.genders.length) return false;

        if(activeGender === 'FAMILY') {
            return family.genders.length > 1;
        }

        if (!family.genders.includes(activeGender)) {
            return false;
        }


        return true
    })


    //Custom ordering
    const order = [
        "ALL", "NIGHTWEAR", "PILLOW TALK", "BEACHWEAR", "MENSWEAR", "JUST PANTS", "UNDERWEAR", "LOUNGEWEAR", "LINGERIE"
        // "FAVORITES",
    ]

    return <div className="families-archive">
        <nav className="collections-filter">
            <SeasonArchiveFilters filters={collections.sort((a, b) => order.indexOf(a) - order.indexOf(b))}
                                  handleChange={setActiveFilter} active={activeFilter}
                                  handleChangeGender={setActiveGender} activeGender={activeGender}/>
            {familiesArray.length ?
                <FamiliesList wishlistItems={wishlistItems} wishlistToggle={wishlistToggle} entries={familiesArray}
                                           handleClick={(familyId) => history.push(`/app/presentation/family/${familyId}`)}/> :
                <div className={'not-found-msg'}>No products found</div>
            }

        </nav>
    </div>
}

export default SeasonFamiliesArchive
