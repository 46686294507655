import React from 'react'

import './FullScreenLoader.scss'

const FullScreenLoader = ({message}) => {

    return <div className="fullscreen-loader">
        {message ? <span className="loader-message">{message}</span> : null}
        <div className="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>

}

export default FullScreenLoader