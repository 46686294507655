import axios from 'axios';

class Customer {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async getInvoicesBarcodesPdf(customerId, findoc, defaultprint) {
        const credits = await axios.post(`${this.apiUrl}/customer/${customerId}/info/invoices/pdf/barcodes`, {
            findoc,
            defaultprint,
        })
        return credits.data.payload
    }

    async getInvoicesPackingPdf(customerId, findoc, defaultprint) {
        const credits = await axios.post(`${this.apiUrl}/customer/${customerId}/info/invoices/pdf/packing`, {
            findoc,
            defaultprint
        })
        return credits.data.payload
    }

    async getInvoicesPdf(customerId, findoc, defaultprint) {
        const credits = await axios.post(`${this.apiUrl}/customer/${customerId}/info/invoices/pdf`, {
            findoc,
            defaultprint
        })
        return credits.data.payload
    }

    async getInvoices(customerId, date = '') {
        const invoices = await axios.post(`${this.apiUrl}/customer/${customerId}/info/invoices`, {
            date,
        })
        return invoices.data.payload
    }

    async getShipments(customerId) {
        const invoices = await axios.post(`${this.apiUrl}/customer/${customerId}/info/shipments`)
        return invoices.data.payload
    }

    async getCredits(customerId, date = '') {
        const credits = await axios.post(`${this.apiUrl}/customer/${customerId}/info/credits`, {
            date,
        })
        return credits.data.payload
    }

    async getCreditsPdf(customerId, findoc, defaultprint) {
        const credits = await axios.post(`${this.apiUrl}/customer/${customerId}/info/credits/pdf`, {
            findoc,
            defaultprint,
        })
        return credits.data.payload
    }

    async initInfosRequest(customerId, fromDate, toDate) {
        const infosId = await axios.post(`${this.apiUrl}/customer/${customerId}/info/request`, {
            fromDate,
            toDate
        })
        return infosId.data.payload
    }

    async getInfosPage(customerId, requestId, page) {
        const infosId = await axios.post(`${this.apiUrl}/customer/${customerId}/info/page`, {
            requestId,
            page
        })

        return infosId.data.payload
    }

    async getBalance(customerId) {
        const customerBalance = await axios.get(`${this.apiUrl}/customer/${customerId}/info/balance`)
        return customerBalance.data.payload
    }

    async getBalanceOverdue(customerId) {
        const customerBalanceOverdue = await axios.get(`${this.apiUrl}/customer/${customerId}/info/balance/overdue`)
        return customerBalanceOverdue.data.payload
    }

    async getBalances(customerId) {
        const customerBalance = await axios.get(`${this.apiUrl}/customer/${customerId}/info/balances`)
        return customerBalance.data.payload
    }

    async getSalesman(customerId) {
        const salesman = await axios.get(`${this.apiUrl}/customer/${customerId}/salesman`)
        return salesman.data.payload
    }

    async getSeasonOrders(defaultSeason) {
        const preorderCustomers = await axios.get(`${this.apiUrl}/customer/preorders/season/${defaultSeason}`)

        if (!preorderCustomers || !preorderCustomers.status === 200 || !preorderCustomers.data.payload.length) {
            throw Error('No Customers');
        }

        return preorderCustomers.data.payload
    }

    async updateEmail(customerId, email) {
        const customer = await axios.put(`${this.apiUrl}/customer/${customerId}/email`, {
            email
        })

        if (!customer || !customer.status === 200 || !customer.data.payload) {
            throw Error('Failed to update customer');
        }

        return customer.data.payload
    }

    async updatePassword(customerId, password) {
        const customer = await axios.put(`${this.apiUrl}/customer/${customerId}/password`, {
            password
        })

        if (!customer || !customer.status === 200 || !customer.data.payload) {
            throw Error('Failed to update customer password');
        }

        return customer.data.payload
    }

    async updateMobile(customerId, mobile) {
        const customer = await axios.put(`${this.apiUrl}/customer/${customerId}/mobile`, {
            mobile
        })

        if (!customer || !customer.status === 200 || !customer.data.payload) {
            throw Error('Failed to update customer');
        }

        return customer.data.payload
    }

    async getCustomers() {
        const customer = await axios.get(`${this.apiUrl}/customer`)

        if (!customer || !customer.status === 200 || !customer.data.payload.length) {
            throw Error('No Customers');
        }

        return customer.data.payload
    }

    async getOrders(userId, onlySaved = false) {

        let customerOrders = {}

        if (onlySaved) {
            customerOrders = await axios.get(`${this.apiUrl}/order/user/${userId}/saved`)
        } else {
            customerOrders = await axios.get(`${this.apiUrl}/customer/${userId}/orders`)
        }

        if (!customerOrders || !customerOrders.status || !customerOrders.status === 200 || !customerOrders.data.payload.length) {
            return []
        }

        return customerOrders.data.payload
    }

    async getOrdersByDate(userId, fromDate, toDate, session) {

        let customerOrders = {}

        customerOrders = await axios.post(`${this.apiUrl}/customer/${userId}/ordersByDate`, {
            fromDate,
            toDate,
            session,
        })

        if (!customerOrders || !customerOrders.status || !customerOrders.status === 200 || !customerOrders.data.payload.length) {
            return []
        }

        return customerOrders.data.payload
    }

    async getCountries() {
        const countries = await axios.get(`${this.apiUrl}/customer/countries`)

        if (!countries || !countries.status === 200 || !countries.data.payload.length) {
            return []
        }

        return countries.data.payload
    }

    async setDefaultAddress(addressId, customerId) {
        try {
            const customer = await axios.post(`${this.apiUrl}/customer/${customerId}/setDefaultAddress`, {
                addressId
            })

            if (!customer.status === 200 || !customer.data.payload) {
                throw new Error('Failed to update customer default address')
            }

            return customer.data.payload
        } catch (e) {
            console.log(e)
            return {}
        }
    }

    async createCustomer(customerData, potentialCustomer = false) {
        try {
            const customer = await axios.post(`${this.apiUrl}/customer/`, {
                customerData,
                potentialCustomer
            })

            return {
                customerCreated: true,
                payload: customer.data.payload
            }
        } catch (e) {
            return {
                customerCreated: false,
                payload: e.response.data.payload
            }
        }
    }

    async createAddress(customerId, addressItem) {
        try {
            const customer = await axios.post(`${this.apiUrl}/customer/${customerId}/address`, {
                addressItem
            })

            if (!customer.status === 200 || !customer.data.payload) {
                throw new Error('Failed to create new customer address')
            }

            return customer.data.payload
        } catch (e) {
            console.log(e)
            return false
        }
    }

    async updateAddress(customerId, addressItem) {
        try {
            const customer = await axios.patch(`${this.apiUrl}/customer/${customerId}/address`, {
                addressItem
            })

            if (!customer.status === 200 || !customer.data.payload) {
                throw new Error('Failed to update customer address')
            }

            return customer.data.payload
        } catch (e) {
            console.log(e)
            return false
        }
    }

    async saveCustomerSeasons(customerId, seasons) {
        const response = await axios.post(`${this.apiUrl}/customer/${customerId}/seasons`, {
            seasons,
        })

        return response.data.payload
    }

    async revokeCustomerAccess(customerId) {
        const response = await axios.post(`${this.apiUrl}/customer/${customerId}/access/revoke`)
        return response.data.payload
    }

    async forceAllowAccess(customerId) {
        const response = await axios.post(`${this.apiUrl}/customer/${customerId}/access/forceAllow`)
        return response.data.payload
    }

    async grantCustomerAction(customerId) {
        const response = await axios.get(`${this.apiUrl}/customer/${customerId}/access/grant`)
        return response.data.payload
    }

    async sendCustomerEmailValidation(email, token) {
        const response = await axios.post(`${this.apiUrl}/customer/emailValidation/init`, {
            email,
            token,
        })

        return response
    }

    async authorizeCustomer(token, authenticationPassword) {
        const response = await axios.post(`${this.apiUrl}/customer/emailValidation/authorize`, {
            token,
            authenticationPassword,
        })

        return response.data.payload
    }

    async forceVerify(customerId) {
        const response = await axios.get(`${this.apiUrl}/customer/${customerId}/access/verify`)
        return response.data.payload
    }

}

export default Customer
