import React, {useState, useEffect} from 'react'
import ReactFancyBox from 'react-fancybox'
import LazyLoad from 'react-lazy-load';

import {ReactComponent as PlusSvg} from '../assets/plus.svg';
import {ReactComponent as MinusSvg} from '../assets/minus.svg';
import { useTranslation } from "react-i18next";

import 'react-fancybox/lib/fancybox.css'
import './ProductMiniature.scss'

const printSampleControls = (product, sample, sampleAdded, updateAction, t) => {

    if (!sample) {
        return null
    }

    if (sampleAdded) {
        return <div className="sample-item-controls remove"
                    onClick={() => {
                        updateAction(product._id, 0)
                    }}>{t('Remove From Order')}</div>
    }

    return <div
        onClick={() => {
            updateAction(product._id, 1)
        }}
        className="sample-item-controls">{t('Add to Order')}</div>
}

const printImageItem = (product, fancy, lazyLoad, sample) => {

    if (lazyLoad) {
        if (fancy) {
            return <LazyLoad>
                <ReactFancyBox
                    thumbnail={product.image}
                    image={sample ? product.image.replace("width=250", "width=600") :product.image}/>
            </LazyLoad>
        } else {
            return <LazyLoad><img src={product.image} alt={product.titleEN}/></LazyLoad>
        }
    }

    //Return item without lazyload
    return <img src={product.image} alt={product.titleEN}/>
}

const ProductMiniature = ({product, updateAction, qty, fancy, sample, sampleAdded, lazyLoad, deleteAction}) => {
    const { t } = useTranslation();

    const [qtyInput, setQtyInput] = useState(qty)

    useEffect(() => {
        if(product.type === 'simple' && qtyInput) {
            if(!parseInt(qtyInput)) {
                deleteAction([product.code])
            }else {
                updateAction([{
                    productId: product._id,
                    barcode: product.code,
                    qty: qtyInput
                }], true)
            }
        }
    }, [qtyInput])

    return <div className={`product-miniature ${fancy ? 'fancy-miniature' : ''} ${sample? 'product-miniature-sample' : '' }`}>
        <div className="image">
            {printImageItem(product, fancy, lazyLoad, sample)}
        </div>
        <div className="meta">
            <div className="title">
                {product.titleEN}
            </div>
            <div className="code">
                {product.code}
            </div>
            {
                sample && <>
                        <div className="sample-title">
                            {t('Color')}: {product.ColorName} - ({product.color})
                        </div>
                        <div className="sample-title">
                            {t('Size')}: {product.size}
                        </div>
                        <div className="sample-title">
                            {t('Price')}: {product.price}
                        </div>
                    </>
            }
            {product.type === 'simple' && !sample ?
                <div className="qty-input">
                    <label>{t('Qty')}:</label>
                    <input
                        value={qtyInput}
                        type="number" min={0}
                        onFocus={(e) => {
                            e.target.select()
                            e.target.blur()
                        }
                        }
                        onChange={(e) => setQtyInput(e.target.value)}/>
                    <div className="miniature-input-controls">
                        <span className="tablet-control-element" onClick={(e) => {
                            if (parseInt(qtyInput) > 0) {
                                setQtyInput(parseInt(qtyInput) - 1)
                            }
                        }}>
                            <MinusSvg/>
                        </span>
                        <span className="tablet-control-element" onClick={(e) => {
                            setQtyInput(parseInt(qtyInput) + 1)
                        }}>
                            <PlusSvg/>
                        </span>
                    </div>
                </div>
                : null}
        </div>
        {printSampleControls(product, sample, sampleAdded, updateAction, t)}
    </div>
}

export default ProductMiniature
