import React from 'react'

import './Loader.scss'

const PriceLoader = (props) => {
    return <div className="price-loader lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
}

export default PriceLoader
