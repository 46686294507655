import React, {Fragment, useState, useEffect} from 'react'
import {ReactComponent as LogoSvg} from "../../assets/logo.svg";
import Api from '../../../controllers/Api';
import _ from 'loadsh'
import moment from 'moment'
import './CustomerAcceptAccessPage.scss'

// const initCustomerEmailValidation = async (email, token) => {
//     return await Api.customer.sendCustomerEmailValidation(email, token)
// }

const initCustomerAuthorization = (token, authenticationPassword, setFormError, setVerificationSuccess) => {
    Api.customer.authorizeCustomer(token, authenticationPassword).then(res => {
        setVerificationSuccess(true)
    }).catch(error => {
        setFormError(error.response.data.message)
    })
}

const initCustomerAuthorizationDebounced = _.debounce(initCustomerAuthorization, 500)

const FormHeader = ({notice, extraNotice}) => {
    return <header>
        <div className="login-logo">
            <LogoSvg/>
        </div>
        <div className="welcome-notice">
            <h1>Welcome to Vamp</h1>
            <p>{notice}</p>
            {extraNotice ? <p className="extra-notice">{extraNotice}</p> : null}
        </div>
    </header>
}

const CustomerAcceptAccessPage = ({history, match: {params: {accessToken}}}) => {
    const [verificationNumber, setVerificationNumber] = useState('')
    const [verificationSuccess, setVerificationSuccess] = useState(false)
    const [formError, setFormError] = useState('')

    useEffect(() => {
        //Logout on first visit
        localStorage.removeItem('vamp_salesapp_token');
    }, [])

    if(verificationSuccess) {
        setTimeout(() => {
            history.push('/app/presentation')
        }, 5000)
    }

    return <div className="customer-accept-access-page">
        {verificationSuccess ?
            <Fragment>
                <FormHeader
                    notice="Authentication successful. We have sent you an email containing your LOGIN information."
                    extraNotice="You are going to be automatically redirected to the LOGIN page in 5 seconds."
                />
            </Fragment>
            :
            <Fragment>
                <FormHeader
                    notice="Please enter your Verification PIN"
                />
                <div className="grant-access-form-container">
                    <div className="form-errors">
                        {formError}
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        setFormError('')

                        initCustomerAuthorizationDebounced(accessToken, verificationNumber, setFormError, setVerificationSuccess)
                    }}>
                        <div className="form-input">
                            <label>PIN</label>
                            <input required={true} type="text" value={verificationNumber} onChange={(e) => setVerificationNumber(e.target.value)}/>
                        </div>
                        <div className="form-input">
                            <button typeof="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </Fragment>
        }
    </div>
}

export default CustomerAcceptAccessPage
