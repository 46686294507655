import React from 'react'
import Search from '../Search/Search'

import CartSummaryWidget from './CartSummaryWidget'

import './CartHeader.scss'

const CartHeader = ({
                        points,
                        openQuickView,
                        cartCount,
                        cartTotalPrice,
                        underwearCount,
                        collectionsCount,
                        hasPreorders,
                    }) => {
    return <header className="cart-header">
        <div className="search-container">
            <Search openQuickView={openQuickView}/>
        </div>
        <div className="cart-actions-container">
            <CartSummaryWidget
                cartTotalPrice={cartTotalPrice}
                cartCount={cartCount}
                underwearCount={underwearCount}
                collectionsCount={collectionsCount}
                hasPreorders={hasPreorders}
                points={points}
            />
        </div>
    </header>
}

export default CartHeader
