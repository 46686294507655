import moment from "moment";

export const isTablet = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return false;
}

export const camelize = (str) => {
    if( str ){
        let arr = str.toLowerCase().split(' ');
        arr.forEach(function (item, index) {
            arr[index] = item.replace(item[0], item[0].toUpperCase());
        });

        return arr.join(' ');
    }
    else{
        return str
    }
}

export const customerCanLogin = (loginUntil) => {
    return moment(loginUntil).diff(moment().utc(), 'days') > 0
}

export const filterCustomers = (customers, searchTerm, filters = {}) => {

    if (!customers || !customers.length) {
        return []
    }

    //Find active filters
    const activeFilters = Object.values(filters).filter(item => item.value)
    return customers.filter((customer) => {

        if (activeFilters && activeFilters.length) {
            for (let i = 0; i < activeFilters.length; i++) {
                if (activeFilters[i].key === 'canLogin') {
                    if (activeFilters[i].value && !customerCanLogin(customer.canLoginUntil)) {
                        return false
                    }
                }
            }
        }

        if (!searchTerm) {
            return true;
        }

        let searchTermNormalized = searchTerm.toLowerCase().trim()

        if (
            !customer.name ||
            !customer.email ||
            !customer.customer[0]
        ) {
            return false;
        }

        if (
            customer.name.toLowerCase().includes(searchTermNormalized) ||
            customer.customer[0].altCode && customer.customer[0].altCode.toLowerCase().includes(searchTermNormalized) ||
            customer.email && customer.email.toLowerCase().includes(searchTermNormalized) ||
            customer.customer[0].billingAddress.city && customer.customer[0].billingAddress.city.toLowerCase().includes(searchTermNormalized)
        ) {
            return true;
        }

        return false;
    })
}

export const extractProductFilters = (entries) => {
    const filterFields = {
        gender: {
            label: "Gender",
            values: new Set(),
            active: [],
            key: "gender"
        },
        arttype: {
            label: "Art Type",
            values: new Set(),
            active: [],
            key: "arttype"
        },
        collection: {
            label: "Collection",
            values: new Set(),
            active: [],
            key: "productCollection"
        },
        front: {
            label: "Front",
            values: new Set(),
            active: [],
            key: "front"
        },
        sleeve: {
            label: "Sleeve",
            values: new Set(),
            active: [],
            key: "sleeve"
        },
        length: {
            label: "Length",
            values: new Set(),
            active: [],
            key: "length"
        },
        pants: {
            label: "Pants",
            values: new Set(),
            active: [],
            key: "pants"
        },
        plussize: {
            label: "Plus Size",
            values: new Set(),
            active: [],
            key: "plussize"
        },
        composition: {
            label: "Composition",
            values: new Set(),
            active: [],
            key: "composition"
        },
    }

    entries.filter(entry => {
        Object.keys(filterFields).filter(key => {
            if (entry[filterFields[key].key]) {
                filterFields[key].values.add(entry[filterFields[key].key])
            }
        })
    })

    return filterFields
}

export const calculateDate = (type) => {
    let startDate = moment().toDate()
    let endDate = moment().toDate()

    switch (type) {
        case 'current_year': {
            startDate = moment().startOf('year').toDate();
            endDate = moment().toDate();
            break
        }
        case 'current_month': {
            startDate = moment().startOf('month').toDate();
            endDate = moment().toDate();
            break
        }

        case 'previous_month': {
            startDate = moment().subtract(1, 'months').startOf('month').toDate();
            endDate = moment().subtract(1, 'months').endOf('month').toDate();
            break
        }
    }

    return {
        startDate,
        endDate
    }
}

export const cloudflareImage = (url, width, height = false) => {
    if(url){
        let urlSplit = url.split('/');

        let baseUrl = urlSplit.slice(0,3).join('/');
        let pathUrl = urlSplit.slice(3).join('/');

        if(!baseUrl.length || !pathUrl.length) {
            return url;
        }

        let options = `/cdn-cgi/image/fit=contain,width=${width}`;
        options = height ? options += `,height=${height}/` : options + '/';

        url = baseUrl + options + pathUrl;
        return url;
    }
    else{
        return ''
    }
}
