import React from 'react'

import {ReactComponent as AngleLeftSvg} from '../assets/angle-left.svg';
import {ReactComponent as AngleRightSvg} from '../assets/angle-right.svg';
import {ReactComponent as PlusSvg} from '../assets/plus.svg';
import {ReactComponent as MinusSvg} from '../assets/minus.svg';
import { isTablet } from "../../utils/utils";

import './TabletControls.scss'

const TabletControls = ({activeBarcode, inputsSelector, remove, add}) => {
    if(!isTablet()) {
        return null;
    }

    let inputs = document.querySelectorAll(inputsSelector);

    return <div className="tablet-controls-component">
        <div className="table-controls-container">
            <span className="tablet-control-element" onClick={() => {
                for(let i =0; i < inputs.length; i++) {
                    const elementBarcode = inputs[i].getAttribute('data-barcode')

                    if(elementBarcode && elementBarcode === activeBarcode) {
                        if(inputs[i - 1]) {
                            inputs[i - 1].focus();
                            inputs[i - 1].blur();
                            break
                        }
                    }
                }
            }}>
                <AngleLeftSvg/>
            </span>
            <span className="tablet-control-element" onClick={remove}>
                <MinusSvg/>
            </span>
            <span className="tablet-control-element" onClick={add}>
                <PlusSvg/>
            </span>
            <span className="tablet-control-element" onClick={() => {
                for(let i =0; i < inputs.length; i++) {
                    const elementBarcode = inputs[i].getAttribute('data-barcode')

                    if(elementBarcode && elementBarcode === activeBarcode) {
                        if(inputs[i + 1]) {
                            inputs[i + 1].focus();
                            inputs[i + 1].blur();
                            break
                        }
                    }
                }
            }}>
                <AngleRightSvg/>
            </span>
        </div>
    </div>
}

export default TabletControls
