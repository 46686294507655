import React, {Fragment} from 'react'
import {Link} from "react-router-dom";
import ColorsList from '../Product/ColorsList'
import {ReactComponent as BackSvg} from "../assets/back.svg";
import {camelize} from "../../utils/utils";

import './FamilyHeader.scss'

const FamilyMeta = ({composition, fabric}) => {
    return null

    if (!composition) {
        return null
    }

    return <div className="family-composition-and-fabric">
        {`${camelize(fabric)} - ${camelize(composition)}`}
    </div>
}

const FamilyHeader = ({
                          title,
                          colors,
                          sizes,
                          composition,
                          season,
                          fabric,
                          history,
                          defaultSeason,
                          applyBanner,
                          bannerMobile,
                          bannerDesktop,
}) => {
    return <Fragment>
        {season.softoneId == 8 ?
            <span className="back-button" onClick={() => history.goBack()}>
             <BackSvg/><span>All Families</span>
        </span>
        :
            <Link className='back-button' to={`/app/presentation/season/${season.softoneId}`}>
                <BackSvg/><span>All Families</span>
            </Link>
        }
        <header>
            <div className="title-container">
                <h1 className="title">
                    {title}
                </h1>
            </div>
            <div className="header-extras">
                <div className="left-container">
                    <ColorsList colors={colors}/>
                </div>
                <div className="right-container">
                    <FamilyMeta composition={composition} fabric={fabric}/>
                </div>
            </div>
            {applyBanner && (
                <div className={'banner-header'}>
                    {bannerDesktop && <img className={'banner-desktop'} src={bannerDesktop} alt="header banner"/>}
                    {bannerMobile && <img className={'banner-mobile'} src={bannerMobile} alt="header banner"/>}
                </div>
            )}
        </header>
    </Fragment>

}

export default FamilyHeader
