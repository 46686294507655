import React, { Component } from 'react'
import Api from "../../controllers/Api";
import OrdersTable from "../Orders/OrdersTable";
import PanelHeader from "./PanelHeader";
import {clearSavedOrder} from "../../actions/orderActions";

class UserOrdersPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: []
        }
    }

    componentDidMount() {
        this.mounted = true;
        const { user } = this.props

        let userId = false;

        if(user.role === 'customer') {
            userId = user._id
        }else {
            userId = user.id
        }

        this.getUserOrders(userId).then((orders) => {
            if(this.mounted) {
                this.setState({
                    orders
                })
            }
        })
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    getUserOrders(activeUserId) {
        return Api.customer.getOrders(activeUserId, true)
    }

    render() {
        const { loadOrderAction, user, clearSavedOrder } = this.props
        const { orders } = this.state

        return <div className="panel user-orders-panel">
            <PanelHeader title="Saved Orders"/>
            <OrdersTable loadOrderAction={loadOrderAction} deleteOrder={ async (orderId) => {

                await Api.order.delete(orderId)
                const orders = await Api.customer.getOrders(user._id, true)

                clearSavedOrder()

                this.setState({
                    orders
                })

            }} orders={orders}/>
        </div>
    }
}

export default UserOrdersPanel
