import axios from "axios";

class Season {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async get() {
        let seasons = await axios.get(`${this.apiUrl}/seasons`)
        return seasons.data.payload
    }

    async getPresentation() {
        let seasons = await axios.get(`${this.apiUrl}/seasons/presentation`)
        return seasons.data.payload
    }

    async getActive() {
        let seasons = await axios.get(`${this.apiUrl}/seasons/active`)
        return seasons.data.payload
    }

}

export default Season
