import React, {useState} from 'react'
import { connect } from 'react-redux'
import PanelHeader from "../../Panels/PanelHeader";
import DatePicker from "react-datepicker";
import Api from "../../../controllers/Api";
import InfoTable from "../../Common/InfoTable";
import DateRanges from "../../Common/Infos/DateRanges";
import {getCurrencySymbolPerCurrency, normalizePrice} from "../../../utils/priceUtils";
import Loader from "../../Common/Loader";

const SalesmanTurnovers = ({ settings: { settings }, season: { seasons }, activeUser }) => {

    const [ fromDate, setFromDate ] = useState('')
    const [ toDate, setToDate ] = useState('')
    const [ turnovers, setTurnovers ] = useState([])
    const [ showLoader, setShowLoader ] = useState(false)

    const columns = React.useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'ClientCode',
                Footer: ({data}) => {
                    return <strong>Totals</strong>
                }
            },
            {
                Header: 'Name',
                accessor: 'ClientName',
            },
            {
                Header: 'Current turnover',
                accessor: 'Ctz',
                Cell: props => {
                    let value = props.cell.row.original.Ctz
                    value = normalizePrice(value)

                    return <span>€{`${value}`}</span>
                },
                Footer: ({data}) => {

                    if(!data || !data.length) {
                        return null
                    }

                    let total = data.reduce((total, { Ctz }) => total += parseFloat(Ctz), 0)
                    total = normalizePrice(total)

                    return <strong>€{total}</strong>
                }
            },
            {
                Header: 'Previous year',
                accessor: 'Ptz',
                Cell: props => {
                    let value = props.cell.row.original.Ptz
                    value = normalizePrice(value)

                    return <span>€{`${value}`}</span>
                },
                Footer: ({data}) => {

                    if(!data || !data.length) {
                        return null
                    }

                    let total = data.reduce((total, { Ptz }) => total += parseFloat(Ptz), 0)
                    total = normalizePrice(total)

                    return <strong>€{total}</strong>
                }
            },
        ],
        []
    )

    if(showLoader) {
        return <Loader />
    }

    return <div className="infos-panel salesman-infos salesman-turnovers">
        <PanelHeader title="Turnovers"/>
        <div className="panel-body">
            <div className="panel-filters">
                <div className="date-filters">
                    <div className="form-group">
                        <label>From Date</label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={fromDate}
                            onChange={date => setFromDate(date)}
                        />
                    </div>
                    <div className="form-group">
                        <label>To Date</label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={toDate}
                            onChange={date => setToDate(date)}
                        />
                    </div>
                </div>
                <DateRanges
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                />
                <div className="form-group">
                    <button onClick={() => {
                        setTurnovers([])
                        setShowLoader(true)
                        Api.salesman.getTurnovers(fromDate, toDate).then(data => {
                            if(data && data.length) {
                                setTurnovers(data)
                            }else {
                                alert('No data found')
                            }
                        }).finally(() => {
                            setShowLoader(false)
                        })
                    }} className="vamp-button">Search</button>
                </div>
            </div>
            {turnovers && turnovers.length ?
                <div className="turnovers-table">
                    <InfoTable columns={columns} data={turnovers} />
                </div>
                :
                null
            }
        </div>
    </div>
}

const mapStateToProps = ({ settings, season, auth }) => {
    return {
        settings,
        season,
        activeUser: auth.activeUser
    }
}

export default connect(mapStateToProps, null)(SalesmanTurnovers)