import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    withRouter
} from 'react-router-dom'

const PrivateRoute = ({component: Component, ...rest}) => {
    if(!rest.triedToAuth) {
        rest.authenticateUsingToken()
        return null
    }

    if(!rest.authenticated) {
        return <Route {...rest} render={(props) => <Redirect to={{ pathname: '/login', state: { from: props.location }}} /> } />
    }

    return <Route {...rest} render={(props) => <Component {...props} /> } />
}

export default PrivateRoute
