import React, {useState, useEffect, Fragment} from 'react'
import moment from 'moment'
import Select from 'react-select'
import Button from "../../Button";
import EditField from "../../Common/EditField";
import {customerCanLogin} from '../../../utils/utils'
import './CustomerAccessData.scss'
import { useTranslation } from "react-i18next";

const CustomerAccessData = (
    {
        customer,
        onClick,
        seasonsArray,
        updateCustomerSeasons,
        createCustomerAccess,
        forceVerifyCustomer,
        revokeCustomerAccess,
        forceAllowCustomerAccess,
        updateEmail,
        updateMobile,
        customersSoftoneIdsWithSeasonOrders,
        activeUserCountryCode,
        role,
    }
) => {
    const { t } = useTranslation();

    let canPreorderUntil = new moment(customer.customer[0].canPreorderUntil).format('DD-MM-YYYY');

    return <tr onClick={onClick} className="customer-list-item access-list-item">
        <td className="customer-name">
            <div className="value">
                {customer.name}
            </div>
        </td>
        <td className="customer-email">
            <EditField
                editAccess={true}
                type="email"
                value={customer.email}
                placeholder={t('email...')}
                submitAction={(value) => {
                    updateEmail(customer._id, value.value)
                }}
            />
        </td>
        {activeUserCountryCode === 1000 ?
            <td className="customer-mobile">
                <EditField
                    editAccess={true}
                    type="mobile"
                    value={customer.mobile}
                    placeholder={t('mobile...')}
                    submitAction={(value) => {
                        updateMobile(customer._id, value.value)
                    }}
                />
            </td>
            : null}
        <td className="customer-alt-code">
            <div className="value">
                {customer.customer[0].altCode}
            </div>
        </td>
        <td className="customer-potential">
            <div className="value">
                {customer.customer[0].potential ? <span className="yes-value">{t('Yes')}</span> :
                    <span className="no-value">{t('No')}</span>}
            </div>
        </td>
        <td className="customer-can-preorder">
            <div className="value">
                {customer.customer[0].canPreorder ? <span className="yes-value">{t('Yes')}</span> :
                    <span className="no-value">{t('No')}</span>}
            </div>
        </td>
        <td className="customer-has-orders">
            <div className="value">
                {(customer.softoneId && customersSoftoneIdsWithSeasonOrders.includes(customer.softoneId.toString())) ?
                    <span className="yes-value">{t('Yes')}</span> : <span className="no-value">{t('No')}</span>}
            </div>
        </td>
        <td className="customer-preorder-until">
            {canPreorderUntil}
        </td>
        <td className="customer-preorder-authenticated">
            {customer.customer[0].preorderAuthenticated ? t('Verified') : t('Unverified')}
        </td>
        <td className="customer-send-access">
            {customer.customer[0].canPreorder ?
                <span className="vamp-button vamp-red" onClick={() => revokeCustomerAccess(customer._id)}>{t('Revoke')}</span> :
                <span className="vamp-button vamp-green" onClick={() => createCustomerAccess(customer._id)}>{t('Allow')}</span>}
        </td>
        <td className="customer-verify">
            <span className="vamp-button vamp-green" onClick={() => forceVerifyCustomer(customer._id)}>{t('Verify')}</span>
        </td>
    </tr>
}

export default CustomerAccessData
