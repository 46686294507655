import React, {Component} from 'react'

import Api from '../../controllers/Api'

import SearchResultItem from "./SearchResultItem";
import SearchResultsList from "./SearchResultsList";
import BarcodeModal from "../Cart/BarcodeModal";
import {connect} from "react-redux";

import {ReactComponent as SearchSvg} from '../assets/search.svg'
import {ReactComponent as CloseSvg} from '../assets/close.svg'
import {ReactComponent as BarcodeSvg} from "../assets/barcode.svg";

import debounce from 'lodash.debounce';

import './Search.scss'
import Loader from "../Common/Loader";

class Search extends Component {

    constructor(props) {
        super(props);

        console.log(props)

        this.state = {
            searchTerm: '',
            products: [],
            showBarcodeModal: false,
        }

        this.searchInputRef = React.createRef()

        this.debouncedSearch = debounce((term) => this.performSearch(term), 800);
    }

    openQuickViewFactory(productId, editMode = false) {
        const { openQuickView} = this.props

        this.searchInputRef.current.blur();
        openQuickView(productId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { searchTerm, products } = this.state

        if((!searchTerm || !searchTerm.length) && (products && products.length)) {
            this.setState({
                products: []
            })
        }

        if(searchTerm && searchTerm.length && (prevState.searchTerm !== searchTerm)) {
            this.debouncedSearch(searchTerm)
        }
    }

    performSearch(searchTerm) {

        console.log(this.state)
        console.log(this)

        this.setState({
            products: [],
            searchLoading: true
        })

        Api.product.searchProduct(searchTerm).then((res) => {
            if(res) {
                let tempRes
                if((this.props.customer && this.props.customer.activeCustomer && this.props.customer.activeCustomer.customer && this.props.customer.activeCustomer.customer.length && this.props.customer.activeCustomer.customer[0]
                        && this.props.customer.activeCustomer.customer[0].billingAddress && this.props.customer.activeCustomer.customer[0].billingAddress.softoneCountryCode === 1000) ||
                    ( this.props.auth.activeUser && this.props.auth.activeUser.allAccess && this.props.auth.activeUser.b2bView === 'GR' ) ||
                    ( this.props.auth.activeUser && this.props.auth.activeUser.role === 'admin' && this.props.auth.activeUser.b2bView === 'GR' ) ||
                    ( this.props.auth.activeUser && this.props.auth.activeUser.role === 'salesman' && this.props.auth.activeUser.softoneCountryCode === 1000 ))
                {
                    tempRes = res
                }
                else{
                    console.log(res)
                    tempRes = res.filter( product => ( product.family && product.family.factory !== 'Simone' ) )
                    console.log(tempRes)
                }

                if(tempRes.length && tempRes.length === 1) {
                    this.openQuickViewFactory(tempRes[0]._id)
                    this.setState({
                        searchTerm: '',
                        searchLoading: false
                    })
                }else {

                    this.setState({
                        products: tempRes || [],
                        searchLoading: false
                    })

                    /*if((this.props.customer && this.props.customer.activeCustomer && this.props.customer.activeCustomer.customer && this.props.customer.activeCustomer.customer.length && this.props.customer.activeCustomer.customer[0]
                            && this.props.customer.activeCustomer.customer[0].billingAddress && this.props.customer.activeCustomer.customer[0].billingAddress.softoneCountryCode === 1000) ||
                        ( this.props.auth.activeUser && this.props.auth.activeUser.allAccess && this.props.auth.activeUser.b2bView === 'GR' ) ||
                        ( this.props.auth.activeUser && this.props.auth.activeUser.role === 'admin' && this.props.auth.activeUser.b2bView === 'GR' ) ||
                        ( this.props.auth.activeUser && this.props.auth.activeUser.role === 'salesman' && this.props.auth.activeUser.b2bView === 'GR' )
                    ){
                        this.setState({
                            products: res || [],
                            searchLoading: false
                        })
                    }
                    else{
                        console.log(res)
                        this.setState({
                            products: res.filter( product => product.family.factory !== 'Simone' ) || [],
                            searchLoading: false
                        })
                    }*/

                }
            }
        })
    }

    renderSearchResults() {
        const {products} = this.state

        if(!products || !products.length) {
            return null
        }

        return <SearchResultsList>
            {products.map(searchResultItem => <SearchResultItem openQuickView={(id) => {
                this.setState({searchTerm: ''}, () => {
                    this.openQuickViewFactory(id)
                })
            }} key={searchResultItem._id} item={searchResultItem}/>)}
        </SearchResultsList>

        // const searchResults = products.filter((product) => {
        //     if (!product.code || !product.titleEN) {
        //         return false
        //     }
        //
        //     const bsample = product.bsample || ''
        //
        //     return (
        //         product.titleEN.toLowerCase().includes(searchTerm.toLowerCase()) ||
        //         product.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        //         bsample.toLowerCase().includes(searchTerm.toLowerCase())
        //     )
        // })
        //
        // //A bit hackyy here needs refactoring
        // if(searchResults.length === 1) {
        //     const foundItem = Object.assign({}, searchResults[0])
        //     this.setState({searchTerm: ''}, () => {
        //         this.openQuickViewFactory(foundItem._id, true)
        //     })
        // }else {
        //     return <SearchResultsList>
        //         {searchResults.map(searchResultItem => <SearchResultItem openQuickView={(id) => {
        //             this.setState({searchTerm: ''}, () => {
        //                 this.openQuickViewFactory(id)
        //             })
        //         }} key={searchResultItem._id} item={searchResultItem}/>)}
        //     </SearchResultsList>
        // }
    }

    render() {
        const {products, searchLoading} = this.state
        const {searchTerm, showBarcodeModal} = this.state

        return <div className="search-component-wrapper">
            <div className="search-component">
                <div className="search-input">
                    <div className="search-icon">
                        <SearchSvg/>
                    </div>
                    <input ref={this.searchInputRef} className="search-input" value={searchTerm}
                           onChange={(e) => this.setState({searchTerm: e.target.value})}/>
                    <div className="clear-icon" onClick={() => this.setState({searchTerm: ''})}>
                        <CloseSvg/>
                    </div>
                </div>
                {searchLoading ? <Loader type="ellipsis" /> : null}
                {this.renderSearchResults(products)}
            </div>
            <div className="search-controls">
                <div className="barcode-scanner-button"
                     onClick={() => this.setState({
                         showBarcodeModal: !showBarcodeModal,
                         searchTerm: ''
                     })}>
                    <BarcodeSvg/>
                </div>
                {showBarcodeModal ?
                    <BarcodeModal
                        closeBarcodeScanner={() => this.setState({showBarcodeModal: !showBarcodeModal})}
                        setCode={(code) => {
                            this.setState({
                                searchTerm: code,
                                showBarcodeModal: false
                            })
                        }}
                    />
                    : null}
            </div>
        </div>
    }
}
const mapStateToProps = ({auth, customer}) => {
    return {
        auth,
        customer
    }
}

export default connect(mapStateToProps, {})(Search);
