import axios from 'axios';

class Family {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async get() {
        const families = await axios.get(`${this.apiUrl}/families`)
        if(!families || !families.status === 200) {
            return false
        }

        return families.data.payload
    }
}

export default Family
