import React from 'react'

const InfoBox = ({ label, value }) => {
    return <div className="panel-field customer-name">
        <label>
            {label}
        </label>
        <span className="value">{value}</span>
    </div>
}

export default InfoBox
