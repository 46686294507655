import React, {Component} from 'react'

import Api from "../../controllers/Api";

import {ReactComponent as CheckedSvg} from '../assets/checked.svg';
import {ReactComponent as NoticeSvg} from '../assets/notice.svg';
import {ReactComponent as PdfSvg} from '../assets/pdf.svg';
import { normalizePrice } from '../../utils/priceUtils'
import { withTranslation } from 'react-i18next';

import './ThankYouPage.scss'

class ThankYouPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            orderId : null,
            summary : null,
            orderPdfLink: null,
            triedToGetLink: false
        }
    }

    componentDidMount() {
        const { match: { params: { orderId } } } = this.props

        //Check the case
        //if we have an order id seems the order is passed correctly to softone
        if(orderId) {
            this.setState({
                orderId
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { orderId, orderPdfUrl, triedToGetLink } = this.state;

        if(orderId && !orderPdfUrl && !triedToGetLink) {
            Api.order.getPdfLink(orderId).then((pdfLink) => {
                this.setState({
                    orderPdfLink: pdfLink,
                    triedToGetLink: true
                })
            }).then(() => {
                Api.order.getOrderSummary(orderId).then((summary) => {
                    this.setState({
                        summary
                    })
                })
            })

            this.setState({
                triedToGetLink: true
            })
        }
    }

    printResultMsg(orderId, orderPdfLink) {
        const { t } = this.props;

        if(!orderId) {
            return <div className="result-msg notice-msg">
                <p>{t('Due to some technical issues your order hasn\'t reached our system')}</p>
                <p>{t('We will try to re-submit automatically the order in a bit')}</p>
            </div>
        }else if(orderId && !orderPdfLink) {
            return <div className="result-msg notice-msg">
                <p>{t('Due to some technical issues we couldn\'t generate th pdf report for your order')}</p>
                <p>{t('We will try to generate and email the report to you automatically in a bit')}</p>
            </div>
        }else {
            return <div className="result-msg">
                <p>{t('You can download a detailed report of your order by clicking the Pdf icon below')}</p>
                <div className="pdf-link">
                    <a href={orderPdfLink} target="_blank">
                        <PdfSvg />
                    </a>
                </div>
            </div>
        }
    }

    printOrderSummary(summary) {
        const { t } = this.props;
        if(!summary) {
            return null
        }

        return <div className="order-summary-table">
            <h3>{t('Order Summary')}</h3>
            <table>
                <tbody>
                <tr>
                    <th>
                        {t('Price')}
                    </th>
                    <th>
                        {t('Total Qty')}
                    </th>
                </tr>
                <tr>
                    <td>
                        {summary.currency} {normalizePrice(summary.totalPrice)}
                    </td>
                    <td>
                        {summary.totalQty}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    }

    render() {
        const { t } = this.props;
        const { orderId, orderPdfLink, summary } = this.state

        return (
            <div className="thank-you-page">
                <div className="header-message">
                    {orderId ? <CheckedSvg /> : <NoticeSvg/>}
                    <h3>{t('Thank you for your order')}!</h3>
                </div>
                <div className="body-message">
                    {/*<h4 className="intro-msg">*/}
                    {/*    Your order was successfully submitted!*/}
                    {/*</h4>*/}
                    {this.printResultMsg(orderId, orderPdfLink)}
                </div>
                {this.printOrderSummary(summary)}
            </div>
        );
    }
}

export default withTranslation()(ThankYouPage)
