import React, {Component} from 'react'
import {connect} from 'react-redux'

import { wishlistToggleAction } from '../../actions/wishlistActions'

import SeasonArchiveHeader from '../Season/Archive/SeasonArchiveHeader'
import ProductsList from '../Product/ProductsList'
import {getCurrencySymbol} from "../../utils/priceUtils";
import Api from "../../controllers/Api";
import axios from "axios";
import Loader from "../Common/Loader";

class TopProductsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
        }
    }

    componentDidMount() {
        const {
            history,
            settings: {presentation: {seasons}},
            match: {params: {seasonId}},
            productsArray,
            activeCustomer,
            activeUser
        } = this.props

        let topProductsCompany = false
        let topProductsCountry = false

        if(this.props.match.url.includes("/top/salesman")) {
            if(activeUser.role === 'customer') {
                history.push('/app')
            }
            topProductsCompany = activeUser.b2bView === 'GR' ? 1001 : 1003
            topProductsCountry = activeUser.softoneCountryCode
        }else {
            topProductsCompany = activeCustomer.b2bView === 'GR' ? 1001 : 1003
            topProductsCountry = activeCustomer.customer[0].billingAddress.softoneCountryCode
        }

        if(topProductsCompany && topProductsCountry) {
            Api.product.getTopProducts(topProductsCompany, topProductsCountry, seasonId).then(productsSoftoneIds => {
                //Get collection data for families

                Api.product.getMultiple(productsSoftoneIds, 'mtrl')
                    .then(products => {
                        this.setState({
                            products
                        })
                    })
            })
        }
    }


    render() {
        const {history,  match: {params: {seasonId}}, wishlistItems, wishlistToggleAction, variationPrices, activeCustomer} = this.props
        const {products} = this.state
        const currencySymbol = getCurrencySymbol(activeCustomer)

        if(!products || !products.length) {
            return <Loader />
        }

        return <div className="season-archive-page">
            <SeasonArchiveHeader
                title='Top Articles'
                backLabel={'Season'}
                backAction={() => {
                    history.push(`/app/presentation/season/${seasonId}/`)
                }}
                switchViewAction={() => {
                    history.push(`/app/presentation/season/${seasonId}/products`)
                }}
            />

            <ProductsList
                extraMeta={true}
                showFilters={false}
                entries={products}
                variationPrices={variationPrices}
                currencySymbol={currencySymbol}
                activeCustomer={activeCustomer}
                history={history}
                postsPerPage={36}
                wishlistItems={wishlistItems}
                wishlistToggle={wishlistToggleAction}
                activeFiltersFromParams={{}}
            />
        </div>
    }
}

const mapStateToProps = ({settings, product, season, wishlist, customer, auth}) => {
    return {
        settings: settings.settings,
        productsArray: product.products,
        variationPrices: product.variationPrices,
        seasonsArray: season.seasons,
        wishlistItems: wishlist.items.products,
        activeCustomer: customer.activeCustomer,
        activeUser: auth.activeUser
    }
}

export default connect(mapStateToProps, { wishlistToggleAction })(TopProductsPage)
