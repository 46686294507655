import React, {Component} from 'react'
import {connect} from 'react-redux'

import { wishlistToggleAction } from '../../actions/wishlistActions'

import SeasonArchiveHeader from '../Season/Archive/SeasonArchiveHeader'
import ProductsList from '../Product/ProductsList'
import {getCurrencySymbol} from "../../utils/priceUtils";
import ArchivePage from "./Archive/ArchivePage";
import Loader from "../Common/Loader";

class SeasonProductsPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            history,
            match,
        } = this.props

        //Get season id
        if(!match.params || !match.params.seasonId) {
            return <Loader />
        }

        return  <ArchivePage
            history={history}
            preorderSeasonId={match.params.seasonId}
            preorder={true}
        />
    }
}

const mapStateToProps = ({settings, product, season, wishlist, customer, auth}) => {
    return {
        settings: settings.settings,
        productsArray: product.products,
        variationPrices: product.variationPrices,
        seasonsArray: season.seasons,
        wishlistItems: wishlist.items,
        activeCustomer: customer.activeCustomer,
        activeUser: auth.activeUser,
    }
}

export default connect(mapStateToProps, { wishlistToggleAction })(SeasonProductsPage)
