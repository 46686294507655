import React, {useState} from 'react'
import {ReactComponent as LikeSvg} from "../../assets/like-black.svg";
import Button from "../../Button";

import './SeasonArchiveFilters.scss'

const SeasonArchiveFilters = ({filters, active, activeGender, handleChange, handleChangeGender}) => {

    const [filtersOpen, toggleFiltersOpen] = useState(false)

    const genders = ['ALL', 'WOMEN', 'MEN'];

    return <aside className="family-filters">
        <Button callback={() => toggleFiltersOpen(!filtersOpen)}
                title={`${filtersOpen ? 'Close Filters' : 'Filters'}`}/>

        <ul className={`filters-list ${filtersOpen ? 'show-filters' : null}`}>
            {genders.map((gender) => <li
                className={`family-filter-element ${activeGender === gender ? 'active' : ''}`}
                onClick={() => {
                    handleChangeGender(gender)
                    handleChange('ALL')
                }
                }
                key={gender}
            >
                <span>{gender}</span>
            </li>)}
        </ul>

        <ul className={`filters-list ${filtersOpen ? 'show-filters' : null}`}>
            {filters.map((filter, index) => <li onClick={() => {
                toggleFiltersOpen(false)
                handleChange(filter)
            }} key={index} className={`family-filter-element ${active === filter ? 'active' : ''}`}>
                {filter === 'Favorites' ? <span className="filter-icon"><LikeSvg/></span> : null}
                <span className="filter-name">
                    {filter}
                </span>
                {
                    /*
                    filter === 'HOMEWEAR' && <span className={'web-only-label'}>WEB ONLY</span>
                    */
                }
            </li>)}
        </ul>
    </aside>
}

export default SeasonArchiveFilters
