import React from 'react'
import moment from 'moment'
import { useTranslation } from "react-i18next";

import './DateRanges.scss'

const calculateDate = (type) => {
    let startDate = moment().toDate()
    let endDate = moment().toDate()

    switch (type) {
        case 'current_year': {
            startDate = moment().startOf('year').toDate();
            endDate = moment().toDate();
            break
        }
        case 'current_month': {
            startDate = moment().startOf('month').toDate();
            endDate = moment().toDate();
            break
        }
        case 'today': {
            startDate = moment().subtract(0, 'days').startOf('day').toDate();
            endDate = moment().subtract(0, 'days').endOf('day').toDate();
            break
        }
        case 'previous_month': {
            startDate = moment().subtract(1, 'months').startOf('month').toDate();
            endDate = moment().subtract(1, 'months').endOf('month').toDate();
            break
        }
    }

    return {
        startDate,
        endDate
    }
}

const DateRanges = ({ setFromDate, setToDate, today }) => {
    const { t } = useTranslation();

    return <div className="date-ranges">
        <ul>
            <li onClick={() => {
                const { startDate, endDate } = calculateDate('current_year')

                if(setFromDate) {
                    setFromDate(startDate)
                }

                if(setToDate) {
                    setToDate(endDate)
                }

            }}>{t('Current Year')}</li>
            <li onClick={() => {
                const { startDate, endDate } = calculateDate('current_month')

                if(setFromDate) {
                    setFromDate(startDate)
                }

                if(setToDate) {
                    setToDate(endDate)
                }

            }}>{t('Current Month')}</li>
            <li onClick={() => {
                const { startDate, endDate } = calculateDate('previous_month')

                if(setFromDate) {
                    setFromDate(startDate)
                }

                if(setToDate) {
                    setToDate(endDate)
                }

            }}>{t('Previous Month')}</li>
            {
                today ?
                    <li onClick={() => {
                        const { startDate, endDate } = calculateDate('today')

                        if(setFromDate) {
                            setFromDate(startDate)
                        }

                        if(setToDate) {
                            setToDate(endDate)
                        }

                    }}>{t('Today')}</li>
                    : null
            }
        </ul>
    </div>
}

export default DateRanges