import Product from './Product'
import Auth from './Auth'
import Cart from './Cart'
import Customer from './Customer'
import Order from './Order'
import Notification from './Notification'
import Settings from './Settings'
import Season from './Season'
import Family from './Family'
import Wishlist from './Wishlist'
import Payment from './Payment'
import Salesman from './Salesman'

const {REACT_APP_SERVER_API_URL} = process.env

export default {
    product: new Product(REACT_APP_SERVER_API_URL),
    auth: new Auth(REACT_APP_SERVER_API_URL),
    cart: new Cart(REACT_APP_SERVER_API_URL),
    customer: new Customer(REACT_APP_SERVER_API_URL),
    order: new Order(REACT_APP_SERVER_API_URL),
    settings: new Settings(REACT_APP_SERVER_API_URL),
    notification: new Notification(REACT_APP_SERVER_API_URL),
    season: new Season(REACT_APP_SERVER_API_URL),
    family: new Family(REACT_APP_SERVER_API_URL),
    wishlist: new Wishlist(REACT_APP_SERVER_API_URL),
    payment: new Payment(REACT_APP_SERVER_API_URL),
    salesman: new Salesman(REACT_APP_SERVER_API_URL),
}
