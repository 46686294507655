import React from 'react'

const PanelTopControls = ({children}) => {
    return <aside className="panel-top-controls">
        {children}
    </aside>

}

export default PanelTopControls
