import { TYPES } from '../actions/customerActions'
import {TYPES as authTypes} from '../actions/authActions'

const initialState = {
    customers: false,
    activeCustomer: null,
    countries: [],
    customersWithTheSameAFM: []
};

const customer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.LOGOUT_CUSTOMER: {
            return {
                ...state,
                activeCustomer: initialState.activeCustomer
            }
        }
        case TYPES.UPDATE_EMAIL: {
            const customerUpdated = action.payload
            let activeCustomer = Object.assign({}, state.activeCustomer)

            let customersArray = state.customers.slice().map(customer => {

                if(state.activeCustomer._id === customerUpdated._id) {
                    activeCustomer = Object.assign({}, customerUpdated)
                }

                if(customer._id === customerUpdated._id) {
                    return customerUpdated
                }

                return customer
            })

            return {
                ...state,
                customers: customersArray,
                activeCustomer
            }
        }
        case TYPES.UPDATE_CUSTOMER_SEASON: {
            const customerUpdated = action.payload

            let customersArray = state.customers.slice().map(customer => {
                if(customer._id === customerUpdated._id) {
                    return customerUpdated
                }

                return customer

            })

            return {
                ...state,
                customers: customersArray,
            }
        }
        case TYPES.UPDATE_CUSTOMER: {
            //Update the active customer in order to update the render method
            const customerUpdated = action.payload
            let customersArray = state.customers.slice()

            customersArray = customersArray.filter((customer) => {
                return customer._id !== customerUpdated._id
            })

            customersArray.push(customerUpdated)

            return {
                ...state,
                customers: customersArray,
                activeCustomer: customerUpdated,
            }
        }
        case TYPES.UPDATE_SINGLE_CUSTOMER: {
            //Update the active customer in order to update the render method
            const customerUpdated = action.payload
            let customersArray = state.customers.slice()

            customersArray = customersArray.filter((customer) => {
                return customer._id !== customerUpdated._id
            })

            customersArray.push(customerUpdated)

            return {
                ...state,
                customers: customersArray,
            }
        }
        case TYPES.CREATE_CUSTOMER_ACCESS_ACTION: {
            //Update the active customer in order to update the render method
            const customerUpdated = action.payload
            const customersArray = state.customers

            let newCustomersArray = customersArray.filter((customer) => {
                return JSON.stringify(customer._id) !== JSON.stringify(customerUpdated._id)
            })

            newCustomersArray.push(customerUpdated)

            return {
                ...state,
                customers: newCustomersArray,
            }
        }
        case TYPES.DEFAULT_ADDRESS_SET: {
            //Update the active customer in order to update the render method
            const customerUpdated = action.payload
            const customersArray = state.customers

            let newCustomersArray = customersArray.filter((customer) => {
                return customer._id !== customerUpdated._id
            })

            newCustomersArray.push(customerUpdated)

            return {
                ...state,
                customers: newCustomersArray,
                activeCustomer: customerUpdated,
            }
        }
        case TYPES.CLEAR_CUSTOMER_AFM_EXISTS: {
            return {
                ...state,
                customersWithTheSameAFM: []
            }
        }
        case TYPES.CUSTOMER_AFM_EXISTS: {
            return {
                ...state,
                customersWithTheSameAFM: action.payload
            }
        }
        case TYPES.CLEAR_CUSTOMER_ORDERS: {
            const activeCustomer = Object.assign({}, state.activeCustomer)
            activeCustomer.orders = [];

            return {
                ...state,
                activeCustomer
            }
        }
        case TYPES.FETCH_CUSTOMER_ORDERS: {
            const activeCustomer = Object.assign({}, state.activeCustomer)
            activeCustomer.orders = action.payload;

            return {
                ...state,
                activeCustomer
            }
        }
        case TYPES.CREATED_CUSTOMER: {
            return {
                ...state,
                customers: [
                    ...state.customers,
                    action.payload
                ]
            }
        }
        case TYPES.GET_COUNTRIES: {
            return {
                ...state,
                countries: action.payload
            }
        }
        case TYPES.SELECT_CUSTOMER: {

            let customerToSelect = state.customers.filter((customer) => customer._id === action.payload)[0]

            return {
                ...state,
                activeCustomer: customerToSelect,
            }
        }
        case TYPES.SELECT_CUSTOMER_FROM_SESSION: {

            // vamp_salesapp_active_customer
            const selectedCustomerId = action.payload;
            let activeCustomer = initialState.activeCustomer

            //Scan customers for the selected id
            for(let i = 0; i < state.customers.length; i++) {
                if(state.customers[i]._id === selectedCustomerId) {
                    activeCustomer = state.customers[i]
                    break;
                }
            }

            // if(!activeCustomer) {
            //     localStorage.removeItem('vamp_salesapp_active_customer')
            // }

            return {
                ...state,
                activeCustomer,
            }
        }
        case TYPES.FETCH_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                customers: action.payload
            }
        }
        case authTypes.LOGOUT_ACTION: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state
    }
};

export default customer
