import React, {Component} from 'react'
import ProductItem from './ProductItem'
import ProductFilters from "./ProductFilters";
import BottomBar from "../Common/BottomBar";
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import {ReactComponent as NextSvg} from "../assets/angle-right.svg";
import {ReactComponent as PrevSvg} from "../assets/angle-left.svg";


import './ProductsList.scss'

import {getProductPrices} from "../../utils/priceUtils";
import Loader from "../Common/Loader";

const ProductPagination = ({productsLength, page, postsPerPage, handlePageChange, t}) => {
    const paginationCount = Math.ceil(productsLength / postsPerPage)
    const paginationArray = []

    const paginationDisplayOffset = 5;

    for (let i = 0; i < paginationCount; i++) {
        paginationArray.push(i + 1)
    }

    return <div className="product-pagination-container">
        {page > 1 ? <div className="paginate-prev-page" onClick={() => handlePageChange(parseInt(page) - 1)}>
            <PrevSvg />
            <span>{t('Previous')}</span>
        </div> : null}
        {/*<select className="product-list-pagination" defaultValue={parseInt(page)} onChange={(e) => {*/}
        {/*    handlePageChange(parseInt(e.target.value))*/}
        {/*}}>*/}
        {/*    {paginationArray.map((i) =><option*/}
        {/*        key={`page-key-${i}`}*/}
        {/*        value={i}*/}
        {/*    >{i}*/}
        {/*    </option>)}*/}
        {/*</select>*/}

        <ul className="product-list-pagination">
            {paginationArray.map((i) => {
                const counter = parseInt(i);
                const activePage = parseInt(page);

                if(counter < (activePage - paginationDisplayOffset)) {
                    return null
                }

                if(counter > (activePage + paginationDisplayOffset)) {
                    return null
                }

                return <li className={`${parseInt(page) === (i) ? 'active' : ''}`} onClick={() => {
                    handlePageChange(parseInt(i))
                }} key={`page-key-${i}`}>
                    {i}
                </li>
            })}
        </ul>

        {page < paginationCount ? <div className="paginate-next-page" onClick={() => handlePageChange(parseInt(page) + 1)}>
            <span>{t('Next')}</span>
            <NextSvg />
        </div> : null}
    </div>
}

class ProductsList extends Component {
    masterElement = null

    constructor(props) {
        super(props);

        const {activeFiltersFromParams} = this.props

        this.cookies = new Cookies();
        const itemsPerLine = this.cookies.get('vamp_app_items_per_line') || 4

        this.state = {
            activeFilters: activeFiltersFromParams,
            fetchProducts: false,
            filters: {},
            inputTerm: '',
            itemsPerLine: itemsPerLine,
            priceValues: []
        }
    }

    componentDidMount() {
        const {entries, filters, priceRange, page, sort} = this.props

        const newStateObj = {
            products: entries,
            filters: filters,
            page: page,
            sort: sort,
            // minPrice: priceRange.minPrice,
            // maxPrice: priceRange.maxPrice,
        }

        this.setState(newStateObj)

        const script = document.createElement("script");
        script.src = "https://interactive-img.com/js/include.js";
        script.async = true;
        document.body.appendChild(script);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            entries,
            filters,
            priceRange,
            fetchProductsAction,
            activeFiltersFromParams,
            page,
            sort,
        } = this.props

        const {
            activeFilters,
            fetchProducts,
            inputTerm
        } = this.state

        if (
            (!prevProps.entries.length && entries.length)
        ) {
            const newStateObj = {
                filters: filters,
                minPrice: priceRange ? priceRange.minPrice : 0,
                maxPrice: priceRange ? priceRange.maxPrice : 0,
            }
            this.setState(newStateObj)
        }

        //Get products on new filters
        if (fetchProducts) {
            fetchProductsAction(activeFilters, page, inputTerm, sort)
            this.setState({
                fetchProducts: false,
                fetchingProducts: true
            })
        }

        const normalizedFiltersFromParams = {};
        Object.keys(activeFiltersFromParams).filter(key => {
            normalizedFiltersFromParams[key] = Array.from(activeFiltersFromParams[key])
        })

        const normalizedActiveFilters = {};
        Object.keys(activeFilters).filter(key => {
            normalizedActiveFilters[key] = Array.from(activeFilters[key])
        })

        //Update params filters
        if(JSON.stringify(normalizedFiltersFromParams) !== JSON.stringify(normalizedActiveFilters)) {
            this.setState({
                activeFilters: activeFiltersFromParams
            })
        }

        const script = document.createElement("script");
        script.src = "https://interactive-img.com/js/include.js";
        script.async = true;
        document.body.appendChild(script);
    }

    printPaginatedProducts(products, page, postsPerPage) {

        const {sortBySeason} = this.props

        const start = (page - 1) * postsPerPage
        const end = (page * postsPerPage)

        if (sortBySeason) {
            let nosProducts = []
            let seasonProducts = []

            for (let i = 0; i < products.length; i++) {
                if (products[i].season.cType === 'NOS') {
                    nosProducts.push(products[i])
                    continue;
                }

                seasonProducts.push(products[i])
            }

            nosProducts = nosProducts.sort((a, b) => a.code - b.code)
            seasonProducts = seasonProducts.sort((a, b) => a.code - b.code)
            return seasonProducts.concat(nosProducts).slice(start, end)
        } else {
            return products.sort((a, b) => a.code - b.code).slice(start, end)
        }
    }

    clearFilters() {
        const {history} = this.props

        history.push({
            pathname: history.location.pathname,
        })

        this.setState({
            activeFilters: {},
            fetchProducts: true,
            page: 1
        })
    }

    toggleFilter(key, value) {
        const {page} = this.state
        const {history} = this.props
        let newFilters = Object.assign({}, this.state.activeFilters)

        if (!newFilters[key]) {
            newFilters[key] = new Set()
        }

        if (newFilters[key].has(value)) {
            newFilters[key].delete(value)
        } else {
            newFilters[key].add(value)
        }

        //Convert filters to array
        let newFiltersNormalized = {}
        Object.keys(newFilters).filter(key => {
            newFiltersNormalized[key] = Array.from(newFilters[key])
        })

        history.push({
            pathname: history.location.pathname,
            search: `?page=${page}&filters=${JSON.stringify(newFiltersNormalized)}`
        })

        this.setState({
            activeFilters: newFilters,
            fetchProducts: true,
            page: 1
        })
    }

    getActiveBannerId(activeBannerId){
        let bannerIdTemp = ''
        switch(activeBannerId) {
            case '659f2271e9ec8db3f4067e10':
            case '659f2271e9ec8db3f4067e1b':
            case '659f2271e9ec8db3f4067e19':
            case '659f2271e9ec8db3f4067e0f':
                bannerIdTemp = 56903
                break
            case '659f2271e9ec8db3f4067e27':
                bannerIdTemp = 56905
                break
            case '659f2271e9ec8db3f4067e0d':
                bannerIdTemp = 56909
                break
            case '659f2271e9ec8db3f4067e0c':
                bannerIdTemp = 56904
                break
            case '659f2271e9ec8db3f4067e1d':
            case '659f2271e9ec8db3f4067e1c':
                bannerIdTemp = 56906
                break
            case '659f2271e9ec8db3f4067e15':
            case '659f2271e9ec8db3f4067e25':
                bannerIdTemp = 56900
                break
            case '659f2271e9ec8db3f4067e24':
            case '659f2271e9ec8db3f4067e23':
            case '659f2271e9ec8db3f4067e20':
                bannerIdTemp = 56902
                break
            case '659f2271e9ec8db3f4067e1e':
            case '659f2271e9ec8db3f4067e2f':
            case '659f2271e9ec8db3f4067e2e':
            case '659f2271e9ec8db3f4067e2c':
                bannerIdTemp = 56901
                break
            case '659f2271e9ec8db3f4067e26':
                bannerIdTemp = 56907
                break
            case '659f2272e9ec8db3f4067e41':
            case '659f2272e9ec8db3f4067e35':
                bannerIdTemp = 56908
                break
        }
        return bannerIdTemp
    }


    scrollToTop = () => window.scrollTo(0, (this.masterElement.offsetTop - 100))

    render() {
        const { t } = this.props;
        const {
            entries,
            livePrices,
            extraMeta,
            history,
            showFilters,
            postsPerPage,
            wishlistItems,
            wishlistToggle,
            variationPrices,
            currencySymbol,
            showBottomNavigation,
            bottomNavigationPrev,
            bottomNavigationNext,
            hideSearch,
            minPrice,
            maxPrice,
            fetchingProducts,
            customerRole,
            productsCount,
            changePage,
            changeSort,
            page,
            sort,
            customerHasLocalPrices,
            preorder,
            wishlist,
            prevFamily,
            nextFamily,
            disabledBuy,
            comingSoonProducts,
            activeUser,
        } = this.props
        const {
            inputTerm,
            itemsPerLine,
            activeFilters,
            filters,
        } = this.state

        const urlParams = new URLSearchParams(window.location.search);
        const factoryParam = urlParams.get('factory');

        return <div className="products-list-component">
            {!entries.length ? (
                    fetchingProducts ? <Loader/> :
                        <h3 className="no-products-found-msg">{t('No products matching your search')}</h3>
                )
                : <div ref={(ref) => this.masterElement = ref} className="products-list-container">
                    <div className="products-list-top-section">
                        {showFilters ?
                            <div className="sort-by-section">
                                <span>{t('Sort by')}</span>
                                <select value={sort} name="" id="" onChange={(e) => {
                                    const sortSelection = e.target.value
                                    changeSort(sortSelection)
                                    this.scrollToTop()
                                    this.setState({
                                        fetchProducts: true
                                    })

                                    //Convert filters to set
                                    let filtersNormalized = {}

                                    Object.keys(activeFilters).filter(key => {
                                        filtersNormalized[key] = Array.from(activeFilters[key])
                                    })

                                    history.push({
                                        pathname: history.location.pathname,
                                        search: `?page=${page}&filters=${JSON.stringify(filtersNormalized)}&sort=${sortSelection}`  + ( factoryParam === 'Simone' ? '&factory=Simone' : '' )
                                    })
                                }}>
                                    <option value="sku">{t('Code')}</option>
                                    <option value="default">{t('Latest Season')}</option>
                                    <option value="latest">{t('Latest Offers')}</option>
                                    { activeUser.b2bView === 'CZ' ? '' : <option value="finalPrice">{t('Price Low To High')}</option> }
                                </select>
                            </div>
                            :
                            null
                        }
                    </div>
                    <ul className={`product-list items-per-line-${itemsPerLine}`}>{entries.map((entry) => {
                            const {price, regularPrice, livePriceMatched} = getProductPrices(entry, livePrices, customerHasLocalPrices)
                            let showLoader = false

                            if (
                                customerRole === 'customer' &&
                                !entry.markedAsPreorder
                            ) {
                                if (!livePriceMatched) {
                                    showLoader = true
                                }
                            }

                            return <li
                                key={entry._id}>
                                    <ProductItem
                                        disabledBuy={disabledBuy}
                                        comingSoonProducts={comingSoonProducts}
                                        preorder={preorder}
                                        wishlist={wishlist}
                                        // handleClick={() => history.push(`/app/product/${entry._id}`)}
                                        extraMeta={extraMeta}
                                        variationPrices={variationPrices}
                                        currencySymbol={currencySymbol}
                                        price={price}
                                        regularPrice={regularPrice}
                                        showLoader={showLoader}
                                        isInWishlist={wishlistItems.includes(entry._id)}
                                        wishlistToggle={() => wishlistToggle(entry._id, 'product')}
                                        itemsPerLine={itemsPerLine}
                                        item={entry}/>
                            </li>
                        }
                    )}</ul>
                    {showFilters && postsPerPage < productsCount ?
                        <ProductPagination
                            productsLength={productsCount}
                            page={page}
                            postsPerPage={postsPerPage}
                            handlePageChange={page => {
                                const urlParamsTemp = new URLSearchParams(window.location.search);
                                const factoryType = urlParamsTemp.get('factory');
                                changePage(page)
                                this.scrollToTop()
                                this.setState({
                                    fetchProducts: true
                                })

                                //Convert filters to set
                                let filtersNormalized = {}

                                Object.keys(activeFilters).filter(key => {
                                    filtersNormalized[key] = Array.from(activeFilters[key])
                                })

                                history.push({
                                    pathname: history.location.pathname,
                                    search: `?page=${page}&filters=${JSON.stringify(filtersNormalized)}${factoryType === 'Simone' ? '&factory=Simone' : ''}`
                                })
                            }}
                            t={t}
                        />
                        :
                        null }
                </div>
            }
            { window.location.pathname.split('/').length > 4 && window.location.pathname.split('/')[4] && this.getActiveBannerId(window.location.pathname.split('/')[4]) && <div className="iactiveImg" data-ii={window.location.pathname.split('/').length > 4 && window.location.pathname.split('/')[4] && this.getActiveBannerId(window.location.pathname.split('/')[4]) }></div> }
            <BottomBar>
                {(showFilters) ? <ProductFilters
                    hideSearch={hideSearch}
                    filters={filters}
                    productsCount={productsCount}
                    activeFilters={activeFilters}
                    inputTerm={inputTerm}
                    handleInputChange={value => {

                        const urlParams = new URLSearchParams(window.location.search);
                        const myParam = urlParams.get('factory');
                        history.push({
                            pathname: history.location.pathname,
                            search: `?page=1&filters={}` + ( myParam === 'Simone' ? '&factory=Simone' : '' )
                        })

                        this.setState({
                            inputTerm: value,
                            page: 1,
                            activeFilters: {},
                            fetchProducts: true
                        })
                    }}
                    setViewPerLine={itemsPerLine => {
                        this.cookies.set('vamp_app_items_per_line', itemsPerLine, {path: '/'});
                        this.setState({itemsPerLine})
                    }}
                    viewPerLine={itemsPerLine}
                    clearFilters={() => this.clearFilters()}
                    toggleFilter={(key, value) => this.toggleFilter(key, value)}
                    handlePriceFilter={(values) => {
                        this.setState({priceValues: values})
                    }}
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    currencySymbol={currencySymbol}
                    bottomNavigation={showBottomNavigation}
                    prevFamily={prevFamily}
                    nextFamily={nextFamily}
                    navigationPrev={() => {
                        this.scrollToTop()
                        bottomNavigationPrev()
                    }}
                    navigationNext={() => {
                        this.scrollToTop()
                        bottomNavigationNext()
                    }}
                /> : null}
            </BottomBar>
        </div>
    }
}

export default withTranslation()(ProductsList)
