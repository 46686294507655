import React from 'react'

import './Button.scss'

const Button = ({title, callback, className, children}) => {
    return <div className={`vamp-button ${className || ''}`} onClick={callback}>
        <span>{title}</span>
        {children || null}
    </div>
}

export default Button
