import React from 'react'

import './FormElement.scss'

const FormElement = ({ children, label, required, className }) => {
    return <div className={`${className || ''} form-element ${required ? 'required' : ''}`}>
        {label ? <label>{label}</label> : null}
        {children}
    </div>
}

export default FormElement
