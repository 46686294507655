import axios from "axios";

class Payment {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async getSeasonCompare(mainSeason, prevSeason, type) {
        let response = await axios.post(`${this.apiUrl}/salesman/info/seasonCompare`, {
            mainSeason,
            prevSeason,
            type,
        })
        return response.data.payload
    }

    async getTurnovers(fromDate, toDate) {
        let response = await axios.post(`${this.apiUrl}/salesman/info/turnovers`, {
            fromDate,
            toDate,
        })
        return response.data.payload
    }
}

export default Payment
