import { TYPES } from '../actions/orderActions'
import {TYPES as authTypes} from '../actions/authActions'

const initialState = {
    activeSavedOrderId: null,
    editMode: false
};

const order = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.LOAD_SAVED_ORDER: {
            return {
                ...state,
                activeSavedOrderId: action.payload,
                editMode: action.editMode || false
            }
        }
        case TYPES.CLEAR_SAVED_ORDER: {
            return {
                ...state,
                activeSavedOrderId: null,
                editMode: false,
            }
        }
        case authTypes.LOGOUT_ACTION: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state
    }
};

export default order
