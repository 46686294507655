import React from 'react'
import { ReactComponent as CustomerSvg } from '../assets/customer.svg';
import './CustomerListItem.scss'
import { useTranslation } from "react-i18next";

const CustomerListItem = ({customer, onClick, accessData}) => {
    const { t } = useTranslation();
    return <li onClick={onClick} className="customer-list-item">
        <div className="customer-item-box">
            <CustomerSvg />
            <div className="customer-name">
                {customer.name}
            </div>
            <div className="customer-email">
                {customer.email}
            </div>
            <div className="customer-city">
                {customer.customer[0].billingAddress.city}
            </div>
            <div className="customer-alt-code">
                {customer.customer[0].altCode}
            </div>
            <div className="select-button">
                <span>{ customer.customer[0].potential ? t('Select Customer') : t('Select Customer') }</span>
            </div>
        </div>
    </li>
}

export default CustomerListItem
