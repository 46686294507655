import React from 'react'
import {useTable, useSortBy, usePagination, useFilters} from 'react-table'

import './InfoTable.scss'
import { Input, CustomInput } from "reactstrap"
import { useTranslation } from "react-i18next";

export const DefaultColumnFilter = ({
                                        column: {
                                            filterValue,
                                            setFilter,
                                            preFilteredRows: { length },
                                        },
                                    }) => {
    return (
        <Input
            className="table-input-filter"
            value={filterValue || ""}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        />
    )
}

const InfoTable = ({columns, data}) => {
    const { t } = useTranslation();
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        footerGroups,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0},
        },
        useFilters,
        useSortBy,
        usePagination,
    )

    // Render the UI for your table
    return (
        <div className="info-table">
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                  //           <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  //               {column.render('Header')}
                  //               {/* Add a sort direction indicator */}
                  //               <span>
                  //   {column.isSorted
                  //       ? column.isSortedDesc
                  //           ? ' 🔽'
                  //           : ' 🔼'
                  //       : ''}
                  // </span>
                  //           </th>

                            <th {...column.getHeaderProps()}>
                                <div {...column.getSortByToggleProps()}>
                                    {column.render("Header")}
                                </div>
                                <div>{column.canFilter && !column.skipFilter ? <DefaultColumnFilter column={column}/> : null}</div>
                            </th>

                        ))}
                    </tr>
                ))}
                </thead>
                {/*<tbody {...getTableBodyProps()}>*/}
                {/*{rows.map((row, i) => {*/}
                {/*    prepareRow(row)*/}
                {/*    return (*/}
                {/*        <tr {...row.getRowProps()}>*/}
                {/*            {row.cells.map(cell => {*/}
                {/*                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>*/}
                {/*            })}*/}
                {/*        </tr>*/}
                {/*    )*/}
                {/*})}*/}
                {/*</tbody>*/}
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
                <tfoot>
                {footerGroups.map(group => (
                    <tr {...group.getFooterGroupProps()}>
                        {group.headers.map(column => (
                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                        ))}
                    </tr>
                ))}
                </tfoot>
            </table>
            {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                {' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                {' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                {' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>
                {' '}
                <span>
          Page{' '}
                    <strong>
            {pageIndex + 1} {t('of')} {pageOptions.length}
          </strong>{' '}
        </span>
                <span>
          | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{width: '100px'}}
                    />
        </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {t('Show')} {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export default InfoTable