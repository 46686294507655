import Api from "../controllers/Api";

import { selectCustomerAction } from './customerActions'
import { clearCartAction } from './cartActions'

export const TYPES = {
    LOAD_SAVED_ORDER: 'LOAD_SAVED_ORDER',
    CLEAR_SAVED_ORDER: 'CLEAR_SAVED_ORDER',
    GET_CUSTOMER_ORDERS: 'GET_CUSTOMER_ORDERS',
    LOAD_ORDER_FOR_EDIT: 'LOAD_ORDER_FOR_EDIT',
}

export const editOrderAction = (findoc) => {
    return async (dispatch, getState) => {
        const refreshedOrder = await Api.order.refreshOrder(findoc)

        if(refreshedOrder && refreshedOrder.orderId) {
            dispatch(loadOrderAction(refreshedOrder.orderId, true))
        }
    }
}

export const loadOrderAction = (orderId, editMode = false) => {
    return async (dispatch, getState) => {

        //Send an api request to create a cart, returns customer id to select and created / updated cart id
        const { customerId } = await Api.cart.getCartByOrderId(orderId)

        dispatch(selectCustomerAction(customerId))
        dispatch({
            type: TYPES.LOAD_SAVED_ORDER,
            payload: orderId,
            editMode,
        })
    }
}

export const deleteOrderAction = (orderId) => {
    return async (dispatch, getState) => {
        await Api.order.delete(orderId)
        dispatch(clearSavedOrder())
    }
}

export const clearSavedOrder = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.CLEAR_SAVED_ORDER,
        })
    }
}
