import React from 'react'

import './CheckoutFormSection.scss'

const CheckoutFormSection = ({ sectionNumber, sectionTitle, sectionClass, children }) => {
    return <div className={`checkout-section ${sectionClass}`}>
        <header className="checkout-section-header">
            <span className="section-number">
                {sectionNumber}
            </span>
            <span className="section-title">
                {sectionTitle}
            </span>
        </header>
        <div className="checkout-section-content">
            {children}
        </div>
    </div>
}

export default CheckoutFormSection