import { combineReducers } from 'redux'

import homepageSettings from "./homepageSettingsReducer";
import product from "./productReducer";
import quickView from "./quickViewReducer";
import cart from "./cartReducer";
import auth from "./authReducer";
import customer from "./customerReducer";
import checkout from "./checkoutReducer";
import order from "./orderReducer";
import settings from "./settingsReducer";
import headerSettings from "./headerSettingsReducer";
import season from "./seasonReducer";
import wishlist from "./wishlistReducer";
import notice from "./noticeReducer";
import general from "./generalReducer";
// import family from "./familyReducer";

export default combineReducers({
    homepageSettings,
    product,
    quickView,
    cart,
    auth,
    customer,
    checkout,
    order,
    settings,
    headerSettings,
    season,
    wishlist,
    notice,
    general,
    // family,
})
