import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'

import {ReactComponent as Logo} from './assets/logo.svg';
import {ReactComponent as CustomerSvg} from './assets/customer.svg';
import {ReactComponent as AgentSvg} from './assets/agent.svg';
import {ReactComponent as AdvertisingSvg} from './assets/costume-clothes.svg';
import {ReactComponent as CartSvg} from './assets/cart.svg';
import {ReactComponent as ClothesSvg} from './assets/clothes.svg';
import {ReactComponent as HeartSvg} from './assets/heart.svg';
import {ReactComponent as BurgerSvg} from './assets/burger.svg';
import {ReactComponent as CloseSvg} from './assets/close.svg';
import {ReactComponent as CampaignSvg} from './assets/campaign.svg';
import {ReactComponent as CatalogSvg} from './assets/catalog.svg';
import {ReactComponent as LogoutSvg} from './assets/logout.svg';
import {ReactComponent as BuyNowSvg} from './assets/buy-now.svg';

import {useWindowDimensions} from '../utils/hooks'

import Button from "./Button";
import settings from "../reducers/settingsReducer";

import './Header.scss';
import {useTranslation} from "react-i18next";

const CampaignLink = ({toggleMenuOpen, history}) => {
    const { t } = useTranslation();
    return <div className="header-item" onClick={() => {
        toggleMenuOpen(false)
        history.push('/app/campaign')
    }}>
        <CampaignSvg/>
        <span><span>{t('Campaign')}</span></span>
    </div>
}

const PresentationLink = ({toggleMenuOpen, history}) => {
    return <div className="header-item" onClick={() => {
        toggleMenuOpen(false)
        history.push('/app/presentation')
    }}>
        <CatalogSvg/>
        <span>Collections</span>
    </div>
}

const ArchiveLink = ({toggleMenuOpen, history, isNewProductExist}) => {
    const { t } = useTranslation();
    return <div className="header-item cart-icon" onClick={() => {
        toggleMenuOpen(false)
        window.location.href = '/app/archive'
    }}>
        <BuyNowSvg/>
        {isNewProductExist ? <span className="counter new-product-label">NEW</span> : null}
        <span>{t('Buy Now')}</span>
    </div>
}

const AdminLink = ({toggleMenuOpen}) => {
    return <div className="header-item header-customer-container">
        <Link to={`/app/admin`} onClick={() => toggleMenuOpen(false)}>
            <CustomerSvg/>
            <span>Admin</span>
        </Link>
    </div>
}

const SamplesLink = ({settings, history, cartCounter, toggleMenuOpen}) => {
    const { t } = useTranslation();
    if (!settings || !settings.samples.enabled) {
        return null
    }

    return <div className="header-item cart-icon" onClick={() => {
        toggleMenuOpen(false)
        history.push('/app/samples')
    }}>
        <AdvertisingSvg/>
        {cartCounter ? <span className="counter sample-cart">{cartCounter}</span> : null}
        <span>{t('Samples Bazaar')}</span>
    </div>
}

const CustomerLink = ({activeCustomer, toggleMenuOpen}) => {
    if (!activeCustomer || !activeCustomer._id) {
        return null
    }

    return <div className="header-item header-customer-container">
        <Link to={`/app/customer/account`} onClick={() => {
            toggleMenuOpen(false)
        }}>
            <CustomerSvg/>
            <span>{activeCustomer.name}</span>
        </Link>
    </div>
}

const UserLink = ({activeUser, navigateToUserPanel, toggleMenuOpen}) => {
    if (!activeUser || !activeUser.id) {
        return null
    }

    return <div className="header-item header-customer-container" onClick={() => {
        toggleMenuOpen(false)
        navigateToUserPanel()
    }}>
        <AgentSvg/>
        <span>{activeUser.name}</span>
    </div>
}

const WishlistLink = ({history, toggleMenuOpen, activeCustomer, activeUser, allowedCountries}) => {
    const { t } = useTranslation();
    // activeUser role
    if (!activeCustomer) {
        return null;
    }

    let canPreorder = true;
    let isInAllowedCountries = true;

    //detect if customer can preorder
    if (activeUser && activeUser.role === 'customer') {
        if (
            activeCustomer &&
            activeCustomer.customer &&
            activeCustomer.customer.length &&
            activeCustomer.customer[0] &&
            !activeCustomer.customer[0].canPreorder
        ) {
            canPreorder = false;
        }

        if (activeCustomer.customer[0].billingAddress && activeCustomer.customer[0].billingAddress.softoneCountryCode) {
            const customerCountryCode = activeCustomer.customer[0].billingAddress.softoneCountryCode;

            if (!allowedCountries.includes(customerCountryCode.toString())) {
                isInAllowedCountries = false;
            }
        }
    }

    if(!(isInAllowedCountries || canPreorder)) {
        return null;
    }

    return <div className="header-item wishlist-icon" onClick={() => {
        toggleMenuOpen(false)
        history.push('/app/wishlist')
    }}>
        <HeartSvg/>
        <span>{t('Wishlist')}</span>
    </div>
}

const CartLink = ({history, cartCounter, toggleMenuOpen}) => {
    const { t } = useTranslation();
    return <div className="header-item cart-icon" onClick={() => {
        toggleMenuOpen(false)
        history.push('/app/cart')
    }}>
        <CartSvg/>
        {cartCounter ? <span className="counter">{cartCounter}</span> : null}
        <span>{t('Cart')}</span>
    </div>
}

const LogoutLink = ({logoutAction}) => {
    const { t } = useTranslation();
    return <div className="header-item header-customer-logout">
        <span className="logout-button" title="logout" onClick={logoutAction}>
            <span>{t('Logout')}</span>
            <LogoutSvg/>
        </span>
    </div>
}

const printMenuItems = (linksMap, activeUser, type = 'all') => {
    return Object.values(linksMap).map(item => {

        if (item.canPreorder !== undefined && !item.canPreorder) {
            return null
        }

        if (type === 'notMobile' && item.mobileMenu) {
            return null
        }

        if (type === 'mobileMenu' && !item.mobileMenu) {
            return null
        }

        if (item.access === 'all' || activeUser.role === 'admin') {
            return item.component
        }

        if (!item.access.includes(activeUser.role)) {
            return null
        }


        return item.component
    })
}

function useOutsideAlerter(ref, toggleMobileMenu) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleMobileMenu(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const Header = ({
                    activeCustomer,
                    activeUser,
                    history,
                    logoutAction,
                    navigateToUserPanel,
                    settings,
                    headerSettings,
                    cartCounter,
                    cartCounterSample,
                    canPreorder,
                    isNewProductExist = false
                }) => {
    const [menuOpen, toggleMenuOpen] = useState(false)
    const {height, width} = useWindowDimensions();
    const {preorderAllowedCountries} = settings;

    const {header_banner_image, header_banner_url, header_banner_image_cz, header_banner_url_cz} = headerSettings;

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, toggleMenuOpen);

    const linksMap = {

        // campaign: {
        //     component: <CampaignLink
        //         key={2}
        //         settings={settings}
        //         history={history}
        //         toggleMenuOpen={toggleMenuOpen}
        //     />,
        //     access: 'all',
        //     mobileMenu: true,
        // },
        presentation: {
            component: <PresentationLink
                key={1}
                settings={settings}
                history={history}
                toggleMenuOpen={toggleMenuOpen}
            />,
            access: 'all',
            canPreorder,
            mobileMenu: true,
        },
        archive: {
            component: <ArchiveLink
                key={2}
                settings={settings}
                history={history}
                toggleMenuOpen={toggleMenuOpen}
                isNewProductExist={isNewProductExist}
            />,
            access: 'all',
            mobileMenu: true,
        },
        admin: {
            component: <AdminLink
                key={4}
                toggleMenuOpen={toggleMenuOpen}
            />,
            access: 'admin',
            mobileMenu: true,
        },
        samples: {
            component: <SamplesLink
                key={5}
                settings={settings}
                cartCounter={cartCounterSample}
                history={history}
                activeUser={activeUser}
                toggleMenuOpen={toggleMenuOpen}
            />,
            access: ['salesman', 'customer'],
            mobileMenu: true,
        },
        customer: {
            component: <CustomerLink
                key={6}
                settings={settings}
                history={history}
                activeCustomer={activeCustomer}
                toggleMenuOpen={toggleMenuOpen}
            />,
            access: ['salesman', 'customer'],
            mobileMenu: true,
        },
        user: {
            component: <UserLink
                key={7}
                activeUser={activeUser}
                navigateToUserPanel={navigateToUserPanel}
                toggleMenuOpen={toggleMenuOpen}
            />,
            access: 'salesman',
            mobileMenu: true,
        },
        wishlist: {
            component: <WishlistLink
                key={8}
                history={history}
                activeUser={activeUser}
                activeCustomer={activeCustomer}
                toggleMenuOpen={toggleMenuOpen}
                allowedCountries={preorderAllowedCountries}
            />,
            access: 'all',
            mobileMenu: true,
        },
        cart: {
            component: <CartLink
                key={9}
                history={history}
                cartCounter={cartCounter}
                toggleMenuOpen={toggleMenuOpen}
            />,
            access: 'all'
        },
        logout: {
            component: <LogoutLink
                key={10}
                logoutAction={logoutAction}
                toggleMenuOpen={toggleMenuOpen}
            />,
            access: 'all',
            mobileMenu: true,
        }
    }

    const pathName = window.location.pathname
    const urlParams = new URLSearchParams(window.location.search);
    const factoryParam = urlParams.get('factory');

    return <header className="application-header">
        <div className="left-header-container">
            <Logo onClick={() => history.push('/app')}/>
            {
                activeUser.b2bView === "GR" ?
                    header_banner_image && header_banner_image !== 'false' &&
                    <div className={'secondary-banner'}>
                        {
                            header_banner_url && header_banner_url !== 'false' ?
                                <a href={header_banner_url} target={'_blank'}>
                                    <img src={header_banner_image} alt="banner image"/>
                                </a>
                                :
                                <img src={header_banner_image} alt="banner image"/>
                        }
                    </div>
                    :
                    header_banner_image_cz && header_banner_image_cz !== 'false' &&
                    <div className={'secondary-banner'}>
                        {
                            header_banner_url_cz && header_banner_url_cz !== 'false' ?
                                <a href={header_banner_url_cz} target={'_blank'}>
                                    <img src={header_banner_image_cz} alt="banner image"/>
                                </a>
                                :
                                <img src={header_banner_image_cz} alt="banner image"/>
                        }
                    </div>
            }
        </div>
        <div className="right-header-container">
            {width > 1100 ?
                <div className="right-header-inner">
                    {printMenuItems(linksMap, activeUser, 'all')}
                </div>
                :
                <div className="right-header-inner">
                    {printMenuItems(linksMap, activeUser, 'notMobile')}
                    <div className="header-item burger-menu" onClick={() => toggleMenuOpen(!menuOpen)}>
                        <BurgerSvg/>
                        <span>Menu</span>
                    </div>
                    <div ref={wrapperRef} className={`header-burger-menu ${menuOpen ? 'burger-menu-open' : ''}`}>
                        <div className="header-item burger-menu-close" onClick={() => toggleMenuOpen(false)}>
                            <CloseSvg/>
                        </div>
                        {printMenuItems(linksMap, activeUser, 'mobileMenu')}
                    </div>
                </div>
            }
        </div>
        {
            ((activeCustomer && activeCustomer.customer && activeCustomer.customer.length && activeCustomer.customer[0]
                    && activeCustomer.customer[0].billingAddress && activeCustomer.customer[0].billingAddress.softoneCountryCode === 1000) ||
                ( activeUser && activeUser.allAccess && activeUser.b2bView === 'GR' ) ||
                ( activeUser && activeUser.role === 'admin' && activeUser.b2bView === 'GR' ) ||
                ( activeUser && activeUser.role === 'salesman' && activeUser.softoneCountryCode === 1000 )
            ) && factoryParam !== 'Simone' && pathName === '/app/archive' &&
            <div onClick={() => (window.location.href = '/app/archive?factory=Simone')} className='simone-perele-link-header'>
                <img className="simone-logo-img-header" src="/simoneLogo.svg"/>
            </div>
        }
    </header>
}

export default Header
