import React from 'react'

import './Loader.scss'

const Loader = ({type}) => {

    if(type === 'ellipsis'){
        return <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    }

    return <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
}

export default Loader
