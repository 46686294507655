import React, {Component} from 'react'

import AddressList from './AddressList'
import PanelHeader from "./PanelHeader";
import InfoBox from "../Common/InfoBox";
import './AddressesPanel.scss'
import Button from "../Button";
import { withTranslation } from 'react-i18next';

class AddressesPanel extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        const {activeCustomer, setDefaultAddress} = this.props

        return <div className="customer-addresses-panel">
            <PanelHeader title={t('Account Information')}/>
            <div className="panel-body">
                <div className="panel-row">
                    <header>
                        {t('Billing Information')}
                    </header>
                    <div className="content">
                        <div className="billing-info">
                            <InfoBox label="Name" value={activeCustomer.name} />
                            <InfoBox label="Email" value={activeCustomer.email} />
                            <InfoBox label="Address" value={activeCustomer.customer[0].billingAddress.address} />
                            <InfoBox label="City" value={activeCustomer.customer[0].billingAddress.city} />
                            <InfoBox label="Zip" value={activeCustomer.customer[0].billingAddress.zip} />
                            <InfoBox label="Country" value={activeCustomer.customer[0].billingAddress.country} />
                            <InfoBox label="Phone" value={activeCustomer.customer[0].billingAddress.phone} />
                        </div>
                    </div>
                </div>
                <div className="panel-row">
                    <header>
                        {t('Shipping Information')}
                    </header>
                    <div className="content">
                        <div className="shipping-info">
                            <div className="shipping-panel-component">
                                <AddressList setDefaultAddress={setDefaultAddress} customerId={activeCustomer._id} addresses={activeCustomer.customer[0].shippingAddresses}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withTranslation()(AddressesPanel)
