import React, {Component} from 'react'
import {connect} from 'react-redux'

import {wishlistToggleAction} from '../../actions/wishlistActions'

import SeasonArchiveHeader from '../Season/Archive/SeasonArchiveHeader'
import SeasonArchiveFilters from '../Season/Archive/SeasonArchiveFilters'
import SeasonFamiliesArchive from '../Season/Archive/SeasonFamiliesArchive'
import ProductsList from '../Product/ProductsList'
import customer from "../../reducers/customerReducer";
import { withTranslation } from 'react-i18next';

class SeasonArchivePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeView: 'family',
            products: [],
            families: [],
        }
    }

    componentDidMount() {
        const {
            history,
            settings: {presentationSeasons},
            match: {params: {seasonId}},
        } = this.props

        if (!presentationSeasons || !presentationSeasons.includes(seasonId.toString())) {
            history.push('/app')
        }

        const {families, products} = this.findValidFamiliesAndProducts()

        //Get season products
        this.setState({
            products,
            families,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const {
            productsArray,
            seasonsArray
        } = this.props

        if (
            (
                !this.state.families ||
                !this.state.families.length ||
                !this.state.products ||
                !this.state.products.length
            )
            &&
            (
                productsArray &&
                productsArray.length &&
                seasonsArray.length
            )
        ) {
            const {families, products} = this.findValidFamiliesAndProducts()

            //Get season products
            this.setState({
                products,
                families,
            })
        }
    }

    findValidFamiliesAndProducts() {
        const {
            history,
            settings: {
                presentation: {seasons},
                excludedFamilies
            },
            match: {params: {seasonId}},
            productsArray,
            seasonsArray
        } = this.props

        let families = []

        //The requested season plus the nos 0000 season
        const validSeasons = [seasonId.toString(), '8']

        //Check for excluded families
        let excludedFamiliesArray = [];
        if (excludedFamilies) {
            excludedFamiliesArray = excludedFamilies.split(',')
        }

        // exclude families NOS 0000 season
        excludedFamiliesArray.push('01101','01201', '01700', '02551', '03100', '04000');

        //Get active season and families
        for (let i = 0; i < seasonsArray.length; i++) {
            if (seasonsArray[i].softoneId === seasonId.toString()) {
                families = seasonsArray[i].families.filter(family => {

                    if(!excludedFamiliesArray || !excludedFamiliesArray.length) {
                        return true;
                    }

                    return !excludedFamiliesArray.includes(family.softoneCode)
                })
                break;
            }
        }
        //Get collection data for families
        const products = productsArray.filter(product => {
            if (!validSeasons.includes(product.season.softoneId.toString()) || !product.productCollection) {
                return false
            }

            if(excludedFamiliesArray && excludedFamiliesArray.length) {
                if(excludedFamiliesArray.includes(product.family.softoneCode)) {
                    return false
                }
            }

            //Find family
            for (let i = 0; i < families.length; i++) {
                if (!families[i].collections) {
                    families[i].collections = new Set()
                }

                if (families[i]._id === product.family._id) {
                    families[i].collections.add(product.productCollection)
                    break
                }
            }

            return true;
        })

        return {
            families,
            products
        }
    }

    renderActiveView(view) {
        const {products, families} = this.state
        const {history} = this.props

        switch (view) {
            case 'category': {
                return <ProductsList
                    extraMeta={true}
                    showFilters={true}
                    entries={products}
                    history={history}
                    postsPerPage={36}
                />
                break;
            }
            case 'family': {
            }
            default: {
                return <div>An error has occurred</div>
            }
        }
    }

    render() {
        const { t } = this.props;
        const {
            history, match: {params: {seasonId}},
            wishlistToggleAction,
            wishlistItems,
            activeUser,
            activeCustomer,
        } = this.props

        const {families} = this.state

        //Calculate collections
        const collections = new Set()

        for (let i = 0; i < families.length; i++) {
            if (!families[i].collectionName || families[i].collectionName === 'NOS') {
                continue;
            }

            collections.add(families[i].collectionName)
        }

        // collections.add('Favorites')
        collections.add('ALL')

        //Check user role
        let customerTopProducts = false
        let salesmanTopProducts = false

        if (activeUser && activeUser.role !== 'customer') {
            salesmanTopProducts = true
        }

        if (activeCustomer || activeUser.role === 'customer') {
            customerTopProducts = true
        }

        return <div className="archive-page season-archive-page">
            <SeasonArchiveHeader
                backLabel={t('Catalogs')}
                backAction={() => {
                    history.push('/app/presentation')
                }}
                switchViewAction={() => {
                    history.push(`/app/presentation/season/${seasonId}/products`)
                }}
                activeView={t('Show ALL')}
                salesmanTopProducts={salesmanTopProducts}
                customerTopProducts={customerTopProducts}
                topProductsLink={() => {
                    history.push(`/app/presentation/season/${seasonId}/products/top`)
                }}
                topProductsLinkSalesman={() => {
                    history.push(`/app/presentation/season/${seasonId}/products/top/salesman`)
                }}
            />
            <SeasonFamiliesArchive
                families={families}
                collections={Array.from(collections)}
                wishlistToggle={wishlistToggleAction}
                wishlistItems={wishlistItems}
                history={history}
            />
        </div>
    }
}

const mapStateToProps = ({settings, product, season, wishlist, auth, customer}) => {
    return {
        settings: settings.settings,
        productsArray: product.products,
        seasonsArray: season.seasons,
        wishlistItems: wishlist.items,
        activeUser: auth.activeUser,
        activeCustomer: customer.activeCustomer,
    }
}

export default withTranslation()(connect(mapStateToProps, {wishlistToggleAction})(SeasonArchivePage))
