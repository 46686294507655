import React from 'react'

import { useTranslation } from "react-i18next";
import './MultiGridHeader.scss'

const MultiGridHeader = ({sizes, hidePrices}) => {
    const { t } = useTranslation();

    if(!sizes || !sizes.length) {
        return null
    }

    return <tr className="multigrid-header-row">
        <th className="table-size-color-label">
                <span className="size-th-label">{t('size')}</span>
                <span className="line"></span>
                <span className="color-th-label">{t('color')}</span>
        </th>
        {sizes.map((size, key) => <th key={key}><span>{size}</span></th>)}

        {!hidePrices ?
            <th className="table-total-label">
                <span>{t('Total')}</span>
            </th>
            : null}
        <th className="table-controls-label">
        </th>
    </tr>

}

export default MultiGridHeader
