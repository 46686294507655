import {mergeWithLivePrice} from "./priceUtils";

export const getCountByCollection = (productsByColor) => {

    let underwearCount = 0
    let collectionsCount = 0
    let hasPreorders = false

    productsByColor.filter(item => {

        if(item.markedAsPreorder) {
            hasPreorders = true
        }

        if(!item.variations || !item.variations.length) {
            return;
        }

        if(item.productCollection === 'UNDERWEAR') {
            item.variations.filter(variation => {
                if(variation.inputValue) {
                    underwearCount += parseInt(variation.inputValue)
                }
            })
            return
        }

        item.variations.filter(variation => {
            if(variation.inputValue) {
                collectionsCount += parseInt(variation.inputValue)
            }
        })
    })

    return {
        underwearCount,
        collectionsCount,
        hasPreorders,
    }
}

export const normalizeCartItemsByColor = (products, cartItems, variationPrices) => {
    //Get products ids
    let productPools = []
    let productsArray = []
    let productsByColorArray = []

    for (let i = 0; i < cartItems.length; i++) {
        if (productPools.indexOf(cartItems[i].productId) >= 0) {
            continue;
        }

        productPools.push(cartItems[i].productId)
    }

    //Find product object
    productPools.filter((productId) => {
        for (let i = 0; i < products.length; i++) {
            if (products[i]._id === productId) {
                productsArray.push(products[i])
                break;
            }
        }
    })


    productsArray.filter((product) => {
        let colorIds = []

        //If product is simple add and continue
        if (product.type === 'simple') {

            let productObj = Object.assign({}, product)

            for (let i = 0; i < cartItems.length; i++) {
                if (productObj.code === cartItems[i].barcode) {
                    productObj.inputValue = cartItems[i].qty
                }
            }

            productsByColorArray.push(productObj)

            return true;
        }

        //Merge with live prices
        product = mergeWithLivePrice(product, variationPrices)

        product.variations.filter((variation) => {
            if (colorIds.indexOf(variation.colorId) < 0) {
                colorIds.push(variation.colorId)
            }
        });

        colorIds.filter((colorId) => {
            let productObj = Object.assign({}, product)

            productObj.variations = productObj.variations.filter((variation) => {
                if (variation.colorId !== colorId) {
                    return false
                }
                return true
            })

            let addProduct = false

            productObj.variations = productObj.variations.map((variation) => {
                for (let i = 0; i < cartItems.length; i++) {
                    if (variation.barcode === cartItems[i].barcode) {
                        addProduct = true
                        variation.inputValue = cartItems[i].qty
                        return variation
                    }
                }
                variation.inputValue = 0

                return variation
            })

            productObj.colorHex = productObj.variations[0].colorHex
            productObj.colorName = productObj.variations[0].colorName
            productObj.colorId = productObj.variations[0].colorId
            productObj.colorCode = productObj.variations[0].colorCode

            if (addProduct) {
                productsByColorArray.push(productObj)
            }
        })
    })

    //Return the result sorted by code
    return productsByColorArray.sort(function (a, b) {

        if(a.type === 'simple') {
            return 1
        }

        if(b.type === 'simple') {
            return -1
        }

        let aCode = parseInt(a.code)
        let bCode = parseInt(b.code)

        if (aCode < bCode) return -1;
        if (aCode > bCode) return 1;
        return 0;
    })
}

export const calculateTotalPrice = (cartItems, activeCurrency, withCurrency = true) => {
    let totalPrice = 0

    cartItems.filter((cartItem) => {
        if(cartItem.variations && cartItem.variations.length) {
            cartItem.variations.filter((variation) => {
                totalPrice += parseFloat(parseInt(variation.inputValue) * parseFloat(variation.price))
            })
        }else {
            totalPrice += parseFloat(parseInt(cartItem.inputValue) * parseFloat(cartItem.price.final))
        }
    })

    if(withCurrency) {
        return `${activeCurrency}${totalPrice.toFixed(2)}`
    }

    return totalPrice.toFixed(2)
}

export const calculateTopSeason = (cartItems, seasons, settings, preorder = false) => {

    let seasonsArray = []

    if(!preorder) {
        //Get default season
        const defaultSeasonId = settings.defaultSeason

        for(let i = 0; i < seasons.length; i++) {
            if(parseInt(seasons[i].softoneId) === parseInt(defaultSeasonId)) {
                seasonsArray.push({
                    name: seasons[i].name,
                    softoneId: seasons[i].softoneId,
                    cType: seasons[i].cType,
                    finalDate: seasons[i].finalDate,
                    deliveryDates: seasons[i].deliveryDates,
                    itemsCount: 0
                })
                break;
            }
        }

        return seasonsArray;
    }

    cartItems.filter((cartItem) => {

        //Apply only to variable products
        // if (cartItem.type !== 'variable') {
        //     return false;
        // }

        if (!seasonsArray[cartItem.season.softoneId]) {
            seasonsArray[cartItem.season.softoneId] = {
                name: cartItem.season.name,
                softoneId: cartItem.season.softoneId,
                cType: cartItem.season.cType,
                finalDate: cartItem.season.finalDate,
                deliveryDates: cartItem.season.deliveryDates,
                itemsCount: 0
            }
        }

        cartItem.variations.filter((variation) => {
            seasonsArray[cartItem.season.softoneId].itemsCount += parseInt(variation.inputValue)
        })
    })

    //Check if we have only nos season
    let seasonsArrayFiltered = []
    for(let i = 0; i < seasonsArray.length; i++) {
        if(!seasonsArray[i]) {
            continue;
        }

        seasonsArrayFiltered.push(seasonsArray[i])
    }

    if(seasonsArrayFiltered.length === 1 && seasonsArrayFiltered[0].name === '0000') {
        return seasonsArrayFiltered = seasons.map((seasonItem) => {
            return {
                name: seasonItem.name,
                softoneId: seasonItem.softoneId,
                cType: seasonItem.cType,
                finalDate: seasonItem.finalDate,
                deliveryDates: seasonItem.deliveryDates,
                itemsCount: 0
            }
        })
    }

    //Now remove the NOS season
    seasonsArrayFiltered = seasonsArrayFiltered.filter((season) => {
        return season.cType !== 'NOS'
    })

    //Return only the top season
    return seasonsArrayFiltered.sort(function (a, b) {
        let aQty = parseInt(a.itemsCount)
        let bQty = parseInt(b.itemsCount)

        if (aQty < bQty) return -1;
        if (aQty > bQty) return 1;
        return 0;
    })
}

export const calculateOrderType = (topSeason) => {
    const order_types = ['PREORDER']
    // const order_types = ['PREORDER', 'REORDER', 'NOS', 'OFFER']

    // for (let i = 0; i < order_types.length; i++) {
    //     if (topSeason.cType === order_types[i]) {
    //         return {
    //             'label': order_types[i],
    //             'value': order_types[i].toLowerCase()
    //         }
    //     }
    // }

    return {
        'label': order_types[0],
        'value': order_types[0].toLowerCase()
    }
}

export const hasPreorderProduct = (products) => {
    for(let i = 0; i < products.length; i++) {
        if(products[i].markedAsPreorder) {
            return true;
        }
    }

    return false
}